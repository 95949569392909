@font-face {
  font-family: "pmdash";
  src: url("../../assets/fonts/pmdash.eot");
  src:
    url("../../assets/fonts/pmdash.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/pmdash.woff") format("woff"),
    url("../../assets/fonts/pmdash.ttf") format("truetype"),
    url("../../assets/fonts/pmdash.svg#pmdash") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: "fontello";
  src: url("../../assets/fonts/fontello.eot");
  src:
    url("../../assets/fonts/fontello.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/fontello.woff2") format("woff2"),
    url("../../assets/fonts/fontello.woff") format("woff"),
    url("../../assets/fonts/fontello.ttf") format("truetype"),
    url("../../assets/fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[data-icon]:before {
  font-family: "pmdash", sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="pmi-"]:before,
[class*=" pmi-"]:before {
  font-family: "pmdash", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="pmif"]:before,
[class*=" pmif"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.pmifcall:before {
  content: "\e800";
} /* '' */
.pmiffile_star:before {
  content: "\e801";
} /* '' */
.pmifstop-clock:before {
  content: "\e802";
} /* '' */
.pmifpencil:before {
  content: "\e803";
} /* '' */
.pmifcalendar:before {
  content: "\e808";
} /* '' */
.pmi-download-icon:before {
  content: "\61";
}
.pmi-add-notes:before {
  content: "\63";
}
.pmi-delete:before {
  content: "\64";
}
.pmi-edit-active:before {
  content: "\65";
}
.pmi-edit-inactive:before {
  content: "\66";
}
.pmi-add-notes-active:before {
  content: "\62";
}
.pmi-attatchment:before {
  content: "\67";
}
.pmi-tag-icon:before {
  content: "\68";
}
[class^="pmif"]:before,
[class*=" pmif"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.pmifcall:before {
  content: "\e800";
} /* '' */
.pmiffile_star:before {
  content: "\e801";
} /* '' */
