// ministudio styling
@import "../stylesheets/commonPopUp.scss";
$revamp-font: "Rubik", sans-serif;
$revamp-theme-color: #6200ea;

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 2px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-right: none;
    border-left: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

.spec-detail-studiotype {
  padding: 0 24px;
  font-family: $revamp-font;
  .spec-studio-header {
    display: flex;
    justify-content: space-between;
    margin: 15px 2px;
    .spec-btn-style {
      background: #ffffff;
      border: 1px solid #d0d2da;
      box-sizing: border-box;
      border-radius: 4px;
      color: #6200ea;
      font-size: 14px;
      line-height: 12px;
      padding: 11px;
      margin-right: 15px;
      height: 37px;
      cursor: pointer;
      img {
        margin-right: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      .spec-studio-left {
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
        }
        img {
          cursor: pointer;
        }
      }
      .rename-edit {
        input {
          border: none;
          background: none;
          min-width: 100px;
          max-width: 200px;
          width: 149px;
          font-weight: 500;
          padding: 0;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        span {
          color: #651fff;
          font-size: 12px;
          line-height: 12px;
          margin-left: 15px;
          cursor: pointer;
          &.closeSaves {
            color: #000;
            border-left: 1px solid;
            margin-left: 10px;
            padding-left: 10px;
          }
        }
      }
    }
    .spec-call-with {
      color: #83889e;
      font-size: 12px;
      line-height: 12px;
      margin-top: 6px;
    }
  }
  /***Spec Header Ends Here**/
  .spec-detail-middle-section {
    .spec-global-box {
      background: #ffffff;
      border: 1px solid #dfe0e6;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      margin-top: 10px;
      &.customer-and-more {
        margin-top: 0;
      }
      .custom-buildcard {
        font-size: 12px;
        line-height: 12px;
        color: #651fff;
        cursor: pointer;
        &.disabled {
          opacity: 0.7;
        }
      }
      .spec-global-heading {
        border-bottom: 1px solid #ecedf0;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
        ul {
          display: flex;
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            color: #9b9fb1;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-left: 20px;
            padding: 10px 0px;
            cursor: pointer;
            &.active {
              border-bottom: 1px solid $revamp-theme-color;
              color: #3c3e49;
              &.no-border-active {
                border-bottom: none;
              }
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .customer-insight-info {
        &.more-info-box {
          ul {
            li {
              min-height: initial;
              align-items: flex-start;
              .inner-item-box {
                width: 60%;
                &:first-child {
                  width: 40%;
                }
              }
            }
          }
        }
        ul {
          margin: 0;
          padding: 0;
          &.expand-ver-activate {
            max-height: 61px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
              background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
          li {
            list-style: none;
            display: flex;
            border-bottom: 1px solid #ecedf0;
            //min-height:85px;
            min-height: initial;
            .inner-item-box {
              width: 33.33%;
              padding: 20px 20px 28px;
              // padding-top:10px;
              // padding-bottom:10px;
              color: #3c3e49;
              font-size: 13px;
              line-height: 16px;
              border-left: 1px solid #ecedf0;
              text-align: right;
              &.customer-ins {
                padding-left: 5px;
                font-size: 13px;
              }
              .discount_added {
                width: 100%;
                text-align: right;
                padding: 0;
                margin-top: 10px;
                color: #3c3e49;
              }
              &:first-child {
                border-left: none;
                text-align: left;
              }
              .red-box-inner {
                display: flex;
                justify-content: space-between;
              }
              &.red-box-global {
                color: #ff3d00;

                span {
                  color: #ff3d00;
                }
              }
              &.item-desc-customer {
                font-size: 13px;
              }
              span {
                color: #9b9fb1;
                font-size: 11px;
                line-height: 11px;
                margin-top: 4px;
                &.weeks-to-show {
                  font-size: 11px;
                  line-height: 11px;
                  margin-top: 2px;
                }
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .spec-global-footer {
    padding: 0px 20px;
    min-height: 42px;
    display: flex;
    align-items: center;
    .promo-input {
      background: #ffffff;
      border: 1px solid #dfe0e6;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .promo-btn {
      font-size: 12px;
      line-height: 12px;
      color: $revamp-theme-color;
      background: #fff;
      border-color: #ecedf0;
      padding: 14px 20px;
    }
  }

  .phases-info-section {
    max-height: 222px;
    min-height: 222px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      background: #ccc !important;
    }
    &.expand-ver-active {
      max-height: 277px;
    }
    .phase-name-info {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .phase-info-lsiting {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ecedf0;
        padding: 15px 20px;
        font-size: 14px;
        color: #3c3e49;
        line-height: 20px;
        justify-content: space-between;
        &:last-child {
          border: none;
        }
        .flx {
          display: flex;
        }
        .align-center {
          align-items: center;
        }
        .weeks-to-show {
          display: block;
          font-size: 12px;
          color: #83889e;
        }
        .phase-edit {
          cursor: pointer;
        }
        .phase-platform {
          background: #ffffff;
          border: 1px solid #d0d2da;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;
          width: 32px;
          height: 32px;
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
        .phase-speed {
          width: 90px;
          height: 1px;
          background: #dfe0e6;
          position: relative;
          margin-top: 11px;
          .phase-speed-current {
            position: absolute;
            background-color: $revamp-theme-color;
            height: 100%;
            &.show-cursor:before {
              content: "";
              position: absolute;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 100%;
              top: -7px;
              background-color: $revamp-theme-color;
            }
          }
        }
      }
    }
  }

  .delivery-main-section {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      li {
        display: block;
        width: 33.33%;
        padding: 0 20px;
        padding-top: 10px;
        padding-bottom: 20px;
        color: #3c3e49;
        font-size: 13px;
        line-height: 18px;
        border-left: 1px solid #ecedf0;
        .desc-delivery {
          color: #83889e;
          margin-top: 16px;
          .wrap-value {
            display: flex;
            align-items: center;
            cursor: pointer;
            .main-value {
              margin-right: 8px;
            }
            em {
              font-size: 16px;
            }
          }
          &.spec-global-dropdown {
            position: relative;
          }
          .timezone-dropdown {
            position: absolute;
            bottom: 22px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            border: 1px solid #dfe0e6;
            box-sizing: border-box;
            width: 300px;
            background: #ffffff;
            padding-top: 16px;
            z-index: 4;
            .sub-dropdown {
              margin-bottom: 16px;
            }
            .spec-common-search {
              padding-bottom: 16px;
              padding-left: 15px;
              padding-right: 15px;
            }
            .heading-subdropdown {
              color: #3c3e49;
              font-weight: 500;
              padding: 0 15px;
              margin-bottom: 5px;
            }
            ul {
              display: block;
              li {
                color: #3c3e49;
                width: 100%;
                border: none;
                margin: 0;
                padding: 7px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                &:last-child {
                  padding-bottom: 0;
                }
                .custom-radio-box {
                  display: none;
                }
                &.active {
                  background: #ecedf0;
                  .custom-radio-box {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .custom-radio-box {
    background: #ffffff;
    border: 1px solid #afb2c0;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    min-height: 18px;
    margin-top: 3px;
    em {
      display: none;
    }
    &.active {
      font-size: 11px;
      background: #00d659;
      color: #fff;
      border-color: #00d659;
      em {
        display: block;
      }
    }
  }
  .spec-common-search {
    position: relative;
    &.on-top-search {
      margin: 15px 0;
      margin-top: 0;
      padding-top: 15px;
      border-top: 1px solid #dfe0e6;
      .input-group {
        padding: 0 15px;
      }
      .global-search-icon {
        left: 25px;
      }
    }
    .global-search {
      background: #ffffff;
      border: 1px solid #dfe0e6;
      border-radius: 4px !important;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 22px;
      color: #000;
      padding: 0 34px;
      height: 40px;
      &:-moz-placeholder {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #83889e !important;
        opacity: 1 !important;
      }
      &::-ms-placeholder {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #83889e !important;
        opacity: 1 !important;
      }
      &::-o-placeholder {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #83889e !important;
        opacity: 1 !important;
      }
      &::-webkit-input-placeholder {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #83889e !important;
        opacity: 1 !important;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .global-search-close,
    .global-search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 11px;
      z-index: 4;
      line-height: 0;
    }
    .global-search-close {
      left: auto;
      cursor: pointer;
      &.active {
        border-bottom: 1px solid $revamp-theme-color;
        color: #3c3e49;
        &.no-border-active {
          border-bottom: none;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .customer-insight-info {
    &.more-info-box {
      ul {
        li {
          min-height: initial;
          align-items: center;
          .inner-item-box {
            width: 60%;
            &:first-child {
              width: 40%;
            }
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      &.expand-ver-activate {
        max-height: 61px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      li {
        list-style: none;
        display: flex;
        border-bottom: 1px solid #ecedf0;
        //min-height:85px;
        min-height: initial;
        .inner-item-box {
          width: 33.33%;
          padding: 0 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          color: #3c3e49;
          font-size: 13px;
          line-height: 16px;
          border-left: 1px solid #ecedf0;
          text-align: right;
          &.customer-ins {
            padding-left: 5px;
            font-size: 13px;
          }
          .discount_added {
            width: 100%;
            text-align: right;
            padding: 0;
            margin-top: 10px;
            color: #3c3e49;
          }
          &:first-child {
            border-left: none;
            text-align: left;
          }
          .red-box-inner {
            display: flex;
            justify-content: space-between;
          }
          &.red-box-global {
            color: #ff3d00;

            span {
              color: #ff3d00;
            }
          }
          &.item-desc-customer {
            font-size: 13px;
          }
          span {
            color: #9b9fb1;
            font-size: 11px;
            line-height: 11px;
            margin-top: 4px;
            &.weeks-to-show {
              font-size: 11px;
              line-height: 11px;
              margin-top: 2px;
            }
            display: block;
          }
        }
      }
    }
  }
}
.spec-global-footer {
  padding: 0px 20px;
  min-height: 42px;
  display: flex;
  align-items: center;
  .promo-input {
    background: #ffffff;
    border: 1px solid #dfe0e6;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .promo-btn {
    font-size: 12px;
    line-height: 12px;
    color: $revamp-theme-color;
    background: #fff;
    border-color: #ecedf0;
    padding: 14px 20px;
  }
}

.phases-info-section {
  max-height: 222px;
  min-height: 222px;
  overflow: hidden;
  overflow-y: auto;
  &.expand-ver-active {
    max-height: 277px;
  }
  .phase-name-info {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .phase-info-lsiting {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ecedf0;
      padding: 15px 20px;
      font-size: 14px;
      color: #3c3e49;
      line-height: 20px;
      justify-content: space-between;
      &:last-child {
        border: none;
      }
      .flx {
        display: flex;
      }
      .align-center {
        align-items: center;
      }
      .weeks-to-show {
        display: block;
        font-size: 12px;
        color: #83889e;
      }
      .phase-edit {
        cursor: pointer;
      }
      .phase-platform {
        background: #ffffff;
        border: 1px solid #d0d2da;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;
        }
      }
      .phase-speed {
        width: 90px;
        height: 1px;
        background: #dfe0e6;
        position: relative;
        margin-top: 11px;
        .phase-speed-current {
          position: absolute;
          background-color: $revamp-theme-color;
          height: 100%;
          &.show-cursor:before {
            content: "";
            position: absolute;
            right: 0;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            top: -7px;
            background-color: $revamp-theme-color;
          }
        }
      }
    }
  }
}

.delivery-main-section {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    li {
      display: block;
      width: 33.33%;
      padding: 0 20px;
      padding-top: 10px;
      padding-bottom: 20px;
      color: #3c3e49;
      font-size: 13px;
      line-height: 18px;
      border-left: 1px solid #ecedf0;
      .desc-delivery {
        color: #83889e;
        margin-top: 16px;
        .wrap-value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: calc(50% - 10px);
            background: #ffffff;
            border: 1px solid #dfe0e6;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px 10px 10px;
            margin-bottom: 15px;
            &:nth-child(odd) {
              margin-right: 10px;
            }
            &:nth-child(even) {
              margin-left: 10px;
            }
            .custom-radio-box {
              margin-right: 0;
            }
            .template-name {
              line-height: 14px;
              color: #3c3e49;
              font-size: 14px;
              font-weight: 500;
            }
            .template-price {
              color: #83889e;
              font-size: 13px;
              line-height: 13px;
              margin-top: 5px;
            }
          }
          em {
            font-size: 16px;
          }
        }
        &.spec-global-dropdown {
          position: relative;
        }
        .timezone-dropdown {
          position: absolute;
          bottom: 22px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          border: 1px solid #dfe0e6;
          box-sizing: border-box;
          width: 300px;
          background: #ffffff;
          padding-top: 16px;
          z-index: 4;
          .sub-dropdown {
            margin-bottom: 16px;
          }
          .spec-common-search {
            padding-bottom: 16px;
            padding-left: 15px;
            padding-right: 15px;
          }
          .heading-subdropdown {
            color: #3c3e49;
            font-weight: 500;
            padding: 0 15px;
            margin-bottom: 5px;
          }
          ul {
            display: block;
            li {
              color: #3c3e49;
              width: 100%;
              border: none;
              margin: 0;
              padding: 7px 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              &:last-child {
                padding-bottom: 0;
              }
              .custom-radio-box {
                display: none;
              }
              &.active {
                background: #ecedf0;
                .custom-radio-box {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}
.custom-radio-box {
  background: #ffffff;
  border: 1px solid #afb2c0;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  margin-top: 0;
  em {
    display: none;
    line-height: 0;
  }
  &.active {
    font-size: 11px;
    background: #00d659;
    color: #fff;
    border-color: #00d659;
    em {
      display: block;
    }
  }
}
.spec-common-search {
  position: relative;
  &.on-top-search {
    margin: 15px 0;
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid #dfe0e6;
    .input-group {
      padding: 0 15px;
    }
    .global-search-icon {
      left: 25px;
    }
  }
  .global-search {
    background: #ffffff;
    border: 1px solid #dfe0e6;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    padding: 0 34px;
    height: 40px;
    &:-moz-placeholder {
      font-size: 14px !important;
      line-height: 22px !important;
      color: #83889e !important;
      opacity: 1 !important;
    }
    &::-ms-placeholder {
      font-size: 14px !important;
      line-height: 22px !important;
      color: #83889e !important;
      opacity: 1 !important;
    }
    &::-o-placeholder {
      font-size: 14px !important;
      line-height: 22px !important;
      color: #83889e !important;
      opacity: 1 !important;
    }
    &::-webkit-input-placeholder {
      font-size: 14px !important;
      line-height: 22px !important;
      color: #83889e !important;
      opacity: 1 !important;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .global-search-close,
  .global-search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 11px;
    z-index: 4;
    line-height: 0;
  }
  .global-search-close {
    left: auto;
    cursor: pointer;
    right: 11px;
    color: #c0c3ce;
  }
}
.template-info-listing {
  padding-top: 15px;
  padding-bottom: 0;
  font-size: 13px;
  line-height: 22px;
  color: #83889e;
  .spec-common-search {
    padding: 0 15px;
    padding-bottom: 15px;
  }
  .spec-common-desc {
    padding-bottom: 15px;
    span {
      color: $revamp-theme-color;
      cursor: pointer;
    }
  }
  .template-inner-listing {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: calc(50% - 10px);
      background: #ffffff;
      border: 1px solid #dfe0e6;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px 10px 10px;
      margin-bottom: 15px;
      &:nth-child(odd) {
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-left: 10px;
      }
      &.disabled {
        pointer-events: inherit;
      }
      .custom-radio-box {
        margin-right: 0;
      }
      .template-name {
        line-height: 14px;
        color: #3c3e49;
        font-size: 14px;
        font-weight: 500;
      }
      .template-price {
        color: #83889e;
        font-size: 13px;
        line-height: 13px;
        margin-top: 5px;
      }
    }
  }
}

.video-detail-section {
  .video-wrap {
    background-color: #3c3e49;
    height: 268px;
    width: 100%;
    position: relative;
    .transcript-show-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      img {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
        width: 26px;
        height: 26px;
      }
    }

    .expand-meeting-window {
      cursor: pointer;
      position: absolute;
      width: 25px;
      height: 25px;
      right: 6px;
      top: 5px;
      background: #651fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        box-shadow: 0px 0px 4px 1px #000;
      }
      img {
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }

    #cockpit-frame {
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }
  margin-top: 0px;
  border: 1px solid #dfe0e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;
  .main-spec-info {
    background: rgba(255, 255, 255, 0.8);
    ul {
      margin: 0;
      padding: 18px 20px;
      li {
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #3c3e49;
        justify-content: space-between;
        margin-bottom: 8px;
        .info-detail {
          color: #83889e;
          text-align: right;
          max-width: 70%;
        }
        .info-name {
          padding-right: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .chat-feature-tagging-section {
    background: rgba(255, 255, 255, 0.8);
  }
}

/***Custom row and spacing***/
.spec-detail-row {
  margin: 0 -10px;
  .col-sm-6 {
    padding: 0 10px;
  }
}

/***Height frame logic***/
.spec-detail-middle-section {
  //height: calc(100vh - 122px); padding-bottom: 0;
  height: 100%;
  padding-bottom: 15px;
}
.full-height {
  height: 100%;
}
.chat-feature-section-wrp {
  height: calc(100% - 420px);
  padding-bottom: 0 !important;
  max-height: inherit;
  .chat-feature-tagging-section {
    height: calc(100% - 42px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.full-height.full-scroll {
  //overflow-y: auto;
  @include customScrollbar;
  &::-webkit-scrollbar-thumb {
    background: #ccc !important;
  }
}
.spec-detail-page-main {
  display: flex;
  .spec-calls-page {
    //width: calc(100% - 300px);
    width: 74%;
    padding-bottom: 0;
  }
}
.builder-care-section {
  .spec-global-heading {
    padding-left: 0px;
    padding-right: 0px;
    ul {
      width: 100%;
      li {
        &:first-child {
          margin-left: -2px !important;
          width: 30%;
          border-right: 1px solid #ecedf0;
        }
        display: flex;
        align-items: center;
        position: relative;
        &:first-child {
          border-right: 1px solid #ecedf0;
          margin-left: -7px !important;
          padding-left: 7px !important;
        }
        em {
          color: #83889e;
          font-size: 14px;
          margin-left: 5px;
          line-height: 14px;
          width: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        em:hover + .tooltip-box-blk {
          display: block;
        }
      }
    }
  }
  .builder-main-section {
    ul {
      li {
        &:first-child {
          border-left: 0 !important;
        }
      }
    }
  }
}
.spec-global-scroll {
  overflow-y: auto;
  @include customScrollbar;
  &::-webkit-scrollbar-thumb {
    background: #ccc !important;
  }
  &.template-scroll {
    max-height: 147px;
    .spec-inner-scroll-wrp {
      padding: 0 15px;
    }
  }
  &.feature-scroll {
    max-height: 147px;
    .spec-inner-scroll-wrp {
      padding: 0 15px;
    }
  }
  &.phase-scroll {
    height: 100%;
  }
  &.timezone-scroll {
    max-height: 363px;
  }
}

.chat-feature-section-wrp {
  height: calc(100% - 420px);
  .chat-feature-tagging-section {
    height: calc(100% - 42px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.builder-care-section {
  .spec-global-heading {
    ul {
      li {
        display: flex;
        align-items: center;
        position: relative;
        em {
          color: #83889e;
          font-size: 14px;
          margin-left: 5px;
          line-height: 14px;
          width: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        em:hover + .tooltip-box-blk {
          display: block;
        }
      }
    }
  }
}
.natasha-chat {
  height: calc(100vh - 51px);
  width: 360px;
  background: #fff;
  margin-top: 51px;
  .natasha-chat-wrp {
    height: 100%;
    .natasha-chat-heading {
      border-bottom: 1px solid #ecedf0;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          color: #3c3e49;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 0px;
        }
      }
    }
    .natasha-chat-body {
      height: calc(100% - 41px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.spec-global-scroll {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &.template-scroll {
    max-height: 147px;
    .spec-inner-scroll-wrp {
      padding: 0 15px;
    }
  }
  &.feature-scroll {
    max-height: 147px;
    .spec-inner-scroll-wrp {
      padding: 0 15px;
    }
  }
  &.phase-scroll {
    height: 100%;
  }
  &.timezone-scroll {
    max-height: 363px;
  }
}
.global-checkbox-phase {
  font-size: 14px;
  color: #3c3e49;
  line-height: 20px;
  .flx {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .weeks-to-show {
    display: block;
    font-size: 12px;
    color: #83889e;
  }
}

.tooltip-box-blk {
  background: #3c3e49;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  padding: 20px;
  position: absolute;
  bottom: 100%;
  left: 92%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 360px;
  font-weight: 400;
  display: none;
  text-align: left;
  min-width: 160px;
  z-index: 1;
  &.right-shift {
    left: auto;
    right: 0;
  }
  &.center-shift {
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltip-heading-blk {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
  }
  .tooltip-content-blk {
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.spec-global-tooltip {
  position: relative;
  em {
    cursor: pointer;
  }
  em:hover + .tooltip-box-blk {
    display: block;
  }
}
.mini-studio-common-popup {
  .mini-studio-popup-head {
    .close-popup-icon {
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    position: relative;
  }
}
.coupon-applied {
  width: 100%;
  color: #3c3e49;
  font-size: 14px;
  line-height: 22px;
  em {
    left: auto;
    cursor: pointer;
    color: #c0c3ce;
  }
}

.green-coupon {
  color: #00d659 !important;
}

.client-modal.sidebar-button-popup .modal-dialog {
  max-width: 800px;
}

.main-header {
  border: 1px solid #dfe0e6;
  box-shadow: none;
  display: flex;
  height: 53px;
  background: #fff;
  align-items: center;
  font-family: $revamp-font;
  img.revamp-logo {
    width: 99px;
    display: block;
  }
  .subitem-heading {
    letter-spacing: 0.1em;
    color: #83889e;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  div.item-status-manager {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    padding: 13px 20px;
    color: #83889e;
    em {
      position: absolute;
      right: 20px;
    }
    &:before {
      position: static;
      margin-right: 13px;
    }
    &.not-available-status:before {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #ffab00;
    }
  }
  .list-inline {
    li.badge-label {
      color: #262626;
      font-weight: 500;
      font-size: 14px;
    }
    li.badge-stl {
      background: #ede7f6;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    .spec-detail-studiotype {
      padding: 0 16px;
      .spec-studio-header {
        flex-wrap: wrap;
        .spec-studio-left {
          margin: 0 0 12px;
        }
        .spec-studio-right {
          width: 100%;
        }
      }
      .spec-detail-middle-section {
        .full-height {
          height: auto;
        }
        .spec-global-box {
          margin-bottom: 10px;
        }
      }
    }
    .spec-detail-studiotype {
      padding: 0 16px;
      .spec-studio-header {
        flex-wrap: wrap;
        .spec-studio-left {
          margin: 0 0 12px;
        }
        .spec-studio-right {
          width: 100%;
        }
      }
      .spec-detail-middle-section {
        .full-height {
          height: auto;
        }
        .spec-global-box {
          margin-bottom: 10px;
        }
      }
    }
    .spec-detail-middle-section {
      .full-height {
        height: auto;
      }
      .spec-global-box {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .spec-detail-page-main {
    .spec-calls-page {
      width: 100%;
    }
  }

  .natasha-chat {
    position: fixed;
    display: none;
  }
}

@media only screen and (max-width: 575.98px) {
  .spec-detail-studiotype {
    .spec-detail-middle-section {
      .spec-global-box {
        .spec-global-heading {
          padding: 0 10px;
        }
        .customer-insight-info {
          ul {
            li {
              .inner-item-box {
                padding: 10px;
              }
            }
          }
        }
      }
    }
    .template-info-listing {
      .template-inner-listing {
        li {
          width: 100%;
          &:nth-child(odd) {
            margin-right: 0;
          }
          &:nth-child(2n) {
            margin-left: 0;
          }
        }
      }
    }
    .phases-info-section {
      .phase-info-lsiting {
        li {
          padding: 15px 10px;
          overflow-x: auto;
          width: 100%;
          .phase-item {
            margin: 0 10px 0 0;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.spec-detail-studiotype {
  .spec-detail-middle-section {
    .spec-video-featuring {
      display: flex;
      flex-direction: column;
      .spec-global-box {
        flex: 1;
      }
    }
  }
}

.no-attachment-available {
  margin-top: 44px;
}
