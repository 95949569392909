.transcript_overview {
  max-width: calc(100% - 68px);
  height: calc(100% - 68px);
  top: 34px;
  margin: 0 auto;

  .modal-content {
    background-color: #f8f9fa;
    height: 100%;
    position: relative;

    transcript-overview {
      display: block;
      height: 100%;
      position: relative;

      #meeting-transcript-popup,
      #transaction_logs-transcript-popup {
        position: relative;
        height: 100%;

        .modal-body {
          padding: 0 !important;
          height: 100%;

          .spec-detail-studiotype {
            background-color: #f8f9fa;
            overflow: hidden;
            padding-left: 0 !important;
            height: 100%;
            // padding: 32px 32px 16px 32px;
            border-radius: 8px;

            .spec-detail-middle-section {
              padding: 0 !important;

              .spec-detail-row {
                height: 100%;

                .transition-width {
                  app-transcript {
                    display: block;
                    height: 100%;

                    .spec-global-box.transcript-section {
                      height: 100% !important;
                      margin: 0 !important;
                    }
                  }
                }
              }
            }

            .transcript-feature-tag-width {
              height: 100%;
              position: relative;

              .transcript-close {
                position: fixed;
                top: 7px;
                right: 17px;
                font-size: 24px;
                cursor: pointer;
                background: #dfe0e6;
                border-radius: 50%;
                height: 25px;
                width: 25px;
                color: #3c3e49;

                svg {
                  position: absolute;
                  left: -2px;
                }
                // &::before {
                //   content: '\f105\f104';
                //   letter-spacing: -0.15em;
                //   margin-left: 6px;
                // }
              }

              app-builder-video-tag {
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                width: 100%;
                display: block;
                .video-js {
                  background: #000;
                  width: 100%;
                  height: 100%;
                }
                .builderplayer-dimensions {
                  margin: 0;
                  height: 250px !important;

                  video {
                    background: #000;
                    width: 100%;
                  }
                }

                // span.spinner-border {
                //   z-index: 2;
                //   position: absolute;
                //   color: #fff;
                //   left: 0;
                //   right: 0;
                //   top: 0;
                //   bottom: 0;
                //   margin: auto;
                // }

                .video-progress {
                  position: absolute;
                  color: #fff;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 5;
                  .video-progress-percent {
                    position: absolute;
                    color: #fff;
                    background: #1e242c;
                    height: 25px;
                    width: 25px;
                    font-size: 8px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 6;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                  }
                }
              }

              .spec-global-box.chat-feature-section-wrp {
                display: block;
                height: calc(100% - 310px);
                position: relative;

                transcript-feature-tag {
                  display: block;
                  height: calc(100% - 50px);
                }
              }
            }
          }
        }
      }
    }
  }
}

/**video feature release**/
.videoFeaturePopup {
  max-width: calc(100% - 68px);
  height: calc(100% - 68px);
  top: 34px;
  margin: 0 auto;

  .heading-modal {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: -0.01em;
    color: #333333;
    margin-bottom: 16px;
  }

  .modal-content {
    height: 100%;
    background: none;
  }

  .videoFeatureModal {
    .full-height {
      height: 100%;
      overflow: hidden;

      &.overflow-init {
        overflow: initial;
      }
    }

    .modal-body {
      padding: 0;
      height: 100%;

      .closeModal {
        top: -33px;
        color: #4fa399;
        right: -14px;
        font-size: 31px;
      }

      .video-text-wrp {
        .video-wrp {
          height: 100%;

          app-builder-video-tag {
            display: block;
            height: 100%;
            position: relative;

            .builderplayer-dimensions {
              height: 100% !important;

              video {
                background: #000;
              }
            }
          }
        }

        .text-editor-wrp {
          height: calc(100vh - 457px);

          @media (max-width: 1370px) {
            height: calc(100vh - 390px);
          }

          @media (max-width: 1280px) {
            height: calc(100vh - 370px);
          }

          .history-update-wrp {
            height: 46px;
            background: #f5f5f5;
            padding: 8px;

            .revision-history-wrp {
              position: relative;
              display: inline-block;

              p {
                font-weight: 400;
                cursor: pointer;

                em {
                  font-size: 21px;
                  vertical-align: middle;
                }
              }

              .dropdown-menu {
                left: auto;
                right: 0;
                top: 4px;
                padding: 2px 0px;
                max-height: 82px;
                overflow-y: auto;
                border-radius: 0;

                /* width */
                &::-webkit-scrollbar {
                  width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #888;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                ul {
                  margin: 0;

                  li {
                    padding: 2px 8px;
                    cursor: pointer;

                    &:hover,
                    &.active {
                      background: #f5f5f5;
                    }

                    p {
                      font-weight: 400;
                    }
                  }
                }
              }
            }

            p {
              margin-bottom: 0;
            }
          }

          .upload-and-msg-wrp {
            padding: 8px;
            background: #f5f5f5;
            padding-top: 4px;

            .notes-msg-wrp {
              padding-top: 8px;

              .red_clr {
                color: red;
              }

              .texteditor-msg {
                font-size: 12px;
                line-height: 1.6;
                letter-spacing: -0.01em;
                color: #333;
                display: inline-block;

                &.auto-save {
                  color: #237804;
                }

                &.delete-note {
                  cursor: pointer;

                  span {
                    display: none;
                    color: #f5222d;
                  }
                }

                &.delete-note:hover {
                  color: #f5222d;

                  span {
                    display: inline-block;

                    &.done-text {
                      display: none;
                    }
                  }
                }

                &.grey-out {
                  span,
                  em {
                    visibility: hidden;
                  }

                  span.done-text {
                    visibility: visible;
                  }

                  cursor: default;
                  color: #e1e1e1;

                  &:hover {
                    color: #e1e1e1;

                    span {
                      display: none;
                    }
                  }
                }

                &.delete-note-action {
                  em,
                  span {
                    display: none;
                  }

                  &:hover {
                    span {
                      display: none;

                      &.done-text {
                        display: inline-block;
                      }
                    }
                  }

                  span.done-text {
                    display: inline-block;
                  }
                }

                span.done-text {
                  display: none;
                }

                span {
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: 1px;
                }

                em {
                  font-size: 21px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }

            .upload-file-wrp {
              border-bottom: 1px solid #ccc;
              margin: 0 -8px;

              .attatch-icon {
                display: inline-block;
                padding-left: 8px;
                width: auto;
                vertical-align: middle;
                padding-right: 15px;
                padding-bottom: 8px;

                /**same padding as .file-list ul **/
                label {
                  position: relative;
                  top: 5px;
                  cursor: pointer;
                  margin: 0;
                }

                #multi-upload {
                  display: none;
                }
              }

              .file-list {
                display: inline-block;
                width: calc(100% - 40px);

                ul {
                  &::-webkit-scrollbar {
                    width: 3px;
                    height: 3px;
                  }

                  &::-webkit-scrollbar-track {
                    background: transparent;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #ccc;
                  }

                  &::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                  }

                  display: flex;
                  overflow-x: auto;
                  white-space: nowrap;
                  margin-bottom: 0;
                  padding-bottom: 7px;

                  li {
                    padding-right: 5px;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 1.6;
                    letter-spacing: -0.01em;
                    color: #333333;
                    position: relative;
                    cursor: pointer;

                    &:last-child {
                      &:after {
                        display: none;
                      }
                    }

                    &:after {
                      content: '';
                      height: 12px;
                      width: 1px;
                      position: absolute;
                      background: #ccc;
                      right: -1px;
                    }

                    span {
                      margin-right: 4px;
                      max-width: 100px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }

                    em {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }

          .angular-editor {
            height: calc(100% - 120px) !important;
          }

          .angular-editor-wrapper {
            height: calc(100% - 55px) !important;

            @media (max-width: 1500px) {
              height: calc(100% - 74px) !important;
            }

            .angular-editor-textarea {
              background: #fff !important;
              min-height: 70px !important;
              max-height: calc(100vh - 621px);
              margin-top: 0 !important;
              border-right: 2px solid #ddd !important;
              border-left: 2px solid #ddd !important;
              resize: none;

              ul,
              ol {
                list-style: initial;
                padding-left: 15px;
              }

              li {
                list-style: initial;
                padding: initial;
              }

              ol {
                li {
                  list-style: decimal;
                }
              }

              /* width */
              &::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #888;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }
          }
        }
      }

      .commonPopupBox {
        height: 100%;
        padding: 16px;
        background: #f5f5f5;
        box-shadow: 2px 6px 30px rgba(0, 0, 0, 0.25);

        &.selected-features-wrp {
          .time-items-wrp {
            /* width */
            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            overflow: auto;
            height: calc(100% - 20px);
            margin-right: -16px;
          }

          .time-head {
            letter-spacing: -0.01em;
            color: #333333;
            font-size: 12px;
            line-height: 1.8;

            span {
              display: inline-block;
            }

            em {
              display: inline-block;
              font-size: 13px;
              vertical-align: middle;
              margin-right: 5px;
              margin-top: -1px;
              cursor: pointer;
            }
          }

          .time-listing {
            margin-left: 7px;
            margin-bottom: 21px;
            margin-right: 16px;

            li {
              letter-spacing: -0.01em;
              color: #333333;
              font-size: 14px;
              line-height: 1.5;
              border-bottom: 1px solid #d9d9d9;
              padding-top: 8px;
              padding-bottom: 8px;
              position: relative;

              span {
                overflow: hidden;
                width: 100%;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 150px;
                position: relative;
                top: 4px;
              }

              .icon-set {
                position: absolute;
                right: 0;
                top: 20px;
                font-size: 17px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);

                .stop-btn {
                  display: flex;
                  font-size: 12px;
                  height: 24px;
                  align-items: center;
                  border: 1px solid #d9d9d9;
                  padding: 0 8px;
                  background: #fff;
                  border-radius: 3px;
                  cursor: pointer;

                  .timer-added-text {
                    display: none;
                  }

                  &.add-timer {
                    em {
                      display: none;

                      &.plus-icon {
                        display: none;
                      }
                    }

                    cite {
                      display: none;
                    }

                    .timer-added-text {
                      border: none;
                      width: 58px;
                      display: inline-block;
                      text-align: center;

                      &:focus {
                        box-shadow: none;
                        outline: none;
                      }
                    }
                  }

                  &.timer-added {
                    em {
                      display: inline-block;

                      &.plus-icon {
                        display: none;
                      }
                    }

                    cite {
                      &.time-noted {
                        display: inline-block;
                      }

                      &.stop-time-note {
                        display: none;
                      }
                    }
                  }

                  cite {
                    font-style: normal;

                    &.time-noted {
                      display: none;
                    }
                  }

                  em {
                    display: none;

                    &.plus-icon {
                      font-size: 20px;
                      margin: 0;
                      margin-right: 8px;
                      display: inline-block;
                    }

                    &.cross-time {
                      font-size: 13px;
                    }

                    &.square-box {
                      margin: 0;
                      margin-right: 8px;
                      font-size: 9px;
                      padding-top: 1px;
                      vertical-align: middle;
                    }
                  }
                }

                em {
                  margin-left: 8px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        &.bcardFeature {
          .bcard-tags-listing {
            /* width */
            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            overflow: auto;
            margin-top: 19px;
            height: calc(100% - 105px);
            margin-right: -16px;
            margin-bottom: 0;

            li {
              background: #ffffff;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
              border-radius: 100000px;
              height: 30px;
              line-height: 30px;
              margin: 5px 0px;
              font-size: 14px;
              letter-spacing: -0.01em;
              color: #333333;
              display: inline-block;
              padding: 0 18px;
              margin-right: 6px;
              text-transform: capitalize;
              white-space: nowrap;
              overflow: hidden;
              max-width: 287px;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }

          .search-area {
            position: relative;

            input {
              height: 50px;
              letter-spacing: -0.01em;
              color: #333333;
              font-size: 14px;
              line-height: 1.5;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
              border-radius: 5px;
              width: 100%;
              padding: 13px;
              padding-right: 40px;

              &:focus {
                box-shadow: none;
                outline: none;
              }
            }

            em {
              position: absolute;
              font-size: 24px;
              top: 50%;
              transform: translateY(-50%);
              right: 13px;
            }
          }
        }
      }
    }
  }
}

$speakerInitialsBackgroundColors: #7c4dff, #ffab00, #00c853;
$speakerInitialsBackgroundColorsLength: length(
  $speakerInitialsBackgroundColors
);

.speaker-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .project-info {
    display: inline-flex;
    align-items: center;

    .back-press-btn {
      height: 38px;
      width: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d0d2da;
      border-radius: 4px;
    }

    .header-info {
      padding-left: 5px;

      .project-name {
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 2px;
      }

      .project-desc {
        font-size: 12px;
        line-height: 14px;
        color: #83889e;
      }
    }
  }

  .speaker-col {
    .speaker-wrapper {
      position: relative;

      .speaker-trigger {
        display: inline-flex;
        align-items: center;
        height: 36px;
        border: 1px solid #c0c3ce;
        border-radius: 2px;
        padding: 0 8px;
        cursor: pointer;

        &.open {
          .arrow {
            transform: rotate(180deg);
          }
        }

        .title {
          font-size: 12px;
          line-height: 20px;
          margin-right: 10px;
        }

        .speaker-list {
          display: flex;
          align-items: center;
          margin-right: 18px;

          .speaker {
            height: 24px;
            width: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            background: #7c4dff;
            border-radius: 50%;
            font-size: 12px;
            line-height: 16px;
            color: #fbf8ff;
            font-weight: 500;
            text-transform: uppercase;

            &:nth-child(2) {
              background: #ffab00;
            }

            &:nth-child(3) {
              background: #00c853;
            }

            &.more {
              background: #dfe0e6;
              color: #3c3e49;
            }

            + .speaker {
              margin-left: -5px;
            }
          }
        }

        .arrow {
          display: inline-flex;
          height: 16px;
          width: 16px;
          align-items: center;
          justify-content: center;
          transition: all 0.5s;
        }
      }

      .speaker-dropdown {
        position: absolute;
        right: 0;
        top: calc(100% + 4px);
        width: 278px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        z-index: 1;

        .speaker-dropdown-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
          font-size: 12px;
          line-height: 16px;

          .left-header {
            color: #676b7e;
          }

          .right-header {
            display: flex;
            align-items: center;

            .clear-all {
              cursor: pointer;
              color: #6200ea;
              text-decoration: underline;
              margin-right: 16px;
            }

            .close {
              display: inline-flex;
              cursor: pointer;
            }
          }
        }
      }

      .speaker-listing {
        max-height: 230px;
        overflow: auto;
        min-height: 150px;

        .speaker-row {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          @for $i from 1 through $speakerInitialsBackgroundColorsLength {
            &:nth-of-type(#{$speakerInitialsBackgroundColorsLength}n + #{$i}) {
              .speaker-row-outer {
                .speaker-name {
                  .initial {
                    background-color: nth($speakerInitialsBackgroundColors, $i);
                  }
                }
              }
            }
          }

          .speaker-row-outer {
            display: flex;
            align-items: center;

            .custom-checkbox {
              width: 16px;
              height: 16px;
              position: relative;

              .hidden-input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                opacity: 0;

                &:checked {
                  ~ label {
                    &::before {
                      background-color: #6200ea;
                      border-color: #6200ea;
                    }

                    &::after {
                      opacity: 1;
                      transform: scale(1);
                    }
                  }
                }
              }

              label {
                &::before,
                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 16px;
                  width: 16px;
                  border-radius: 2px;
                  transition: all 0.5s;
                }

                &::before {
                  border: 1px solid #c0c3ce;
                }

                &::after {
                  background-image: url('../../assets/images/theme-select-icon.png');
                  background-size: 11px;
                  background-repeat: no-repeat;
                  background-position: center;
                  opacity: 0;
                  transform: scale(0.5);
                }
              }
            }

            .speaker-name {
              display: inline-flex;
              align-items: center;
              padding-left: 8px;
              padding-right: 18px;
              max-width: calc(100% - 30px);

              .initial {
                height: 24px;
                width: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #fff;
                background: #7c4dff;
                border-radius: 50%;
                font-size: 12px;
                line-height: 16px;
                color: #fbf8ff;
                font-weight: 500;
                text-transform: uppercase;
              }

              .name {
                font-size: 12px;
                line-height: 16px;
                padding-left: 6px;
                color: #000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .edit {
              display: inline-flex;
              height: 16px;
              width: 16px;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }

          .speaker-search-wrapper {
            position: relative;

            .error-msg {
              color: #ff0014;
              font-size: 12px;
              padding-right: 5px;
              margin-bottom: 13px;
            }

            .search-block {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .speaker-input {
                outline: none;
                border: 1px solid #dfe0e6;
                border-radius: 4px;
                height: 32px;
                padding: 0 8px;
                font-size: 12px;
                line-height: 20px;
                color: #000000;
                width: calc(100% - 33px);
              }

              .submit {
                height: 20px;
                width: 20px;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              }
            }

            .speaker-search-results {
              position: absolute;
              left: 0;
              right: 0;
              top: 100%;
              background: #fff;
              border: 1px solid #dfe0e6;
              border-radius: 4px;
              max-height: 100px;
              overflow: auto;
              z-index: 1;

              .search-row {
                display: flex;
                padding: 8px;
                align-items: center;

                .search-initial {
                  height: 24px;
                  width: 24px;
                  min-width: 24px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #fff;
                  background: #7c4dff;
                  border-radius: 50%;
                  font-size: 12px;
                  line-height: 16px;
                  color: #fbf8ff;
                  font-weight: 500;
                  text-transform: uppercase;
                }

                .name-email {
                  width: calc(100% - 24px);
                  padding-left: 10px;
                  font-size: 12px;
                  line-height: 16px;

                  .email {
                    display: inline-block;
                    color: #676b7e;
                    margin-left: 4px;
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }

      .apply-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        .purple-btn {
          cursor: pointer;
          padding: 0 16px;
          background: #6200ea;
          border-radius: 3px;
          color: #fff;
          font-size: 12px;
          line-height: 30px;
          height: 32px;
          display: inline-block;
        }
      }
    }
  }
}

.offline-meeting-black-box {
  height: 250px;
  width: 100%;
  background-color: #3c3e49;
  border-radius: 3px;
  position: relative;
  .meeting-detail {
    color: #9fa1ad;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    .meeting-heading {
      color: #fff;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
    .meeting-info {
      margin: 15px 0;
    }
    .meeting-recording-url {
      word-break: break-all;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}
