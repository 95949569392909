/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

@import '~ag-grid-community/styles/ag-grid.min.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';
@import '~ag-grid-community/styles/agGridBalhamFont.min.css';

// @import '~@ctrl/ngx-emoji-mart/picker';
// @import '~ngx-draggable-resize/css/resizable.min';

@import "app/stylesheets/main.scss";
@import "app/stylesheets/toolkit-page-global.scss";
@import "app/stylesheets/mini-studio.scss";
@import "app/stylesheets/theme-color.scss";
@import "app/stylesheets/responsive.scss";
@import "app/stylesheets/material-design-iconic-font.css";
@import "app/stylesheets/custom-font.css";

@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~video.js/dist/video-js.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'app/stylesheets/transcript_and_video_feature_popup.scss';
@import '~@angular/cdk/overlay-prebuilt.css';

.flag {
  background-image: url(assets/images/country-flags.jpg) !important;
}

.col-12,
.col-lg-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

// Redesign style starts
html {
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  font-family: Rubik, sans-serif;
  font-size: 1.4rem;
  line-height: 1.43;
  color: #3c3e49;
  background-color: #fff;
  min-width: 32rem;

  &.dark-theme {
    background-color: #20272d !important;
    color: #d0d2da !important;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #d0d2da;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
}

// Redesign style ends

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

fa-icon.refresh svg {
  animation: fa-spin 2s linear infinite;
}
@keyframes fa-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
