body {
  &.blue {

    .footer,
    .main-header {
      background: #618dcc;
      box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .projects-detail {
      .team-allocation {
        .feature-grouping-wrap {
          .feature-group-item {
            background: url('../../assets/images/featuregroup-bg-blue.png') left top no-repeat #fff;
            background-size: 101% 44px;

            &:hover {
              background: url('../../assets/images/featuregroup-bg-blue.png') left -1px top no-repeat rgba(97, 141, 204, 0.15);
              border-color: rgba(97, 141, 204, 0.5);
              background-size: 102% 44px;
            }

            .feature-wid-count {
              .feature-count-circle {
                background: rgba(97, 141, 204, 0.2);

                .inner-circle-feature {
                  background: #618dcc;
                }
              }
            }
          }
        }
      }
    }

    ul.left-nav {
      li {
        a {
          background-image: url("../../assets/images/dashboard-double-icon_blue.png");

          &.projects-icon {
            background-image: url("../../assets/images/projects-double-icon_blue.png");
          }

          &.spec-icon {
            background-image: url("../../assets/images/spec-double-icon_blue.png");
          }

          &.partners-icon {
            background-image: url("../../assets/images/partners-double-icon_blue.png");
          }

          &.managers-icon {
            background-image: url("../../assets/images/managers-double-icon_blue.png");
          }

          &.accounts-icon {
            background-image: url("../../assets/images/accounts-double-icon_blue.png");
          }

          &.repository-icon {
            background-image: url("../../assets/images/repository-double-icon_blue.png");
          }

          &.bchat-icon {
            background-image: url('../../assets/images/builder-chat-double-icon-revamp.png');
          }

          &:hover {
            color: #618dcc;
          }

          &.active {
            color: #618dcc;
            border-left: solid 4px #618dcc;
          }
        }
      }
    }

    ul.ongoing-dashboard-nav {
      background-color: rgba(97, 141, 204, 0.1);

      li {
        &:hover {
          a {
            background-color: #618dcc;
          }
        }

        &.active {
          a {
            background-color: #618dcc;
          }
        }
      }
    }

    .folder-main-block {
      .project-card {
        border-top: solid 6px #618dcc;

        &:hover {
          border-color: #618dcc;
          background: #eff3fa;
        }
      }

      .selected {
        .project-card {
          border-color: #618dcc;
          background: #eff3fa;
        }
      }
    }

    .competitors-block,
    .feature-block {
      .tag {
        &:hover {
          background-color: #618dcc;
        }
      }

      .custom-phase-tag {
        .del-phase {
          border: solid 1px #618dcc;
          background: #eff3fa;
        }
      }
    }

    .build-card-box,
    .tag {
      border-color: #618dcc;
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: #618dcc;
            border-color: #618dcc;
          }
        }
      }
    }

    .projects-tab {
      .projectDetail {
        .project-info-top {
          background-color: #618dcc;
        }

        .product-template-block {
          h3 {
            background: #618dcc;
          }
        }

        .basic-info-phases {
          .phases-main-block {
            .phases-block {
              &.custom-phase {
                h3 {
                  background: #9f4dff;
                }
              }

              h3 {
                background: #618dcc;
              }
            }
          }
        }
      }
    }
  }

  &.purple {

    .footer,
    .main-header {
      background: #ad80d1;
      box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .projects-detail {
      .team-allocation {
        .feature-grouping-wrap {
          .feature-group-item {
            background: url('../../assets/images/featuregroup-bg-purple.png') left top no-repeat #fff;
            background-size: 101% 44px;

            &:hover {
              background: url('../../assets/images/featuregroup-bg-purple.png') left -1px top no-repeat rgba(173, 128, 209, 0.15);
              border-color: rgba(173, 128, 209, 0.5);
              background-size: 102% 44px;
            }

            .feature-wid-count {
              .feature-count-circle {
                background: rgba(173, 128, 209, 0.2);

                .inner-circle-feature {
                  background: #ad80d1;
                }
              }
            }
          }
        }
      }
    }

    ul.left-nav {
      li {
        a {
          background-image: url("../../assets/images/dashboard-double-icon_purple.png");

          &.projects-icon {
            background-image: url("../../assets/images/projects-double-icon_purple.png");
          }

          &.spec-icon {
            background-image: url("../../assets/images/spec-double-icon_purple.png");
          }

          &.partners-icon {
            background-image: url("../../assets/images/partners-double-icon_purple.png");
          }

          &.managers-icon {
            background-image: url("../../assets/images/managers-double-icon_purple.png");
          }

          &.accounts-icon {
            background-image: url("../../assets/images/accounts-double-icon_purple.png");
          }

          &.repository-icon {
            background-image: url("../../assets/images/repository-double-icon_purple.png");
          }

          &.bchat-icon {
            background-image: url('../../assets/images/builder-chat-double-icon-revamp.png');
          }

          &:hover {
            color: #ad80d1;
          }

          &.active {
            color: #ad80d1;
            border-left: solid 4px #ad80d1;
          }
        }
      }
    }

    ul.ongoing-dashboard-nav {
      background-color: rgba(173, 128, 209, 0.1);

      li {
        &:hover {
          a {
            background-color: #ad80d1;
          }
        }

        &.active {
          a {
            background-color: #ad80d1;
          }
        }
      }
    }

    .folder-main-block {
      .project-card {
        border-top: solid 6px #ad80d1;

        &:hover {
          border-color: #ad80d1;
          background: #f7f2fa;
        }
      }

      .selected {
        .project-card {
          border-color: #ad80d1;
          background: #f7f2fa;
        }
      }
    }

    .competitors-block,
    .feature-block {
      .tag {
        &:hover {
          background-color: #ad80d1;
        }
      }

      .custom-phase-tag {
        .del-phase {
          border: solid 1px #ad80d1;
          background: #f7f2fa;
        }
      }
    }

    .build-card-box,
    .tag {
      border-color: #ad80d1;
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: #ad80d1;
            border-color: #ad80d1;
          }
        }
      }
    }

    .projects-tab {
      .projectDetail {
        .project-info-top {
          background-color: #ad80d1;
        }

        .product-template-block {
          h3 {
            background: #ad80d1;
          }
        }

        .basic-info-phases {
          .phases-main-block {
            .phases-block {
              &.custom-phase {
                h3 {
                  background: #4fa399;
                }
              }

              h3 {
                background: #ad80d1;
              }
            }
          }
        }
      }
    }
  }

  &.lightGreen {

    .footer,
    .main-header {
      background: #8ace8a;
      box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .projects-detail {
      .team-allocation {
        .feature-grouping-wrap {
          .feature-group-item {
            background: url('../../assets/images/featuregroup-bg-light-green.png') left top no-repeat #fff;
            background-size: 101% 44px;

            &:hover {
              background: url('../../assets/images/featuregroup-bg-light-green.png') left -1px top no-repeat rgba(138, 206, 138, 0.15);
              border-color: rgba(138, 206, 138, 0.5);
              background-size: 102% 44px;
            }

            .feature-wid-count {
              .feature-count-circle {
                background: rgba(138, 206, 138, 0.2);

                .inner-circle-feature {
                  background: #8ace8a;
                }
              }
            }
          }
        }
      }
    }

    ul.left-nav {
      li {
        a {
          background-image: url("../../assets/images/dashboard-double-icon_light-green.png");

          &.projects-icon {
            background-image: url("../../assets/images/projects-double-icon_light-green.png");
          }

          &.spec-icon {
            background-image: url("../../assets/images/spec-double-icon_lightgreen.png");
          }

          &.partners-icon {
            background-image: url("../../assets/images/partners-double-icon_light-green.png");
          }

          &.managers-icon {
            background-image: url("../../assets/images/managers-double-icon_light-green.png");
          }

          &.accounts-icon {
            background-image: url("../../assets/images/accounts-double-icon_light-green.png");
          }

          &.repository-icon {
            background-image: url("../../assets/images/repository-double-icon_lightGreen.png");
          }

          &.bchat-icon {
            background-image: url('../../assets/images/builder-chat-double-icon-revamp.png');
          }

          &:hover {
            color: #8ace8a;
          }

          &.active {
            color: #8ace8a;
            border-left: solid 4px #8ace8a;
          }
        }
      }
    }

    ul.ongoing-dashboard-nav {
      background-color: rgba(138, 206, 138, 0.1);

      li {
        &:hover {
          a {
            background-color: #8ace8a;
          }
        }

        &.active {
          a {
            background-color: #8ace8a;
          }
        }
      }
    }

    .folder-main-block {
      .project-card {
        border-top: solid 6px #8ace8a;

        &:hover {
          border-color: #8ace8a;
          background: #f6fbf6;
        }
      }

      .selected {
        .project-card {
          border-color: #8ace8a;
          background: #f6fbf6;
        }
      }
    }

    .competitors-block,
    .feature-block {
      .tag {
        &:hover {
          background-color: #8ace8a;
        }
      }

      .custom-phase-tag {
        .del-phase {
          border: solid 1px #8ace8a;
          background: #f6fbf6;
        }
      }
    }

    .build-card-box,
    .tag {
      border-color: #8ace8a;
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: #8ace8a;
            border-color: #8ace8a;
          }
        }
      }
    }

    .projects-tab {
      .projectDetail {
        .project-info-top {
          background-color: #8ace8a;
        }

        .product-template-block {
          h3 {
            background: #8ace8a;
          }
        }

        .basic-info-phases {
          .phases-main-block {
            .phases-block {
              &.custom-phase {
                h3 {
                  background: #9f4dff;
                }
              }

              h3 {
                background: #8ace8a;
              }
            }
          }
        }
      }
    }
  }

  &.grey {

    .footer,
    .main-header {
      background: #8ea0a8;
      box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .projects-detail {
      .team-allocation {
        .feature-grouping-wrap {
          .feature-group-item {
            background: url('../../assets/images/featuregroup-bg-grey.png') left top no-repeat #fff;
            background-size: 101% 44px;

            &:hover {
              background: url('../../assets/images/featuregroup-bg-grey.png') left -1px top no-repeat rgba(142, 160, 168, 0.15);
              border-color: rgba(142, 160, 168, 0.5);
              background-size: 102% 44px;
            }

            .feature-wid-count {
              .feature-count-circle {
                background: rgba(142, 160, 168, 0.2);

                .inner-circle-feature {
                  background: #8ea0a8;
                }
              }
            }
          }
        }
      }
    }

    ul.left-nav {
      li {
        a {
          background-image: url("../../assets/images/dashboard-double-icon_gray.png");

          &.projects-icon {
            background-image: url("../../assets/images/projects-double-icon_gray.png");
          }

          &.spec-icon {
            background-image: url("../../assets/images/spec-double-icon_grey.png");
          }

          &.partners-icon {
            background-image: url("../../assets/images/partners-double-icon_gray.png");
          }

          &.managers-icon {
            background-image: url("../../assets/images/managers-double-icon_gray.png");
          }

          &.accounts-icon {
            background-image: url("../../assets/images/accounts-double-icon_gray.png");
          }

          &.repository-icon {
            background-image: url("../../assets/images/repository-double-icon_gray.png");
          }

          &.bchat-icon {
            background-image: url('../../assets/images/builder-chat-double-icon-revamp.png');
          }

          &:hover {
            color: #8ea0a8;
          }

          &.active {
            color: #8ea0a8;
            border-left: solid 4px #8ea0a8;
          }
        }
      }
    }

    ul.ongoing-dashboard-nav {
      background-color: rgba(173, 186, 192, 0.1);

      li {
        &:hover {
          a {
            background-color: #8ea0a8;
          }
        }

        &.active {
          a {
            background-color: #8ea0a8;
          }
        }
      }
    }

    .folder-main-block {
      .project-card {
        border-top: solid 6px #8ea0a8;

        &:hover {
          border-color: #8ea0a8;
          background: #f7f8f9;
        }
      }

      .selected {
        .project-card {
          border-color: #8ea0a8;
          background: #f7f8f9;
        }
      }
    }

    .competitors-block,
    .feature-block {
      .tag {
        &:hover {
          background-color: #8ea0a8;
        }
      }

      .custom-phase-tag {
        .del-phase {
          border: solid 1px #8ea0a8;
          background: #f7f8f9;
        }
      }
    }

    .build-card-box,
    .tag {
      border-color: #8ea0a8;
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: #8ea0a8;
            border-color: #8ea0a8;
          }
        }
      }
    }

    .projects-tab {
      .projectDetail {
        .project-info-top {
          background-color: #8ea0a8;
        }

        .product-template-block {
          h3 {
            background: #8ea0a8;
          }
        }

        .basic-info-phases {
          .phases-main-block {
            .phases-block {
              &.custom-phase {
                h3 {
                  background: #9f4dff;
                }
              }

              h3 {
                background: #8ea0a8;
              }
            }
          }
        }
      }
    }
  }

  &.softBlue {

    .footer,
    .main-header {
      background: #5688a1;
      box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .projects-detail {
      .team-allocation {
        .feature-grouping-wrap {
          .feature-group-item {
            background: url('../../assets/images/featuregroup-bg-softblue.png') left top no-repeat #fff;
            background-size: 101% 44px;

            &:hover {
              background: url('../../assets/images/featuregroup-bg-softblue.png') left -1px top no-repeat rgba(86, 136, 161, 0.15);
              border-color: rgba(79, 163, 153, 0.5);
              background-size: 102% 44px;
            }

            .feature-wid-count {
              .feature-count-circle {
                background: rgba(79, 163, 153, 0.2);

                .inner-circle-feature {
                  background: #5688a1;
                }
              }
            }
          }
        }
      }
    }

    ul.left-nav {
      li {
        a {
          background-image: url("../../assets/images/dashboard-double-icon_soft-blue.png");

          &.projects-icon {
            background-image: url("../../assets/images/projects-double-icon_soft-blue.png");
          }

          &.spec-icon {
            background-image: url("../../assets/images/spec-double-icon_soft-blue.png");
          }

          &.partners-icon {
            background-image: url("../../assets/images/partners-double-icon_soft-blue.png");
          }

          &.managers-icon {
            background-image: url("../../assets/images/managers-double-icon_soft-blue.png");
          }

          &.accounts-icon {
            background-image: url("../../assets/images/accounts-double-icon_soft-blue.png");
          }

          &.repository-icon {
            background-image: url("../../assets/images/repository-double-icon_soft-blue.png");
          }

          &.bchat-icon {
            background-image: url('../../assets/images/builder-chat-double-icon-revamp.png');
          }

          &:hover {
            color: #5688a1;
          }

          &.active {
            color: #5688a1;
            border-left: solid 4px #5688a1;
          }
        }
      }
    }

    ul.ongoing-dashboard-nav {
      background-color: rgba(86, 136, 161, 0.1);

      li {
        &:hover {
          a {
            background-color: #5688a1;
          }
        }

        &.active {
          a {
            background-color: #5688a1;
          }
        }
      }
    }

    .folder-main-block {
      .project-card {
        border-top: solid 6px #5688a1;

        &:hover {
          border-color: #5688a1;
          background: #eef3f5;
        }
      }

      .selected {
        .project-card {
          border-color: #5688a1;
          background: #eef3f5;
        }
      }
    }

    .competitors-block,
    .feature-block {
      .tag {
        &:hover {
          background-color: #5688a1;
        }
      }

      .custom-phase-tag {
        .del-phase {
          border: solid 1px #5688a1;
          background: #eef3f5;
        }
      }
    }

    .build-card-box,
    .tag {
      border-color: #5688a1;
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: #5688a1;
            border-color: #5688a1;
          }
        }
      }
    }

    .projects-tab {
      .projectDetail {
        .project-info-top {
          background-color: #5688a1;
        }

        .product-template-block {
          h3 {
            background: #5688a1;
          }
        }

        .basic-info-phases {
          .phases-main-block {
            .phases-block {
              &.custom-phase {
                h3 {
                  background: #9f4dff;
                }
              }

              h3 {
                background: #5688a1;
              }
            }
          }
        }
      }
    }
  }
}