%customScrollY {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.commonPopUp {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  overflow-y: auto;

  .popOverlay {
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .popHolder {
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    z-index: 3;
    padding: 40px;
    border-radius: 3px;
    width: 100%;
    max-height: 700px;
    @extend %customScrollY;
    overflow-y: auto;
    .closePopUp {
      position: absolute;
      cursor: pointer;
      z-index: 9;
      top: 15px;
      right: 15px;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      background: #fff;
      color: #3c3e49;
      border: 1px solid #dfe0e6;
      border-radius: 50px;
      &:hover {
        background: #f8f9fa;
      }
    }
  }

  &.active {
    opacity: 1;
    z-index: 9999999;
    display: block;
    .popOverlay {
      background: rgba(0, 0, 0, 0.3);
      animation: popupfade 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
}

@-webkit-keyframes popupfade {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.3);
  }
}

@keyframes popupfade {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes popupanimation {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes popupanimation {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 768px) {
  .commonPopUp {
    .popHolder {
      padding: 20px;
      .closePopUp {
        top: 8px;
        right: 8px;
      }
    }
  }
}
