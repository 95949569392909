@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('../../assets/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/MyriadPro-Regular.otf') format('opentype'),
    url('../../assets/fonts/MyriadPro-Regular.woff') format('woff'),
    url('../../assets/fonts/MyriadPro-Regular.ttf') format('truetype'),
    url('../../assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('../../assets/fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/MyriadPro-Bold.otf') format('opentype'),
    url('../../assets/fonts/MyriadPro-Bold.woff') format('woff'),
    url('../../assets/fonts/MyriadPro-Bold.ttf') format('truetype'),
    url('../../assets/fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$revamp-font: 'Rubik', sans-serif;
$body-font: 'Work Sans', sans-serif;
$heading-font: 'Rubik', sans-serif;
$revamp-theme-color: #6200ea;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 2px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-right: none;
    border-left: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

.form-control::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #ccc;
}

.form-control:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: #ccc;
}

.form-control::-moz-placeholder,
input::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

.form-control:-moz-placeholder,
input:-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

body {
  font-family: $body-font;
  font-weight: 400;
}

a,
a:focus {
  outline: none;
}

li,
ul {
  list-style: none;
  padding: 0;
}

.cr-desc {
  ul {
    list-style: inherit;
    padding-left: 2rem !important;
    li {
      list-style: disc;
    }
  }
  ol {
    list-style: inherit;
    padding-left: 2rem !important;
    li {
      list-style: decimal;
    }
  }
}

div {
  outline: none;
}

.main-header {
  height: 50px;
  background-color: #4fa399;
  box-shadow: 1.1px 1.6px 2px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  z-index: 900;
  top: 0;
  left: 0;

  .list-inline {
    margin-bottom: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;

      &.badge-label {
        color: #fff;
      }

      &.myTasklabel {
        margin-right: 30px;
        cursor: pointer;
      }

      &.badge-stl {
        margin-left: 10px;
        background: rgba(255, 255, 255, 0.8);
        padding: 2px 7px;
        margin-right: 30px;
        border-radius: 2px;

        em {
          vertical-align: middle;
          padding-left: 4px;
          color: #212121;
          font-size: 14px;

          &.cstm-down {
            cursor: pointer;
          }
        }
      }
    }
  }

  .manager-status-dropdown {
    position: relative;
    top: 9px;
    left: 181px;

    &.not-available-status {
      left: 203px;
    }

    &.do-not-disturb-status {
      left: 211px;
    }

    div.item-status-manager {
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 13px;
      display: block;

      &:before {
        margin-right: 7px;
        position: static;
        display: inline-block;
        vertical-align: -2px;
      }

      &:hover {
        background: #f1f1f1;
      }
    }

    .triangle {
      width: 50px;
      height: 22px;
      position: absolute;
      overflow: hidden;
      top: -22px;
      right: 13px;
      box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);

      &:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background: #fff;
        transform: rotate(45deg);
        top: 17px;
        left: 25px;
        border: solid 1px #ccc;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.logo {
  // padding-top: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 46px;

  img {
    // max-width: 160px;
    max-height: 22px;
  }
}

.middle-sec {
  position: relative;
  height: 100vh;

  background: {
    image: url("../../assets/images/bg-Image.png");
    position: center center;
    size: cover;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 40px;
}

.pm-dashboard-modal {
  background: #fff;
  width: 100%;
  max-width: 510px;
  border: solid 1px #ccc;
  padding: 30px;

  h2 {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin: 0;
    line-height: 22px;

    a {
      color: #4fa399;
      text-decoration: none;
    }
  }
}

.common-form {
  input:focus {
    outline: 0;
  }

  input.common-input,
  select {
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
    margin: 0;
    height: 40px;
    border: none;
    border-bottom: solid 1px #ccc;
    width: 100%;
    background: #fff;
    padding-top: 15px;
    transition:
      padding-top 0.2s ease,
      margin-top 0.2s ease;
    overflow-x: hidden;
  }

  select {
    outline: none;
    -webkit-appearance: none;

    background: {
      image: url("../../assets/images/down-arrow.png");
      position: right 23px;
      repeat: no-repeat;
    }
  }

  input.common-input+label,
  select+label {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    width: 0;
    border-top: 1px solid red;
    -webkit-transition: width 0.4s ease;
    transition: width 0.4s ease;
    height: 0;
  }

  input.common-input:focus+label,
  select:focus+label {
    width: 100%;
  }

  input.common-input:focus,
  input.common-input:valid,
  select:focus,
  select:valid {
    padding-top: 15px;
  }

  input.common-input:focus+label>span,
  input.common-input:valid+label>span,
  select:focus+label>span,
  select:valid+label>span {
    top: -42px;
    left: 0;
    font-size: 10px;
    color: #212121;
  }

  input.common-input+label>span>span,
  select+label>span>span {
    color: #ff0000;
  }

  input.common-input:valid+label,
  select:valid+label {
    border-color: green;
  }

  input.common-input:invalid,
  select:invalid {
    box-shadow: none;
  }

  input.common-input+label>span,
  select+label>span {
    font-weight: 400;
    margin: 0;
    position: absolute;
    color: #212121;
    font-size: 13px;
    top: -38px;
    left: 0;
    -webkit-transition:
      top 0.2s ease,
      font-size 0.2s ease,
      color 0.2s ease;
    transition:
      top 0.2s ease,
      font-size 0.2s ease,
      color 0.2s ease;
  }

  .form-group {
    margin-bottom: 2rem;

    &.crossInviteSignup {
      input {
        pointer-events: none;
        background: #f5f5f5;
      }
    }

    .phoneLabels {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        font-size: 13px;
        color: #212121;
        font-weight: 400;

        span {
          color: #ff0000;
        }
      }

      label+label {
        margin-left: 30px;
      }
    }

    &.phoneNumber {
      text-align: left;

      .input-group {
        margin-top: -12px;

        .form-control {
          border: none;
          border-bottom: solid 1px #ccc;
          box-shadow: none !important;
          height: 24px;
          padding: 0;
          margin-left: 0;
          border-radius: 0;
        }

        button {
          border-bottom: solid 1px #ccc;
          border-radius: 0;
          width: 30px;
          height: 24px;
          padding: 0;
          box-shadow: none;
          display: flex;
          justify-content: flex-start;

          .arrow-down {
            margin-top: 3px;
          }
        }
      }

      label {
        font-size: 10px;
        color: #212121;
        font-weight: 400;
        position: relative;
        top: -10px;
        display: block;
        margin-bottom: 0;

        span {
          color: #ff0000;
        }
      }

      div.input-val {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        height: auto;
        padding: 0 9px;
        vertical-align: bottom;
      }
    }
  }
}

.comm-login-module-btn {
  width: 100%;
  background: #4fa399 !important;
  border: none;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 400;
}

.suggestions-row {
  display: none;

  label {
    font-size: 12px;
    margin: 0 6px 0 0;
  }
}

ul.suggestions-list {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    float: left;
    line-height: 10px;
    color: #4fa399;
    font-size: 12px;
    border-right: solid 1px #ccc;
    padding: 0 10px;

    &:last-child {
      border: none;
    }
  }
}

.invalid-feedback {
  font-size: 12px;
  font-weight: 500;
  color: #ed1c24;
  text-align: left;
  display: block;
}

.footer {
  height: 40px;
  background-color: #4fa399;
  position: fixed;
  width: 100%;
  z-index: 123;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    color: #fff;
    margin: 0;
    font-weight: 300;
    line-height: 30px;
  }
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0 !important;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: solid 1px #4fa399;
}

.custom-checkbox input:checked~.checkmark {
  background-color: #4fa399;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked~.checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox label {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  margin: 0;
  padding-left: 25px;
}

.pm-dashboard-modal p a.forget-pass {
  color: #000;
}

.clear-icon {
  color: #111;
  position: absolute;
  top: 14px;
  right: 15px;
  opacity: 0.6;
  cursor: pointer;
}

.tick {
  position: relative;
  display: inline-block;

  &:before {
    width: 5px;
    height: 10px;
    border: solid #4fa399;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    left: -14px;
    top: 4px;
  }
}

.reset-success-msg {
  position: fixed;
  width: 100%;
  max-width: 70%;
  @include border-radius(5px);
  padding: 12px 30px;
  top: 58px;
  left: 15%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin: 0;
    padding: 0 20px;
  }

  img {
    width: 22px;
  }

  a {
    font-size: 16px;
    color: #fff;
  }
}

.passwordNotMatch {
  position: relative;

  i {
    color: #f6574b;
    font-size: 18px;
    position: relative;
    top: 1px;
    margin-right: 5px;
  }

  &:before {
    display: none;
  }
}

/*===========My Account style===========*/

.middle-dashboard {
  background-color: #fff;
  height: 100vh;
  position: relative;
  padding: 53px 0 47px 60px;

  @media only screen and (max-width: 991.98px) {
    padding: 53px 0 47px 0;
  }
}

.filterPanel {
  width: 325px;
  height: calc(100vh - 50px);
  position: fixed;
  left: auto;
  right: -330px; // right: 0;
  top: auto;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;

  &.open {
    right: 0;
  }

  .filterPanelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ccc;
    height: 50px;
    padding: 0 25px;

    h3 {
      color: #333;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      position: relative;
      top: 2px;
      font-family: $heading-font;
    }

    .closeReset {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        color: #e9645d;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        top: 1px;
      }

      em {
        color: #000;
        font-size: 20px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }

  .filterPanelContent {
    height: calc(100% - 100px);
    overflow: auto;

    .collapse {
      display: block !important;
      max-height: 0 !important;
      overflow: hidden !important;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;

      &.show {
        max-height: 500px !important;
      }

      .card-body {
        margin-bottom: 25px;
        padding: 0;
        border: none;

        .custom-checkbox {
          position: relative;
          margin-bottom: 15px;

          label {
            padding-left: 30px;

            .checkmark {
              left: 0;
              border-color: #ccc;
              @include border-radius(2px);

              &:after {
                border-color: #4fa399;
              }
            }
          }

          input:checked~.checkmark {
            background-color: transparent;
            border-color: #4fa399;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .contentRow {
      padding: 0 25px;
      border-bottom: solid 1px #ccc;

      button {
        display: flex;
        background: none;
        border: none;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        margin: 0;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        width: 100%;
        cursor: pointer;
        font-family: $heading-font;
        em,
        fa-icon {
          font-size: 10px;
        }
      }

      .ngx-slider {
        .ngx-slider-bar {
          height: 7px;
          background: #4fa399;
        }

        .ngx-slider-pointer {
          cursor: pointer;
          width: 17px;
          height: 17px;
          top: -5px;
          background-color: #fff;
          border: solid 2px #4fa399;
          outline: none;

          &:after {
            top: 3px;
            left: 3px;
            opacity: 0;
          }
        }
      }

      .featureCount {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;

        p {
          @include border-radius(70px);
          background-color: #fb8c00;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          padding: 5px 14px;
        }
      }

      .moreHide {
        font-size: 12px;
        font-weight: 400;
        color: #e9645d;
        cursor: pointer;
      }

      .projects-search-filter {
        justify-content: flex-start;
        margin: 0 0 15px;

        .projects-search {
          margin-right: 0;
          width: 100%;
        }
      }

      .no-results-found {
        text-align: center;

        img {
          max-width: 80px;
          margin: 7px 0;
        }
      }

      .fullContent {
        overflow: auto;
        max-height: 400px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }

  .filterPanelFooter {
    background: #efefef;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    height: 50px;
    padding: 0 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      background: #e9645d;
      @include border-radius(4px);
      height: 30px;
      min-width: 105px;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.right-content {
  height: 100%;
  position: relative;
  padding: 15px 0;

  .no-results-found {
    position: absolute;
    width: 100%;
    height: calc(100vh - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 100px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: #333;
      margin-bottom: 0;
    }

    &.hide {
      display: none;
    }

    &.specSearch {
      width: 100%;
      height: calc(100vh - 238px);
    }
  }
}

.main-inner-block {
  padding: 0;

  @media only screen and (max-width: 991px) {
    padding: 0;
  }
}

.profile-hide {
  display: none;
}

.profile-show {
  display: block;
}

h3.top-title {
  font-family: $heading-font;
  font-size: 16px;
  color: #777;
  font-weight: 400;
  margin: 0;
  padding: 15px 0;
}

.my-account-block h3.top-title {
  border-bottom: solid 1px #ccc;
}

.change-pic {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  .profile-pic-validation {
    color: #ff0000;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 12px;
    bottom: -20px;
    left: 0;
  }

  img {
    width: 170px;
    height: 170px;
    @include border-radius(50%);
    border: solid 1px #ccc;
    margin-bottom: 10px;
  }

  .attach-file {
    margin-left: 0;
    font-size: 12px;
    color: #4fa399;
    font-weight: 400;
    margin-top: 10px;
  }

  i {
    margin-right: 5px;
  }
}

.role {
  margin-top: 30px;

  h3 {
    font-size: 14px;
    color: #777;
    font-weight: 500;
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px;
    padding: 10px 0;
  }
}

span.role-name {
  background: #f4f4f4;
  border: solid 1px #ccc;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  @include border-radius(4px);
  padding: 9px;
  display: block;
  height: 41px;
  cursor: default;
}

.change-pass-main,
.my-account-detail {
  padding-right: 80px;
}

.my-account-block label {
  font-size: 12px;
  color: #777;
  font-weight: 400;
  margin-bottom: 15px;
  display: block;
}

.filled-input {
  background: #f4f4f4;
  border: solid 1px #ccc;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  @include border-radius(4px);
  padding: 9px;
  display: block;
  cursor: default;
  margin-bottom: 15px;
  height: 41px;
}

.edit-input {
  background: #fff;
  border: solid 1px #ccc;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  @include border-radius(4px);
  padding: 9px;
  margin-bottom: 0;
  outline: none;
  width: 100%;
}

.my-account-detail {

  .select-box,
  .other-input {
    background-color: #f4f4f4;
    pointer-events: none;

    &.editable-field {
      background-color: #fff;
      pointer-events: initial;
    }

    select {
      border-color: #ccc;
    }
  }
}

ul.phone-edit-list {
  position: absolute;
  right: 0;
  top: 13px;
  margin: 0;

  li {
    float: left;
    margin-left: 20px;

    a {
      text-decoration: none;
      font-size: 14px;
      color: #e9645d !important;
      font-weight: 400;
      cursor: pointer;
    }

    button {
      text-decoration: none;
      font-size: 14px;
      color: #e9645d !important;
      font-weight: 400;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  i {
    margin-right: 3px;
  }
}

.change-pass-btn {
  background-color: #e9645d;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  font-style: normal;
  box-shadow: none !important;
}

.change-pass-input {
  background: #fff;
  border: solid 1px #ccc;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  @include border-radius(4px);
  padding: 9px;
  margin-bottom: 15px;
  outline: none;
  width: 100%;
}

.change-pass-main h3.top-title {
  margin-bottom: 1.5rem;
}

.change-pass-btn.cancel {
  border: solid 1px #e9645d;
  background: transparent;
  color: #e9645d;
  margin-right: 6px;
}

ul.themes-color-list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-right: 80px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4fa399;
    width: 65px;
    height: 65px;
    @include border-radius(4px);
    color: #fff;
    margin-right: 30px;
    cursor: pointer;

    img {
      width: 27px;
      display: none;
    }

    &.color2 {
      background-color: #26c6da;
    }

    &.color3 {
      background-color: #7460ee;
    }

    &.color4 {
      background-color: #1e88e5;
    }

    &.color5 {
      background-color: #99abb4;
    }

    &.color6 {
      background-color: #fb7252;
    }

    &.color7 {
      background-color: #967bdc;
    }

    &.color8 {
      background-color: #d870ad;
    }

    &.active img {
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.my-account-block {
  position: relative;

  .reset-success-msg {
    background: rgba(17, 17, 17, 0.8);
    max-width: 70%;
    z-index: 99999;
  }
}

.projects-block {
  position: relative;
  padding-bottom: 48px;

  &.todosBlock {
    .project-success-msg {
      display: none;
    }
  }

  h3 {
    display: inline-block;
  }

  .developerDetails {
    width: 100%;
  }
}

.projects-search-filter {
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  &.home {
    .projects-search {
      height: 30px;
    }
  }
}

.projects-search {
  background-color: rgba(155, 155, 155, 0.1);
  @include border-radius(4px);
  height: 25px;
  min-width: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #dadada;
  padding: 0 6px;
  margin-right: 20px;

  input[type='submit'] {
    width: 13px;
    height: 13px;
    overflow: hidden;
    background-image: url("../../assets/images/search-icon.png");
    background-repeat: no-repeat;
    background-size: 13px 13px;
    background-position: center center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0;
    text-indent: 50px;
  }

  input[type='text'] {
    width: 100%;
    margin-left: 6px;
    font-size: 12px;
    text-overflow: ellipsis;
    font-weight: 400;
    color: #555;
    height: 24px;
    background: transparent;
    border: none;
    outline: none;
  }
}

ul.sort-filter-list {
  // display: flex;
  display: none;
  justify-content: center;
  margin: 0;

  li {
    margin-right: 20px;

    img {
      margin-right: 6px;
    }

    i {
      margin-right: 6px;
    }

    a {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      cursor: text;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.filterIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    border-radius: 3px;
    border: solid 1px #dadada;
    margin-right: 20px;
    cursor: pointer;
    &.bc-view-wrap {
      width: auto;
      padding: 2px;
      .bc-view-btn {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        height: 100%;
        cursor: pointer;
        transition: all 0.5s;
        &.active {
          background: #F3EBFF;
        }
      }
    }
    &.list-view-col-selection {
      position: relative;
      &:hover {
        .col-selection-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
      .col-selection-tooltip {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 8px);
        color: #fff;
        background: #3c3e49;
        border-radius: 3px;
        padding: 8px 16px;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: all .5s;
        &::before {
          content: "";
          position: absolute;
          left: calc(50% - 4px);
          top: -8px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #3c3e46;
        }
      }
    }

    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 30px;
      position: relative;
      z-index: 99;

      em,
      i,
      fa-icon {
        font-size: 15px;
        color: #555;

        &.arrow {
          font-size: 7px;
          margin-left: 6px;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &.sort {
      position: relative;

      .sortBlock {
        width: 220px;
        min-height: 100px;
        height: auto;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #ccc;
        background-color: #fff;
        position: absolute;
        right: -37px;
        top: 48px;
        cursor: default;
        @include border-radius(3px);
        z-index: 99;
        display: none;

        .triangle {
          width: 80px;
          height: 30px;
          position: absolute;
          overflow: hidden;
          top: -30px;
          right: 10px;
          box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
        }

        .triangle:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background: #fff;
          transform: rotate(45deg);
          top: 19px;
          left: 25px;
          border: solid 1px #ccc;
          box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
        }

        .sortBlockHeader {
          border-bottom: solid 1px #ccc;
          padding: 14px 20px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            color: #333;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
          }

          span {
            color: #e9645d;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
          }
        }

        .sortBlockContent {
          padding: 15px 20px;

          .sortBlockContentRow {
            margin-bottom: 15px;

            h4 {
              font-size: 12px;
              font-weight: 700;
              color: #333;
              margin: 0;
            }

            ul.sortingList {
              margin: 10px 0;
              padding: 0;

              li {
                font-size: 14px;
                font-weight: 400;
                color: #777;
                line-height: 1.8;
                cursor: pointer;

                &.active {
                  color: #4fa399;

                  &:after {
                    font-family: "Material-Design-Iconic-Font", sans-serif;
                    content: "\f26b";
                    font-size: 17px;
                    margin-left: 6px;
                    position: relative;
                    top: 2px;
                  }
                }
              }
            }
          }
        }

        &.open {
          display: block;
        }
      }
    }

    &.calendar {
      position: relative;

      .calendarBlock {
        width: 700px;
        min-height: 100px;
        height: auto;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #ccc;
        background-color: #fff;
        position: absolute;
        right: -107px;
        top: 48px;
        cursor: default;
        @include border-radius(3px);
        z-index: 99;
        display: none;

        .triangle {
          width: 80px;
          height: 30px;
          position: absolute;
          overflow: hidden;
          top: -30px;
          right: 80px;
          box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
        }

        .triangle:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background: #fff;
          transform: rotate(45deg);
          top: 19px;
          left: 25px;
          border: solid 1px #ccc;
          box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
        }

        .calendarBlockHeader {
          border-bottom: solid 1px #ccc;
          padding: 14px 20px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .calendarBlockHeaderLeft {
            position: relative;
          }

          h3 {
            color: #333;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            position: relative;
            padding: 0 0 4px;
            z-index: 99;
            font-family: $heading-font;

            em {
              font-size: 12px;
              margin-left: 5px;
              position: relative;
              top: -1px;
            }
          }

          span {
            color: #e9645d;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
          }

          .headerDropdownBlock {
            width: 270px;
            min-height: 50px;
            height: auto;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #ccc;
            background-color: #fff;
            position: absolute;
            right: -29px;
            top: 37px;
            left: auto;
            cursor: default;
            @include border-radius(3px);
            z-index: 999;
            display: none;

            .triangle {
              width: 80px;
              height: 30px;
              position: absolute;
              overflow: hidden;
              top: -30px;
              right: -10px;
              box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
            }

            .triangle:after {
              content: "";
              position: absolute;
              width: 25px;
              height: 25px;
              background: #fff;
              transform: rotate(45deg);
              top: 19px;
              left: 25px;
              border: solid 1px #ccc;
              box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
            }

            .headerDropdownContent {
              padding: 10px 15px;

              ul {
                margin: 0;

                li {
                  color: #555;
                  font-size: 15px;
                  line-height: 35px;
                  cursor: pointer;

                  &.active {
                    color: #4fa399;

                    &:after {
                      font-family: "Material-Design-Iconic-Font", sans-serif;
                      content: "\f26b";
                      font-size: 17px;
                      margin-left: 6px;
                      position: relative;
                      top: 1px;
                    }
                  }
                }
              }
            }

            &.open {
              display: block;
            }
          }
        }

        .calendarBlockContent {
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;

          .calendarLeft {
            display: flex;
            justify-content: flex-start;

            .calendarInnerBlock {
              padding-right: 15px;
            }

            img {
              max-width: 100%;
            }

            .calendarInnerBlock {
              .titleLabel {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 18px;

                label {
                  color: #565656;
                  font-size: 13px;
                  min-width: 70px;
                  margin: 0;
                }

                .calendarDate {
                  color: #383838;
                  font-size: 13px;
                  margin: 0;
                  background: #e8e8e8;
                  padding: 6px;
                  width: 100%;
                  min-height: 31px;
                }
              }
            }
          }

          .calendarSideFilter {
            border-left: solid 1px #ccc;
            min-height: 250px;
            width: 170px;

            ul {
              margin: 20px 0 0;
              padding: 0 0 0 20px;

              li {
                color: #1e1e1e;
                font-size: 13px;
                opacity: 0.5;
                line-height: 35px;
                cursor: pointer;

                &.active {
                  color: #4fa399;
                  opacity: 1;

                  &:after {
                    font-family: "Material-Design-Iconic-Font", sans-serif;
                    content: "\f26b";
                    font-size: 17px;
                    margin-left: 6px;
                    position: relative;
                    top: 1px;
                  }
                }
              }
            }
          }
        }

        .calendarBlockFooter {
          border-top: solid 1px #ccc;
          padding: 12px 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            background: #e9645d;
            border: none;
            @include border-radius(4px);
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            outline: none;
            min-width: 105px;
            height: 33px;
            cursor: pointer;

            &.cancel {
              background: transparent;
              color: #e9645d;
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        &.open {
          display: block;
        }
      }
    }
  }
}

ul.cancel-save-list {
  display: flex;
  justify-content: center;
  margin: 0;

  li {
    margin-right: 8px;

    img {
      margin-right: 0px;
    }

    i {
      margin-right: 0px;
    }

    a {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.projects-tab .nav-tabs {
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 14px;

  .nav-link {
    font-family: $heading-font;
    border: none;
    font-size: 13.5px;
    color: #888;
    font-weight: 400;
    padding: 0.5rem 0;
    position: relative;
    bottom: 1px;

    &:hover {
      color: #888;
    }

    i {
      font-size: 13.5px;
      margin-right: 2px;
    }
  }

  .nav-item {
    margin-bottom: -2px;
    padding: 0 0.5rem;

    &.NoKickOffDate {
      position: relative;

      a {
        pointer-events: none;
        opacity: 0.4;
      }

      .installmentContentTooltip {
        width: auto;
        min-width: 450px;
        min-height: 20px;
        height: auto;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #ccc;
        background-color: #fff;
        position: absolute;
        left: 16px;
        top: -35px;
        z-index: 9;
        padding: 8px 15px;
        @include border-radius(3px);
        display: none;

        .triangle {
          width: 50px;
          height: 22px;
          position: absolute;
          overflow: hidden;
          bottom: -22px;
          left: 15px;
          box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
        }

        .triangle:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background: #fff;
          transform: rotate(45deg);
          top: -22px;
          left: 25px;
          border: solid 1px #ccc;
          box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
        }

        p {
          font-size: 14px;
          color: #555;
          margin: 0;
          font-weight: 400;
        }
      }

      &:hover .installmentContentTooltip {
        display: block;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.builderCareNav {
      position: relative;
      overflow: hidden;

      a {
        padding-left: 0.8rem;

        img {
          // width: 13.5px;
          position: absolute;
          left: 0.2rem;
          top: 8px !important;
        }
      }
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    color: #fbbf1e;
    background: transparent;
    border-bottom: solid 2px #fbbf1e;
  }

  .nav-item.show .nav-link.ongoing-tab-color,
  .nav-link.ongoing-tab-color.active {
    color: #6cb5d8;
    border-color: #6cb5d8;
  }

  .nav-item.show .nav-link.blocked-tab-color,
  .nav-link.blocked-tab-color.active {
    color: #e86c65;
    border-color: #e86c65;
  }

  .nav-item.show .nav-link.completed-tab-color,
  .nav-link.completed-tab-color.active {
    color: #62b26d;
    border-color: #62b26d;
  }

  .nav-item.show .nav-link.builderCare-tab-color,
  .nav-link.builderCare-tab-color.active {
    color: #00cf00;
    border-color: #00cf00;

    img {
      top: -33px !important;
    }
  }

  .nav-item.show .nav-link.archive-tab-color,
  .nav-link.archive-tab-color.active {
    color: #aeaeae;
    border-color: #aeaeae;
  }

  .nav-item.show .nav-link.assigned-tab-color,
  .nav-link.assigned-tab-color.active {
    color: #b39cdd;
    border-color: #b39cdd;
  }

  .nav-item.show .nav-link.freemium-tab-color,
  .nav-link.freemium-tab-color.active {
    color: #ffa500;
    border-color: #ffa500;
  }
}

.projects-tab .col-lg-3 {
  padding-left: 6px;
  padding-right: 6px;
}

.projects-tab .col-lg-4 {
  padding-left: 6px;
  padding-right: 6px;
}

.projects-tab {

  .purposeRow,
  .searchMemberRow {
    .col-lg-3 {
      padding-left: 15px;
      padding-right: 15px;

      .assign-btn {
        height: 40px;
        min-width: 190px;
        font-size: 16px;
        margin-top: 0;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .gray-box {
      height: 40px;
    }
  }
}

.projects-tab .modal-dialog .col-lg-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.projects-home {
  .tab-content {
    padding: 0 9px;
  }
}

.projects-tab {
  position: relative;

  .spec-nav {
    .nav.nav-tabs {
      .nav-item {
        .nav-link {
          i.spec-instant-icon {
            display: inline-block;
            vertical-align: middle;
            background-image: url("../../assets/images/spec-instant.png");
            background-size: 14px 14px;
            width: 14px;
            height: 14px;
          }

          i.spec-schedule-icon {
            display: inline-block;
            vertical-align: middle;
            background-image: url("../../assets/images/spec-schedule.png");
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
          }

          &.active {
            border-bottom-color: #e9645d;
            color: #e9645d;

            i.spec-instant-icon {
              background-image: url("../../assets/images/spec-instant-active.png");
            }

            i.spec-schedule-icon {
              background-image: url("../../assets/images/spec-schedule-active.png");
            }
          }
        }
      }
    }
  }

  .resetBtn {
    font-size: 14px;
    font-weight: 500;
    color: #e9645d;
    outline: none;
    position: absolute;
    right: 0;
    top: 8px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .createNewBtn {
    position: absolute;
    right: 0;
    top: 7px;
    background: transparent;
    border: none;
    color: #e9645d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;

    i {
      // margin-right: 4px;
      padding: 4px;
    }

    a {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  .tab-content {
    position: relative;

    #clientInfo {
      padding: 0 9px;
    }

    #accInfo {
      .account-main-block {
        border-top: none;
        padding: 0 0 10px;
        height: 100%;

        .account-info-table {
          position: relative;

          .checklist-common-list {
            .invoice-table-inner {
              .table-content {
                max-height: none;
                overflow: auto;
                height: auto;
              }

              .table-header {
                padding-right: 0;
              }

              table {
                tr {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  border-bottom: solid 1px #ccc;
                  background-color: #fff;

                  td {
                    border: none;
                    width: 18%;
                    min-height: 51px;
                    padding: 13px;
                    color: #333;
                    max-width: 360px;

                    &:last-child {
                      width: 23%;
                      position: relative;

                      img.beta-ribbon {
                        width: 50px;
                        position: absolute;
                        right: 0;
                        top: 0;
                      }
                    }

                    &:nth-child(3) {
                      width: 23%;
                    }

                    // &:nth-child(4) {
                    //   width: 10%;
                    // }
                  }

                  th {
                    border: none;
                    width: 18%;
                    min-height: 46px;
                    padding: 13px;
                    font-family: $heading-font;

                    &:last-child {
                      width: 23%;
                    }

                    &:nth-child(3) {
                      width: 23%;
                    }

                    // &:nth-child(4) {
                    //   width: 10%;
                    // }
                  }

                  &:hover {
                    background-color: #edf6f5;
                  }
                }

                thead {
                  tr {
                    &:hover {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.todos {
    .tab-content {
      padding: 0;
    }
  }

  h4.block-title {
    color: #333;
    font-family: $heading-font;
    font-size: 15px;
    font-weight: 400;
    margin: 0;

    // padding: 0 0 8px;
    // border-bottom: solid 1px #ccc;
    // min-height: 24px;
    span {
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      // padding-right: 105px;
      white-space: nowrap;

      @media (max-width: 1279px) {
        max-width: 100px !important;
      }

      @media (max-width: 991px) {
        max-width: 220px !important;
      }
    }
  }

  #projectDetail {

    // margin-left: -9px;
    // margin-right: -9px;
    .col-lg-3 {
      padding-left: 15px;
      padding-right: 15px;
    }

    .col-lg-4 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .projectDetail {
    h2 {
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 0px;
      color: #333;
      // border-bottom: solid 1px #ccc;
      margin: 0 0 20px;
      font-family: $heading-font;
    }

    .blockRequestBar {
      width: 100%;
      height: 43px;
      background-color: #f1920a;
      @include border-radius(4px);
      padding: 0 15px;
      margin-bottom: 12px;
      justify-content: space-between;
      align-items: center;
      display: none;

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        margin: 0;

        em {
          font-size: 20px;
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }

      button {
        border: none;
        background: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        outline: none;

        &:disabled {
          opacity: 0.6;
          cursor: initial;
        }

        em {
          font-size: 15px;
        }
      }

      &.show {
        display: flex;
      }
    }

    .project-info-top {
      background: #4fa399;
      @include border-radius(4px);
      padding: 15px;
      color: #fff;
      padding-bottom: 30px;

      .project-info-row {
        margin-bottom: 8px;

        .nameInitials {
          display: flex;
          margin: 0;
          padding: 0;

          li {
            background: #e9e9e9;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            line-height: 12px;
            border: 1px solid #ffffff;
            margin-left: -8px;
            text-transform: uppercase;
          }
        }

        .invite-btn {
          margin: 0 0 10px;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #000000;
          background: #00d659;
          border-radius: 3px;
          border: none;
          padding: 6px 16px;
          display: flex;
          align-items: center;
          min-width: 121px;
          justify-content: center;
          cursor: pointer;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        h3 {
          font-family: $heading-font;
          font-weight: 400;
          font-size: 21px;
          margin: 0;
        }

        p {
          font-weight: 400;
          font-size: 17px;
          line-height: 18px;
          margin: 0;

          cite {
            font-style: normal;

            &.tooltip_msg_dis {
              position: relative;

              &:hover .msgTooltip-wrp {
                display: block;
              }

              .msgTooltip-wrp {
                display: none;
                position: absolute;
                right: 0;
                left: -14px;
                top: 0;
              }

              .triangle {
                width: 50px;
                height: 22px;
                position: absolute;
                overflow: hidden;
                top: -22px;
                right: initial;
                left: -16px;
                box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);

                &::after {
                  content: "";
                  position: absolute;
                  width: 25px;
                  height: 25px;
                  background: #fff;
                  transform: rotate(45deg);
                  top: 17px;
                  left: 25px;
                  border: solid 1px #ccc;
                  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
                }
              }

              .msgTooltip {
                min-width: 418px;
                padding: 10px;
                right: initial;
                display: flex;
                left: 0;
                top: 23px;

                p {
                  font-size: 13px;
                }
              }
            }

            &.old_week {
              text-decoration: line-through;
              font-size: 12px;
            }

            &.new_week {
              padding-left: 5px;
            }
          }

          img {
            width: 27px;
            position: relative;
            top: -4px;
          }

          span {
            font-size: 12px;
            opacity: 0.8;
            margin-top: 3px;
            display: inline-block;

            &.strikeDate {
              text-decoration: line-through;
              opacity: 1;
              margin: 0;
              display: inline;
            }
          }
        }

        ul.list-inline {
          margin: 0;

          li {
            display: inline-block;
            position: relative;

            &:first-child {
              &:before {
                display: none;
              }
            }

            &:last-child {
              a.view-build-card-btn {
                padding-right: 0;
              }

              span.view-build-card-btn {
                padding-right: 0;
              }
            }

            &:before {
              content: "";
              height: 17px;
              width: 1px;
              background: #fff;
              position: absolute;
              left: 0;
              top: 7px;
            }

            .view-build-card-btn {
              em {
                padding-right: 8px;
                font-size: 15px;

                &.cstm-down {
                  font-size: 16px;
                  padding-right: 0;
                  padding-left: 6px;
                  cursor: pointer;
                }
              }

              .dropdown-menu {
                left: auto;
                right: -13px;
                top: 14px;
                padding: 0;

                .dropdownMenuInner {
                  max-height: 120px;
                  overflow-y: auto;

                  &::-webkit-scrollbar {
                    width: 5px;
                  }

                  &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #888;
                  }

                  &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                  }
                }
              }

              .dropdown-item {
                font-size: 14px;
                font-weight: 400;
                color: #777;
                letter-spacing: 0.2px;
                border-top: 1px solid #e9ecef;

                &:first-child {
                  border: none;
                }
              }

              .triangle {
                width: 50px;
                height: 22px;
                position: absolute;
                overflow: hidden;
                top: -22px;
                right: 3px;
                box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
              }

              .triangle:after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #fff;
                transform: rotate(45deg);
                top: 19px;
                left: 25px;
                border: solid 1px #ccc;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
              }

              position: relative;
              background: none;
              outline: none;
              font-size: 14px;
              font-weight: 400;
              line-height: 30px;
              color: #fff;
              padding: 0px 10px;
              cursor: pointer;
              text-decoration: none;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .progress {
        height: 4px;
        margin-top: 12px;
        @include border-radius(15px);
        background: rgba(255, 255, 255, 0.25);
        position: relative;
        overflow: initial;

        .progress-bar {
          font-size: 9px;
          background: #fff;
          color: #000;
          font-weight: 700;
          @include border-radius(15px);

          span {
            position: absolute;
            left: 0;
            top: 9px;
            color: #fff;
            font-size: 10px;
            font-weight: 400;
            opacity: 0.9;
          }
        }
      }
    }

    .project-info-row2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 50px 0 50px;

      .info-block {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 33.33%;
        float: left;

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #000;
          line-height: 21px;
          position: relative;

          label {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            color: #676b7e;
            margin-bottom: 3px;
            line-height: 14px;

            img {
              width: 19px;
              height: 19px;
              position: relative;
              top: -2px;
              left: 4px;
            }
          }

          .info-block-detail {
            display: flex;
            align-items: center;
          }

          span {
            font-size: 12px;
            line-height: normal;
            color: #9b9fb1;
            margin-left: 6px;
          }

          em {
            font-style: normal;
          }

          sub {
            font-size: 18px;
            font-weight: 500;
            top: 0;
            bottom: initial;
          }
        }
      }
    }

    .product-template-block {
      /*border: solid 1px #ccc; */
      border-radius: 0px;
      min-height: 124px;
      padding: 20px;
      margin: 10px 0;
      position: relative;
      background: #f8f9fa;
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
        font-size: 20px;
        font-family: $heading-font;
        color: #000;
        background: #4fa399;
        position: relative;
      }

      .product-template-con {
        position: relative;
        width: 100%;

        .product-template-dis {
          .round-img-cover {
            width: 44px;
            min-width: 44px;
            height: 44px;
            background: #ecedf0;
          }

          display: flex;
          align-items: center;
          margin-top: 16px;
        }

        .product-template-dis {
          .multi-repeat {
            display: flex;

            p {
              max-width: initial;
            }
          }

          .main-repeater {
            display: flex;
            flex-wrap: wrap;
            max-width: 159px;
          }

          .main-repeater+p {
            align-self: flex-end;
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #000;
            //float: left;
            padding-right: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 173px;
            font-family: $revamp-font;

            @media (max-width: 1279px) {
              max-width: 100px !important;
            }
          }
        }
      }

      button.view-all-btn {
        color: #e9645d;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: underline;
        outline: none;
        border: none;
        background: none;
        bottom: 5px;
        right: 8px;
        padding: 0;
        position: absolute;
      }
    }

    .project-brief {
      margin: 50px 0;
      font-family: $revamp-font;

      .angular-editor-textarea {
        margin-top: 0;
        min-height: 120px !important;
      }

      .custom-tabs {
        display: flex;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 0px 0;
        color: #afb2c0;

        li {
          margin-right: 50px;
          border-bottom: 2px solid transparent;
          padding-bottom: 5px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: $revamp-theme-color;
            border-color: $revamp-theme-color;
          }
        }
      }

      textarea {
        @include border-radius(3px); // background-color: #f4f4f4;
        border: 1px solid #c0c3ce;
        padding: 10px;
        font-size: 14px;
        color: #555;
        font-weight: 400;
        line-height: 1.4;
        min-height: 100px;
        width: 100%;
        resize: none;
        outline: none;
        pointer-events: none;
        display: block;
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.editable-area {
          background-color: #fff;
          pointer-events: initial;
          resize: vertical;
          padding-bottom: 15px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      ul.edit-project-brief {
        position: absolute;
        right: 10px;
        top: -2px;
        margin: 0;

        li {
          float: left;
          margin-left: 20px;

          button {
            text-decoration: none;
            font-size: 14px;
            color: #e9645d !important;
            font-weight: 400;
            cursor: pointer;
            background: none;
            border: none;
            outline: none;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        i {
          margin-right: 3px;
        }
      }
    }

    .basic-info-phases {
      button.add-custom-phase-btn {
        position: absolute;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        border: none;
        right: 10px;
        top: 0;
        cursor: pointer;
        background: #3c3e49;
        outline: none;
        height: 32px;
        padding: 0 16px;
        border-radius: 3px;

        em {
          margin-right: 3px;
        }
      }

      .phases-main-block {
        padding: 0 30px;
        // margin: 30px 0;
        position: relative;

        button.control {
          position: absolute;
          left: 0;
          top: 50%;
          background: none;
          margin-top: -31px;
          color: #ccc;
          font-size: 40px;
          border: none;
          outline: none;
          cursor: pointer;

          &.right {
            left: auto;
            right: 0;
          }

          &.arrow-hide {
            display: none;
          }
        }

        .phases-block {
          min-height: 295px;
          border: solid 1px #ccc;
          @include border-radius(4px);
          position: relative;
          width: 23.6%;
          margin-right: 20px;
          max-width: 255px;

          label {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            color: #333;
          }

          &.custom-phase {
            h3 {
              background: #9f4dff;
            }
          }

          h3 {
            margin: 0;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background: #4fa399;
            min-width: 150px;
            @include border-radius(15px);
            padding: 6px 15px;
            position: absolute;
            top: -14px;
            left: 50%;
            -webkit-transform: translate(-50%);
            -moz-transform: translate(-50%);
            -o-transform: translate(-50%);
            transform: translate(-50%);
          }

          .del-phase {
            width: 27px;
            height: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px #4fa399;
            background: #edf6f5;
            position: absolute;
            right: -9px;
            top: -12px;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            @include border-radius(50%);

            em {
              color: #e9645d;
              font-size: 16px;
            }
          }

          &:hover .del-phase {
            opacity: 1;
            visibility: visible;
          }

          .plateforms {
            border-bottom: solid 1px #ccc;
            width: 100%;
            float: left;
            padding: 8px 8px 20px;
            margin-top: 10px;

            .plateform-inner {
              margin: 8px 0;
              display: flex;
              justify-content: center;
              align-items: center;

              .plateform-circel {
                width: 44px;
                height: 44px;
                border: solid 1px #ccc;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                font-size: 13px;
                color: #333;
                font-weight: 400;
                @include border-radius(50%);
                margin: 0 6px;

                img {
                  max-width: 23px;
                  max-height: 26px;
                  -webkit-filter: grayscale(100%);
                  filter: grayscale(100%);
                }

                span {
                  position: absolute;
                  bottom: -17px;
                  left: 0;
                  font-weight: 700;
                  color: #777;
                  font-size: 9px;
                  text-align: center;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                &.all {
                  cursor: pointer;
                  color: #333;
                  font-size: 30px;
                }
              }
            }
          }

          .speed-features {
            border-bottom: solid 1px #ccc;
            width: 100%;
            float: left;
            padding: 8px 0;

            .speed {
              width: 50%;
              float: left;
              padding: 0 8px 0;
              min-height: 92px;
              border-right: solid 1px #ccc;

              .speed-image-block {
                width: 100%;
                display: block;
                margin: 6px 0;
                text-align: center;

                img {
                  max-width: 80px;
                }
              }

              p {
                margin: 18px 0 0;
                font-size: 14px;
                color: #e9645d;
                text-align: center;
              }

              span {
                width: 100%;
                text-align: center;
                float: left;
                color: #3ea196;
                font-size: 12px;
              }
            }

            .features {
              width: 50%;
              float: left;
              padding: 0 8px 0;

              h2 {
                color: #e9645d;
                font-size: 30px;
                font-weight: 500;
                padding: 0;
                width: 100%;
                border: none;
                margin: 5px 0 0;
                text-align: center;

                .feature-badge {
                  font-size: 11px;
                  font-weight: 400;
                  vertical-align: middle;
                  background-color: #4fa399;
                  padding-top: 0.35em;
                  padding-bottom: 0.35em;
                  cursor: pointer;
                }
              }

              button.view-features {
                color: #e9645d;
                font-size: 12px;
                font-weight: 500;
                width: 100%;
                background: none;
                border: none;
                padding: 0;
                outline: none;
                text-decoration: underline;
                cursor: pointer;
                display: inline-block;
              }
            }
          }

          .delivery-date {
            width: 100%;
            float: left;
            padding: 8px;
            min-height: 59px;

            h4 {
              color: #333;
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .description-block {
            border-bottom: solid 1px #ccc;
            width: 100%;
            float: left;
            padding: 8px;
            margin-top: 10px;

            p {
              font-size: 12px;
              color: #777;
              height: 50px;
              overflow: hidden;
              white-space: normal;
              margin: 0;
            }

            span {
              color: #e9645d;
              display: block;
              width: 100%;
              text-align: center;
              margin-top: 4px;
              font-size: 12px;
              text-decoration: underline;
            }
          }

          .duration-cost-block {
            border-bottom: solid 1px #ccc;
            width: 100%;
            float: left;
            padding: 9px 0;

            .duration-cost {
              width: 50%;
              float: left;
              padding: 0 8px 0;
              border-right: solid 1px #ccc;

              .duration {
                margin: 6px 0;
              }

              span {
                font-size: 10px;
                color: #777;
                display: block;
              }

              &.fcost {
                border-right: none;
              }
            }
          }

          .start-end-date {
            width: 100%;
            float: left;
            padding: 8px;

            h4 {
              color: #333;
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
        }

        .drag-scroll-content {
          overflow: hidden;
          padding: 30px 20px 0;
        }
      }
    }

    .cost-row {
      margin: 50px 0 50px;

      label {
        color: #4fa399;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
        font-family: $heading-font;
      }

      .cost-block {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 25px;
          margin-right: 10px;
        }

        p {
          font-size: 16px;
          color: #333;
          font-weight: 500;
          margin: 0;
        }

        .change-cost-input {
          background: none;
          border: none;
          border-radius: 0;
          box-shadow: none;
          border-bottom: solid 1px #ccc;
        }

        input.change-cost-input[type='number']::-webkit-inner-spin-button,
        input.change-cost-input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        .final-cost-block {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          button {
            background: #e9645d;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border: none;
            box-shadow: none;
            border-radius: 4px;
            line-height: 30px;
            padding: 0 20px;
            margin: 0 0 0 15px;
            outline: none;
            cursor: pointer;
          }
        }
      }

      .addEqalSymbol {
        font-size: 40px;
        margin-top: 15px;
      }

      .ac-sep {
        &:before {
          width: 1px;
          height: 100%;
          background: #000;
          content: "";
          position: absolute;
          right: 20px;
          opacity: 0.3;
          top: 7px;
        }
      }
    }

    .contract-row {
      ul.pdf-contract-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0;
        font-family: $revamp-font;

        li {
          width: calc(33.33% - 15px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          flex-wrap: wrap;
          padding: 20px;
          background: #f8f9fa;
          border-radius: 4px;

          .boxIcon {
            width: 43px;
            height: auto;
          }

          .file-con {
            padding: 0 0 0 16px;
            width: calc(100% - 43px);

            h5, h1 {
              font-size: 18px;
              line-height: 21px;
              color: #000000;
              margin-bottom: 6px;
              font-weight: 500 !important;
            }

            span {
              color: #3c3e49;
              font-size: 12px;
              font-weight: 400;
              display: block;
            }
          }

          .down-prev {
            display: flex;
            width: 100%;
            padding-right: 0;
            /* justify-content: flex-end; */
            text-align: right;

            button {
              // color: #e9645d;
              /* font-size: 14px; */
              /* font-weight: 500; */
              border: none;
              background: none;
              outline: none;
              /* text-decoration: underline; */
              width: auto;
              display: block;
              /* text-align: center; */
              cursor: pointer;
              margin: 20px 0 0;
              padding: 0;
              /* font-family: "Arial"; */
              font-size: 12px;
              color: #000000;
              line-height: 14px;
              border-bottom: 2px solid #00d659;
              padding-bottom: 3px;

              &:last-child {
                margin-left: 30px;
              }
            }

            span.no-data {
              color: #e9645d;
              font-size: 14px;
              font-weight: 500;
              border: none;
              background: none;
              outline: none;
              width: 100%;
              display: block;
              text-align: center;
              font-family: 'Arial', sans-serif;
              margin: 12px 0;
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  &.csat-tab {
    .csat-main-block {
      margin: 30px 0;

      .csat-block {
        min-height: 200px;
        border: solid 1px #ccc;
        @include border-radius(4px);
        margin-bottom: 30px;
        padding: 12px;

        .csat-chart {
          display: flex;
          justify-content: center;
          margin: 30px 0 15px;
        }

        p {
          color: #777;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }

        .csat-heading-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 0 12px;
          border-bottom: solid 1px #ccc;
          margin-bottom: 30px;

          h3 {
            font-size: 14px;
            font-weight: 500;
            color: #333;
            margin: 0;
          }

          .date-block {
            font-size: 12px;
            font-weight: 500;
            color: #333;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              color: #777;
              font-weight: 400;
            }

            em {
              font-style: normal;
            }

            .custom-radio {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border-right: solid 1px #ccc;
              padding: 3px 0;

              .radio {
                label {
                  margin: 0 25px 0 0;
                }
              }
            }

            .yearCalendar {
              margin: 0 20px;

              fa-icon {
                color: #ff7058;
                font-size: 14px;
                position: relative;
                top: 1px;
                cursor: pointer;

                &.disabled {
                  background: {
                    color: #f4f4f4;
                  }
                }
              }

              span {
                margin: 0 14px;
              }
            }
          }
        }

        &.csatGraph {
          margin-bottom: 0;
          min-height: 240px;
          position: relative;
        }
      }

      .no-results-found {
        width: 100%;
        height: calc(100% - 50px);
        left: 0;
        top: 50px;

        img {
          width: 55px;
          margin-bottom: 10px;
        }

        p {
          color: #333;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .raphael-group-26-creditgroup {
        display: none;
      }
    }

    .no-results-found {
      width: 100%;
      height: calc(100vh - 190px);
      left: 0;
      top: 0;

      img {
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}

.project-card {
  background: #fff;
  border: solid 1px #e1e8f5;
  box-shadow: 0 3px 8px 0 rgba(132, 139, 146, 0.4);
  @include border-radius(2px);
  position: relative;
  // height: 228px;
  // padding: 9px;
  margin-bottom: 22px;

  .projectCardHeader {
    min-height: 87px;
    box-shadow: 0 3px 8px 0 rgba(153, 167, 181, 0.16);
    display: flex;
    justify-content: flex-start;
    padding: 8px 12px;
    width: 100%;
    position: relative;
    z-index: 14;

    .projectCardHeaderLeft {
      width: 100%;

      .projectCardHeaderBottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 20px;

        &.is-alias {
          margin-bottom: 4px;
        }

        p {
          font-size: 10px;
          color: #9fa9bc;
          margin: 1px 2px 0;

          .project-revised {
            color: #62b26d;
          }

          &.viewLiveLinks {
            color: #62b26d;
            font-weight: 500;
            position: relative;
            z-index: 11;

            span {
              display: inline-block;
              text-decoration: underline;
              margin-left: 4px;
            }
          }

          &.customPrototype {
            color: #eb2f96;
            font-weight: 500;
            margin-right: 0.5rem;
          }

          &.alias {
            color: #262626;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 88px);
            margin: 0;

            .alias-tag {
              background: #e5e7ff;
              border-radius: 4px;
              padding: 3px 6px;
              font-size: 12px;
              line-height: 22px;
              color: #3c3e49;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .projectCardHeaderRight {
      position: absolute;
      right: 5px;
      top: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 11;

      .holdToggle {
        &.holdToggleOff {
          .holdOff {
            display: block;
          }

          .holdOn {
            display: none;
          }

          .bugFixOn {
            background: #ecedf0;

            p {
              color: #c0c3ce;
              padding: 0 16px;
            }

            .circle {
              left: 4px;
              background: #afb2c0;
              top: 4px;
            }
          }

          em {
            left: auto;
            right: 4px;
          }
        }

        .holdOff {
          display: none;
        }

        .bugFixOn {
          position: relative;
          display: flex;
          width: 53px;
          height: 21px;
          background: #66bb6a;

          p {
            text-align: left;
            line-height: 20px;
            font-size: 10px;
            padding: 0 8px;
          }

          em {
            color: #fff;
            position: absolute;
            top: 2px;
          }

          .circle {
            position: absolute;
            left: 37px;
            top: 4px;
            height: 13px;
            width: 13px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .csatMinCard {
        position: relative;
        display: block;
        margin-left: 8px;
        z-index: 11;

        img {
          width: 25px;
        }

        .instalment-content {
          position: absolute;
          right: -9px;
          top: -38px;
          height: 42px;
          display: none;

          .instalment-content-inner {
            background: #fff;
            border: solid 1px #ccc;
            @include border-radius(2px);
            padding: 4px 8px;
            width: 220px;

            p {
              font-size: 14px;
              color: #333;
              margin: 0;
              font-weight: 400;

              a {
                color: #d64d4d;
                text-decoration: underline;
                margin-left: 4px;
              }
            }

            .arrow-down {
              width: 50px;
              height: 15px;
              position: absolute;
              overflow: hidden;
              top: 30px;
              right: 8px;
              box-shadow: 0 10px 10px -17px transparent;

              &::after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: -22px;
                left: 25px;
                border: solid 1px #ccc;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0);
              }
            }
          }
        }

        &:hover .instalment-content {
          display: block;
        }
      }

      .bugFixOn {
        width: 75px;
        height: 20px;
        background: #00cf00;
        @include border-radius(13px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3px;
        margin-top: 4px;

        img {
          width: 14px;
        }

        p {
          font-size: 11px;
          color: #fff;
          font-weight: 500;
          margin: 0;
        }

        .circle {
          width: 16px;
          height: 16px;
          background: #fff;
          @include border-radius(13px);
        }
      }

      .bugFixOff {
        width: 75px;
        height: 20px;
        background: #e86c65;
        @include border-radius(13px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3px;
        margin-top: 4px;

        img {
          width: 14px;
        }

        p {
          font-size: 11px;
          color: #fff;
          font-weight: 500;
          margin: 0;
        }

        .circle {
          width: 16px;
          height: 16px;
          background: #fff;
          @include border-radius(13px);
        }
      }
    }
  }

  img.beta-ribbon {
    width: 50px;
    position: absolute;
    right: 6px;
    top: 0;
  }

  .progress {
    height: 6px;
    background: #eaedf5;
    position: absolute;
    overflow: initial;
    width: 100%;
    box-shadow: 0 3px 8px 0 rgba(153, 167, 181, 0.16);
    left: 0;
    top: -6px;
    z-index: 9;
    border-radius: 0;

    .progress-bar {
      font-size: 9px;
      background: #6cb5d8;
      color: #000;
      font-weight: 700;
      border-radius: 0;

      span {
        position: absolute;
        right: 0;
        top: 7px;
        color: #6cb5d8;
        font-size: 10px;
        font-weight: 500;
        padding: 1px 4px 0;
        background: rgba(108, 181, 216, 0.15);
      }
    }
  }

  &.addNewClient {
    border: dashed 1px #555 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: none;
    height: 228px;

    img {
      width: 21px;
      margin-bottom: 10px;
    }

    p {
      color: #555;
      font-size: 13px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.new-min-card {
  border-top: solid 6px #fbbf1e;

  &:hover {
    &::before {
      background: rgba(249, 191, 32, 0.1);
      border: solid 2px #fbbf1e;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .project-card-bottom-list {
    table td {
      &.grayCardBlock {
        strong {
          .instalment-content {
            .instalment-content-inner {
              width: 317px;
            }
          }
        }
      }
    }
  }
}

.ongoing-card {
  // border-top: solid 6px #6cb5d8;
  border-top: solid 6px transparent;
  position: relative;

  ul.csat-unpaid-list {
    position: absolute;
    right: 0;
    top: 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 22px;
    z-index: 8;
    margin: 0;
    cursor: default;

    .instalment-content {
      position: absolute;
      right: 0;
      top: -40px;
      height: 42px;
      display: none;

      .instalment-content-inner {
        background: #fff;
        border: solid 1px #ccc;
        @include border-radius(2px);
        padding: 4px 8px;
        width: 250px;

        p {
          font-size: 14px;
          color: #333;
          margin: 0;
          font-weight: 400;

          a {
            color: #d64d4d;
            text-decoration: underline;
            margin-left: 4px;
          }
        }

        .arrow-down {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #fff;
          position: absolute;
          right: 18px;
          bottom: 7px;
        }
      }
    }

    img {
      width: 24px;
      display: block;
      margin-top: 2px;
    }

    li {
      padding: 0 10px;
      border-right: solid 1px #ccc;
      cursor: pointer;
      position: relative;

      &:nth-child(2) {
        img {
          position: relative;
          top: -2px;
        }
      }

      &:last-child {
        border: none;
      }

      &.pastDateDetail {
        .instalment-content-inner {
          width: 260px;
        }
      }

      &:hover .instalment-content {
        display: block;
      }
    }

    &.show-beta {
      right: 50px;
    }
  }

  &.red-card {
    border-top: solid 8px #d64d4d;
    border-bottom: solid 1px #d64d4d;
    border-left: solid 1px #d64d4d;
    border-right: solid 1px #d64d4d;

    &:before {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(277, 14, 14, 0.07);
      content: "";
      width: 100%;
      width: calc(100% + 1px);
      // border: solid 1px #d64d4d;
    }
  }

  &.grey-card {
    &:before {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(133, 133, 133, 0.13);
      content: "";
      width: 100%;
    }

    ul.csat-unpaid-list {
      .instalment-content {
        .instalment-content-inner {
          width: 257px;
        }
      }
    }
  }

  &:hover {
    &::before {
      background: rgba(108, 180, 215, 0.1);
      border: solid 2px #6cb4d7;
      border-top: solid 6px #6cb4d7;
      height: calc(100% + 6px);
      width: 100%;
      z-index: 10;
      content: "";
      position: absolute;
      left: 0;
      top: -6px;
    }
  }
}

.assigned-card {
  @extend .ongoing-card;
  border-top: solid 6px #b39cdd;

  &:hover {
    &::before {
      background: rgba(179, 156, 221, 0.1);
      border: solid 2px #b39cdd;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.blocked-card {
  border-top: solid 6px transparent;
  position: relative;

  .progress {
    .progress-bar {
      background: #e86c65;

      span {
        color: #e86c65;
        background: rgba(232, 108, 101, 0.15);
      }
    }
  }

  &:hover {
    &::before {
      background: rgba(232, 108, 101, 0.1);
      border: solid 2px #e86c65;
      border-top: solid 6px #e86c65;
      width: 100%;
      z-index: 10;
      height: calc(100% + 6px);
      content: "";
      position: absolute;
      left: 0;
      top: -6px;
    }
  }
}

.completed-card {
  border-top: solid 6px #62b26d;

  &:hover {
    &::before {
      background: rgba(98, 178, 109, 0.1);
      border: solid 2px #62b26d;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.builderCare-card {
  border-top: solid 6px #00cf00;

  .podListTooltip {
    bottom: -25px !important;
  }

  &:hover {
    &::before {
      background: rgba(0, 207, 0, 0.1);
      border: solid 2px #00cf00;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.freemium-card {
  border-top: solid 6px #ffa500;

  .podListTooltip {
    bottom: -25px !important;
  }

  &:hover {
    &::before {
      background: rgba(255, 165, 0, 0.1);
      border: solid 2px #ffa500;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.archive-card {
  border-top: solid 6px #aeaeae;
}

ul.project-card-list {
  margin: 0;
  min-height: 125px;
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.lg-padding {
    padding-right: 60px;
  }

  li {
    color: #636975;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    strong {
      color: #101c36;
      font-weight: 500;

      .amber {
        color: #ed8a19;
        font-size: 15px;
        position: relative;
        top: 1px;
        z-index: 11;
      }

      .initial {
        margin-right: 3px;
        position: relative;
        z-index: 11;

        &:last-child {
          .sep {
            display: none;
          }
        }
      }
    }

    span {
      &.project-revised {
        color: #4fa399;
        font-weight: 500;
        margin-left: 4px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .podListTooltip {
      position: absolute;
      left: 35px;
      bottom: 70px;
      height: 42px;
      z-index: 99;
      display: none;

      .instalment-content-inner {
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        background: #fff;
        border: 1px solid #f8f9fa;
        padding: 15px;
        width: max-content;
        margin-top: 11px;
        overflow: auto;
        max-height: 203px;
        min-width: 275px;
        box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);
        @include border-radius(2px);

        p {
          font-size: 14px;
          color: #333;
          margin: 0;
          font-weight: 400;

          a {
            color: #d64d4d;
            text-decoration: underline;
            margin-left: 4px;
          }
        }

        .arrow-down {
          width: 50px;
          height: 15px;
          position: absolute;
          overflow: hidden;
          top: -3px;
          left: 0;
          box-shadow: 0 10px 10px -17px transparent;

          &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            background: #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 10px;
            left: 20px;
            border: solid 1px #ccc;
            box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0);
          }
        }

        ul {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding: 10px 0;
            overflow: visible;

            &:nth-child(4n + 1) .initial {
              background: #e6f7ff;
              border-color: #91d5ff;
              color: #1890ff;
            }

            &:nth-child(4n + 2) .initial {
              background: #e8f5e9;
              border-color: #a5d6a7;
              color: #4caf50;
            }

            &:nth-child(4n + 3) .initial {
              background: #fff2e8;
              border-color: #ffbb96;
              color: #fa541c;
            }

            &:nth-child(4n + 4) .initial {
              background: #f4eaff;
              border-color: #d4afff;
              color: #6c00ea;
            }

            .initial {
              width: 28px;
              height: 28px;
              background: #9fa9bc;
              @include border-radius(100%);
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              span {
                font-size: 10px;
                // color: #fff;
                font-weight: 500;
                font-family: $revamp-font;
              }

              .primaryStar {
                /*width: 18px; */
                /* height: 18px; */
                /* border: solid 1px #9fa9bc; */
                /* border-radius: 50%; */
                display: flex;
                /* justify-content: center; */
                /* align-items: center; */
                /* background: #fff; */
                position: absolute;
                top: -5px;
                right: -5px;

                em {
                  background-image: url("../../assets/images/empty-star.svg");
                  background-size: 15px 15px;
                  width: 15px;
                  height: 15px;
                  background-repeat: no-repeat;

                  &.fa-star {
                    background: none;
                  }

                  &.fill-star {
                    background-image: url("../../assets/images/fill-star.svg");
                  }
                }
              }
            }

            .podDetail {
              // width: calc(100% - 55px);
              margin-left: 11px;

              h5 {
                font-size: 14px;
                margin: 0 0 0px 0px;
                font-family: $revamp-font;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #3c3e49;
                letter-spacing: -0.01em;
                font-weight: 400 !important;
                text-transform: capitalize;
              }

              p {
                font-size: 12px;
                color: #9fa9bc;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
    }

    &.podListRow {
      strong:hover .podListTooltip {
        display: block;
      }
    }
  }
}

.create-new-project {
  border: dashed 1px #555 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;

  img {
    width: 21px;
    margin-bottom: 10px;
  }

  p {
    color: #555;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
}

.new-project-inner {
  width: auto;
  text-align: center;
}

.project-card-bottom-list {
  border-top: solid 1px #dcdee2;
  padding: 10px 0;
  height: 60px;

  table {
    width: 100%;

    td {
      text-align: center;
      color: #9fa9bc;
      font-size: 10px;
      font-weight: 400;
      padding: 0 7px;
      border-right: solid 1px #dcdee2;
      position: relative;

      strong {
        font-size: 14px;
        font-weight: 500;
        color: #101c36;
        display: block;
        margin-top: 2px;
        position: relative;

        img {
          width: 15px;
          margin-left: 4px;
        }

        .unpaidIconBlock,
        .pastDateDetail,
        .changeReqIconBlock {
          display: inline-block;
          position: relative;
          z-index: 11;
        }

        .instalment-content {
          position: absolute;
          right: -11px;
          top: -38px;
          height: 42px;
          display: none;

          &.NoPaymentContent {
            display: none;
            top: -64px;
            z-index: 99;

            .instalment-content-inner {
              width: 420px;
            }
          }

          .instalment-content-inner {
            background: #fff;
            border: solid 1px #ccc;
            @include border-radius(2px);
            padding: 4px 8px;
            width: 285px;

            p {
              font-size: 14px;
              color: #333;
              margin: 0;
              font-weight: 400;

              a {
                color: #d64d4d;
                text-decoration: underline;
                margin-left: 4px;
              }
            }

            .arrow-down {
              width: 50px;
              height: 15px;
              position: absolute;
              overflow: hidden;
              top: 30px;
              right: 8px;
              box-shadow: 0 10px 10px -17px transparent;

              &::after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: -22px;
                left: 25px;
                border: solid 1px #ccc;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .unpaidIconBlock:hover .instalment-content {
          display: block;
        }

        .pastDateDetail:hover .instalment-content {
          display: block;
        }

        .changeReqIconBlock:hover .instalment-content {
          display: block;
        }
      }

      &.redCardBlock {
        &::before {
          content: "";
          background: rgba(255, 14, 14, 0.07);
          height: calc(100% + 21px);
          width: 100%;
          position: absolute;
          left: 0;
          top: -10px;
        }

        z-index: 13;

        &+.redCardBlock {
          z-index: 12;
        }
      }

      &.NoPaymentBlock {
        @extend .redCardBlock;
        z-index: 12 !important;

        .unpaidIconBlock:hover+.NoPaymentContent {
          display: none;
        }

        &::before {
          background: none;
          border: 2px solid #dc3545;
        }

        &:hover {
          .NoPaymentContent {
            display: block;
          }
        }
      }

      &.grayCardBlock {
        &::before {
          content: "";
          background: rgba(133, 133, 133, 0.2);
          height: calc(100% + 21px);
          width: 100%;
          position: absolute;
          left: 0;
          top: -10px;
        }

        z-index: 13;
      }

      &:last-child {
        border-right: none;

        &.redCardBlock {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}

.isFirstCardInRow {
  .project-card {
    .project-card-bottom-list {
      table {
        td.grayCardBlock {
          z-index: 14;
        }
      }

      .instalment-content {
        right: auto;
        left: -36px;
        z-index: 9;

        .arrow-down {
          right: auto;
          left: 7px;
        }
      }
    }
  }
}

.red-color {
  color: #d64d4d !important;
}

.completed-card ul.project-card-bottom-list,
.new-min-card ul.project-card-bottom-list {
  justify-content: center;
}

/*======Ongoing Max Card======*/

ul.project-breadcrumb {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  min-height: 21px;
  font-family: $heading-font;

  li {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    padding: 0 6px;

    a {
      color: #555;
      text-decoration: none;
      margin-right: 6px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.active {
      color: #555;
    }

    span.customPrototype {
      color: #eb2f96;
      display: inline-block;
      margin-left: 0.3rem;
    }
  }

  &.viewLiveLinks {
    a {
      color: #9e9e9e;
    }
  }
}

ul.ongoing-dashboard-nav {
  width: 200px;
  height: 100vh;
  background-color: rgba(79, 163, 163, 0.1);
  left: 60px;
  top: 0;
  padding: 65px 10px;
  position: fixed;
  z-index: 1;
  box-shadow: 2.4px 3px 4px 0 rgba(0, 0, 0, 0.1);
  font-family: $heading-font;

  li {
    a {
      font-size: 13px;
      color: #727c86;
      font-weight: 400;
      display: block;
      @include border-radius(3px);
      background-color: transparent;
      text-decoration: none;
      line-height: 34px;
      padding: 0 6px;
      margin-bottom: 6px;
      &.has-num {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &:hover {
      a {
        color: #fff;
        background-color: #4fa399;
      }
    }

    &.active {
      a {
        color: #fff;
        background-color: #4fa399;
        cursor: text;
      }
    }
  }
}

.subMenuClick {
  position: fixed;
  z-index: 1010;
  bottom: 100px;
  right: 30px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #818181;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    display: block;
    width: 20px;
    height: 14px;
    position: relative;
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #333;
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
    }

    &::after {
      content: "";
      display: none;
      width: 100%;
      height: 2px;
      background: #333;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.active {
    span {
      border: none;

      &::before {
        width: 22px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transform-origin: center;
      }

      &::after {
        width: 22px;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%) rotate(-45deg);
        transform-origin: center;
      }
    }
  }
}

.ongoing-tab {

  // margin-top: 10px;
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #e9645d;
    background: transparent;
    border-bottom: solid 2px #e9645d;
  }

  h4 {
    font-size: 13px;
    color: #333;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .add-b-border h4 {
    border-bottom: solid 1px #cccccc;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
}

.gray-box {
  @include border-radius(4px);
  background-color: #f4f4f4;
  border: solid 1px #cccccc;
  padding: 9px;
  font-size: 14px;
  color: #555;
  font-weight: 400;
  line-height: 18px;
  min-height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag {
  @include border-radius(4px);
  background-color: #ffffff;
  border: solid 1px #acacac;
  border-top: solid 4px #4fa399;
  display: inline-block;
  font-size: 14px;
  color: #555;
  font-weight: 400;
  line-height: 56px;
  padding: 0 30px;
  min-width: 135px;
  max-width: 200px;
  text-align: center;
  margin: 10px 15px 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    margin-right: 0;
  }
}

.feature-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tag {
    background-color: #f4f4f4;
    min-width: 167px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #4fa399;
      color: #fff;
    }
  }

  .custom-phase-tag {
    position: relative;

    .del-phase {
      width: 27px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #4fa399;
      background: #edf6f5;
      position: absolute;
      right: 3px;
      top: 0;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      @include border-radius(50%);

      em {
        color: #e9645d;
        font-size: 16px;
      }
    }

    &:hover .del-phase {
      opacity: 1;
      visibility: visible;
    }
  }

  .add-custom-phase {
    border: dashed 1px #555;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 176px;
    padding: 0 30px;
    margin: 5px 15px 10px 0;
    @include border-radius(4px);
    height: 60px;
    cursor: pointer;

    .add-custom-phase-inner {
      text-align: center;

      img {
        width: 16px;
        margin-bottom: 2px;
      }

      p {
        color: #555;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.phases-block-main {
  margin-top: 20px;

  .phases-block {
    width: 100%;
    min-height: 150px;
    border: dashed 1px #ccc;
    padding: 15px;
    position: relative;
    margin-top: 15px;

    h4 {
      margin-bottom: 20px;
    }

    .feature-btn {
      position: absolute;
      right: 16px;
      top: 13px;
      background: transparent;
      border: none;
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      outline: none;
      z-index: 9;
      padding: 0;

      i {
        margin-right: 4px;
      }
    }
  }
}

.delivery-detail {
  label {
    font-size: 12px;
    color: #777;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .gray-box {
    margin-bottom: 20px;
  }

  .actual-cost-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .gray-box {
      width: 100%;
      margin: 0;
    }

    input[type='number'],
    input[type='text'] {
      @include border-radius(4px);
      border: solid 1px #cccccc;
      padding: 9px;
      font-size: 14px;
      color: #555;
      font-weight: 400;
      line-height: 18px;
      min-height: 38px;

      &:focus {
        box-shadow: none;
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    .infoMsg {
      color: #999;
      position: absolute;
      left: 0;
      font-size: 13px;
      top: 43px;
      line-height: 15px;
      font-style: italic;
    }

    .btn {
      @include border-radius(4px);
      background-color: #e9645d;
      font-size: 14px;
      color: #fff;
      box-shadow: none;
      border: solid 1px #e9645d;
      min-width: 85px;
      margin-left: 10px;
    }
  }
}

.insurance-need.custom-radio {
  height: 38px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  padding-left: 2px;

  label {
    margin: 0 30px 0 0;
  }

  .radio-checkmark {
    margin-right: 5px;
  }
}

/*============Custom radio============*/

.radio {
  label {
    display: inline-block;
    position: relative;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ccc;
      @include border-radius(50%);
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: "";
      width: 9px;
      height: 9px;
      left: 4px;
      top: 4px;
      margin-left: -20px;
      @include border-radius(50%);
      background-color: #555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }

  input[type='radio'] {
    opacity: 0;
  }

  input[type='radio']:focus+label::before {
    outline-offset: -2px;
    outline: none;
  }

  input[type='radio']:checked+label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  input[type='radio']:disabled+label {
    opacity: 0.65;
  }

  input[type='radio']:disabled+label::before {
    cursor: not-allowed;
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio-primary {
  input[type='radio']+label::after {
    background-color: #e9645d;
  }

  input[type='radio']:checked+label::before {
    border-color: #ccc;
  }

  input[type='radio']:checked+label::after {
    background-color: #e9645d;
  }
}

.build-card {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
}

.build-card-block {
  margin-right: 30px;

  p {
    color: #555;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
  }
}

.build-card-block:last-child {
  margin-right: 0;
}

.build-card-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 120px;
  @include border-radius(4px);
  background-color: #f4f4f4;
  border: solid 1px #cccccc;
  border-top: solid 4px #4fa399;

  img {
    width: 48px;
  }
}

.competitors-block {
  .tag {
    background-color: #f4f4f4;
    transition: all 0.3s ease-in-out;

    a {
      color: #555;
      text-decoration: none;
      display: inline-block;
    }

    &:hover {
      background-color: #4fa399;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }
}

.ongoing-tab ul.project-card-list {
  li {
    line-height: 30px;

    &:nth-child(2) {
      float: none;
      top: auto;
    }
  }
}

.edit-client-block {
  border-top: solid 6px #62b26d;
  position: relative;
  height: 228px;

  .projectCardHeader {
    .projectCardHeaderLeft {
      h4.block-title {
        max-width: 85%;
      }
    }

    .projectCardHeaderRight {
      bottom: 18px;

      .ownership {
        em {
          margin-left: 8px;

          &.fa-user {
            color: #62b26d;
          }

          &.fa-star {
            color: #ffbb1d;
          }
        }
      }

      p.edit-client {
        color: #e86c65;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  &:hover {
    &::before {
      background: rgba(98, 178, 109, 0.1);
      border: solid 2px #62b26d;
      border-top: none;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.client-modal {
  &.delTask-modal {
    h2 {
      padding: 0;
    }
  }

  .update-feature-changelist {
    p {
      margin-bottom: 4px;
    }

    ul.project-change-feature-list {
      li {
        em {
          vertical-align: middle;
        }

        font-size: 12px;
        color: #777;
        line-height: 1.5;
        font-weight: 500;
      }
    }
  }

  .deny-reason-wrp {
    p {
      margin-bottom: 4px;
    }

    textarea.form-control {
      width: 100%;
      font-size: 12px;
      outline: none;
      resize: none;
      height: 80px;
    }
  }

  &.meetings-modal {
    .modal-dialog {
      max-width: 600px;

      h4 {
        font-size: 16px;
        color: #333;
        margin-bottom: 15px;

        i {
          color: #999;
          font-size: 11px;
          margin-right: 9px;
          float: left;
          margin-top: 5px;
        }
      }

      p {
        font-size: 14px;
        color: #777;
        padding-left: 22px;
        line-height: 24px;
      }
    }
  }

  &.answerPopup {
    h2 {
      padding: 0;
      margin-bottom: 15px;
      text-align: justify;
    }

    p {
      font-size: 15px;
      color: #777;
      line-height: 24px;
      margin: 0;
      text-align: justify;
    }
  }

  &.rejected-reason-modal,
  &.allocate-project-modal {
    .modal-body {
      padding: 3rem 2rem;

      h4 {
        color: #4fa399;
        font-size: 12px;
        font-weight: 500;
        padding: 0 2rem;
        position: relative;
        top: -15px;

        &.unavailable-from {
          top: 0;
          padding: 0;
          margin: 4px 0;
        }
      }

      .costType {
        >div {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .radio {
            margin-right: 15px;

            label {
              padding-left: 8px;
              color: #777;
              margin: 0 0 6px;

              &::before {
                margin-left: -14px;
              }

              &::after {
                margin-left: -14px;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .rateField {
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            background-color: transparent;
            box-shadow: none;
            color: #555;
            font-size: 14px;
            font-weight: 400;
            height: 40px !important;
            border-color: #ccc;
            margin: 0 4px;
            max-width: 115px;
          }

          em {
            font-size: 14px;
            color: #555;
            font-weight: 400;
            font-style: normal;

            strong {
              color: #333;
              font-weight: 500;
            }
          }
        }
      }

      .purpose-info-block {
        position: absolute;
        right: 22px;
        top: 45px;

        .purpose-info-list-main {
          position: absolute;
          right: -7px;
          top: 17px;
          z-index: 99;
          display: none;

          .purpose-info-list {
            padding: 12px;
            @include border-radius(0 4px 4px 0);
            border: 1px solid #ced4da;
            background: #fff;
            width: 432px;
            color: #e9645d;
            font-size: 14px;
            font-weight: 400;
            margin-top: 10px;

            .arrow-up {
              width: 13px;
              height: 8px;
              background: url('../../assets/images/up-arrow-b.png') center center no-repeat;
              background-size: 100%;
              position: absolute;
              right: 6px;
              top: 4px;
              margin-left: -7px;
              left: auto;
            }

            p {
              color: #555;
              margin-bottom: 10px;
            }

            ul {
              margin: 0;

              li {
                color: #777;
                font-size: 13px;
                list-style-type: disc;
                list-style-position: inside;
                line-height: 23px;
              }
            }
          }
        }

        .purpose-info {
          color: #999;
          font-size: 16px;
        }

        &:hover .purpose-info-list-main {
          display: block;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 560px;
  }

  .modal-content {
    border: none;
    @include border-radius(0);
  }

  h2 {
    padding: 0 2rem;
    font-size: 24px;
    color: #000;
    font-weight: 400;
    margin-bottom: 20px;

    @media only screen and (max-width: 767.98px) {
      padding: 0;
    }

    &.noPadding {
      padding: 0;
    }
  }

  h3 {
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
  }

  .modal-body {
    padding: 2rem;
    max-height: 650px;
    overflow: auto;
    overflow-x: hidden;
  }

  .form-group {
    padding: 0 2rem;
    position: relative;

    @media only screen and (max-width: 767.98px) {
      padding: 0;
    }

    .infoMsg {
      color: #999;
      position: absolute;
      right: 30px;
      font-size: 13px;
      top: 80px;
      line-height: 15px;
      font-style: italic;

      @media only screen and (max-width: 575.98px) {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    span.input-group-addon.flagInput {
      border: 1px solid #ccc;
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      button.dropbtn:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .form-group.btn-block {
    padding: 15px 0 0;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: #333;
    font-weight: 400;
    margin-bottom: 2px;

    span {
      color: #f00;
    }

    small {
      font-size: 12px;
      color: #999;
      font-weight: 500;
      margin-left: 3px;
    }
  }

  input.form-control,
  textarea.form-control {
    font-size: 14px;
    color: #555;
    font-weight: 400;
    line-height: 1.9;
    box-shadow: none;
    border-color: #ccc;
  }

  textarea.form-control {
    resize: none;
  }

  select.form-control {
    -webkit-appearance: none;
    background: transparent;
    box-shadow: none;
    border-color: #ccc;
    font-size: 14px;
    color: #555;
    font-weight: 400;
    line-height: 1.4;
  }

  button.edit-zoom-meeting {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    outline: none;
    background: #e9645d;
    border: none;
    @include border-radius(5px);
    padding: 4px 20px;
    position: absolute;
    top: -7px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &.feature-nodal {
    .modal-dialog {
      max-width: 590px;

      button {
        outline: none;
        font-size: 28px;
      }

      .with-upload-editor {
        .btn {
          font-size: 12px;
        }
      }

      h2 {
        padding: 0;
        margin-bottom: 30px;
      }

      ul.feature-tags {
        margin: 0 -5px;

        li {
          @include border-radius(2px);
          background-color: #f6f6f6;
          border: solid 1px #e2e2e2;
          color: #959595;
          font-size: 14.5px;
          font-weight: 500;
          margin: 0 5px 10px;
          text-align: center;
          display: inline-block;
          min-width: 87px;
          padding: 6px 10px;
        }
      }
    }
  }

  &.viewFeatures-modal {
    .modal-dialog {
      h2 {
        padding: 0;
        margin-bottom: 12px;
      }

      h3 {
        font-size: 16px;
        color: #777;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }
  }

  &.addQuickTipsPopup,
  &.editQuickTipsPopup {
    .attach-file-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;

      .attached-file {
        display: flex;
        justify-content: flex-start;
        margin: 0 15px 0 0;
        align-items: center;
        flex-wrap: wrap;

        .attach-file-info {
          font-size: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 15px;
          margin-bottom: 5px;
          min-width: 160px;

          .doc-name {
            width: auto;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          i,
          fa-icon {
            color: #333;
            margin-right: 6px;

            &.red {
              cursor: pointer;
              color: #e9645d;
              margin-left: 8px;
              margin-right: 0;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
  }

  &.basic-info-modal {
    .modal-dialog {
      max-width: 550px;

      .modal-body {
        overflow: initial;

        button.close {
          background: transparent;
          border: none;
          margin: 0;
          // margin-top: 0;
          position: absolute;
          top: 10px;
          right: 5px;
          z-index: 9;
          line-height: 30px;
          text-shadow: none;
          opacity: 1;

          span {
            font-size: 45px;
            color: #4fa399;
          }
        }

        .top-icon {
          width: 70px;
          height: 70px;
          @include border-radius(50%);
          display: flex;
          justify-content: center;
          background: #4fa399;
          align-items: center;
          position: absolute;
          left: 50%;
          top: -35px;
          margin-left: -35px;

          img {
            width: 40px;
          }

          &.comp {
            img {
              width: 27px;
            }
          }
        }

        h3 {
          font-size: 20px;
          color: #000;
          font-weight: 400;
          margin: 20px 0;
          width: 100%;
          text-align: center;

          @media only screen and (max-width: 575.98px) {
            font-size: 16px;
          }
        }

        ul.tempalte-list {
          max-height: 440px;
          overflow: auto;
          margin: 0;
          padding-right: 20px;

          li {
            border-top: solid 1px #ccc;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            @media only screen and (max-width: 575.98px) {
              padding: 12px 0;
            }

            .feature-name-wrp {
              position: relative;
              display: inline-block;

              img {
                width: 18px;
                height: 16px;
                position: absolute;
                top: 0;
                margin-left: 2px;
              }
            }

            .platefomIcon {
              width: 80px;
              padding-right: 16px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
              }
            }

            span {
              font-size: 16px;
              color: #777;
              font-weight: 400;

              @media only screen and (max-width: 575.98px) {
                font-size: 14px;
              }

              &.cstm-cancel {
                color: #e9645d;
                font-size: 12px;
              }

              &.phase-feature-title {
                width: calc(100% - 100px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .attach-file {
              color: #4fa399;

              span {
                font-size: 12px;
                color: #4fa399;
              }
            }

            &:first-child {
              border: none;
            }
          }
        }
      }
    }
  }

  &.project-new-change-modal {
    .modal-dialog {
      max-width: 477px;

      .modal-body {
        padding-top: 0;

        p {
          font-size: 12px;
          color: #333;
          margin-bottom: 25px;

          cite {
            font-style: normal;
          }

          &.toggle_feature_wrp {
            margin-bottom: 10px;
          }

          img {
            max-width: 20px;
            vertical-align: middle;
            margin-right: 11px;
            display: inline-block;
          }

          span {
            font-weight: 700;
            color: #333;

            &.toggle_feature {
              color: #4fa399;
              font-weight: 500;
              padding-left: 8px;
              cursor: pointer;

              .toggle_text {
                border-bottom: 1px solid;
                color: #4fa399;
                font-weight: 500;
                margin: 0 6px;
              }
            }
          }
        }

        ul.change_feature_request {
          padding-left: 31px;
          overflow-y: auto;
          max-height: 323px;

          li {
            font-size: 12px;
            line-height: 1.5;
            margin-bottom: 6px;
            color: #777777;
          }
        }

        button.close {
          position: absolute;
          top: 20px;
          right: 20px;
        }

        .delete-task-block {
          h2 {
            padding-top: 60px;
            font-weight: 400 !important;
          }
        }

        button {
          &.close {
            font-size: 28px;
          }

          font-size: 14px;
        }
      }
    }
  }

  &.denystate {
    .modal-dialog {
      .modal-body {
        .delete-task-block {
          h2 {
            padding-left: 2rem;
            margin-bottom: 27px;
          }
        }
      }
    }

    textarea.form-control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #cccccc;
      padding: 0;
    }
  }

  &.select-member-modal {
    .modal-dialog {
      max-width: 650px;

      .member-main-block {
        h2 {
          padding: 0;
        }

        p {
          font-size: 14px;
          color: #777;

          em {
            color: #d6d5d5;
          }
        }
      }

      button {
        @include border-radius(4px);
        font-size: 14px;
        box-shadow: none;
        border: solid 1px #e9645d;
        min-width: 85px;
        margin-left: 10px;
        background-color: transparent;
        color: #e9645d;
        outline: none;
      }
    }
  }

  &.completeDetailPopup {
    .modal-dialog {
      max-width: 750px;

      h2 {
        padding: 0;
      }

      ul.reason-list {
        li {
          color: #555;
          font-size: 15px;
          font-weight: 500;
          list-style-type: disc;
          list-style-position: inside;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.projectnewtimeline {
    .modal-dialog {
      max-width: 600px;

      .modal-body {
        padding: 0;

        .timeline-heading {
          padding: 13px;
          display: flex;
          justify-content: space-between;

          h3 {
            margin-bottom: 0;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.select-box {
  background: {
    image: url("../../assets/images/down-arrow.png");
    repeat: no-repeat;
    position: 97% center;
  }

  &.half {
    background: {
      position: 92% center;
    }
  }

  &.assignedPodClient {
    background: {
      position: 10% center;
    }
  }

  select {
    -webkit-appearance: none;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    height: 40px !important;

    &:focus {
      border-color: #ccc;
    }
  }
}

.btn-block {
  .btn {
    @include border-radius(4px);
    background-color: #e9645d;
    font-size: 14px;
    color: #fff;
    box-shadow: none;
    border: solid 1px #e9645d;
    min-width: 105px;
    margin-left: 10px;
  }

  .btn.cancel-btn {
    background-color: transparent;
    color: #e9645d;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
  z-index: 949;
}

button.add-task-top-btn {
  position: absolute;
  right: 10px;
  top: 30px;
  color: #e9645d;
  font-size: 14px;
  font-weight: 400;
  z-index: 1;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;

  i {
    margin-right: 5px;
  }
}

.right-content.task-main {
  padding-bottom: 0;
}

.todos .tab-content {
  height: calc(100vh - 172px);
}

.todos .nav-tabs {
  margin-bottom: 0;
}

.todos .tab-content .tab-pane {
  height: 100%;
}

.add-task-block {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: #fff;

  .add-task-con {
    width: 350px;
    text-align: center;
    position: relative;
    top: -20px;

    img {
      width: 70px;
    }

    p {
      width: 100%;
      margin: 1.5rem 0;
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }

    .add-task-btn {
      background: #e9645d;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 5px 20px;
      min-width: 100px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.add-task-block.hide {
  display: none;
}

.due-date-calender {
  color: #e9645d;
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #e9645d;
  padding: 5px 10px;
  @include border-radius(15px);
  margin-left: 13px;
  cursor: pointer;
  position: relative;
}

.attach-file {
  position: relative;
  display: inline-block;
  color: #e9645d;
  font-size: 13px;
  font-weight: 400;
  margin-left: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  input[type='file'] {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 140px;
    opacity: 0;
    cursor: pointer;
  }

  span {
    margin-left: 6px;
  }

  i.remove-attchment {
    position: relative;
    z-index: 99;
    margin-left: 8px;
  }
}

.todosQueries {
  height: 100%;

  .todos-left,
  .todos-right {
    width: 57%;
    height: 100%;
    float: left;
    border-left: solid 1px #e2e2e2;
    border-right: solid 1px #e2e2e2;
    position: relative;
  }

  .todos-left {
    margin-right: -1px;
    width: calc(43% + 1px);
  }

  .todos-right {
    .task-detail-row {
      textarea {
        resize: vertical;
        border: 1px solid #dfe0e6;
        height: 60px;

        &:focus {
          border-color: $revamp-theme-color;
        }
      }
    }
  }
}

.todos-header {
  width: 100%;
  height: 41px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .todos-right-header-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .invalid-feedback {
      position: absolute;
      left: 30%;
      display: initial;
      top: 8px;
      width: auto;
      overflow: hidden;
      max-width: 300px;
      text-overflow: ellipsis;
    }
  }
}

.todos-right {
  .todos-header {
    padding: 0 20px;
  }

  .todos-inner {
    height: calc(100% - 172px);
  }
}

.todos-header .projects-search-filter {
  float: left;
  width: 100%;
  margin: 0;
  justify-content: space-between;
}

.todos-inner {
  height: calc(100% - 45px);
  position: absolute;
  left: 0;
  top: 41px;
  width: 100%;
  overflow: auto;
  margin: 0;

  .no-results-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 45px);
    position: absolute;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 1;

    img {
      width: 53px;
      margin-bottom: 25px;
    }

    p {
      font-size: 16px;
      color: #333;
      margin-bottom: 0;
    }
  }
}

ul.tasks-queries-list {
  li {
    min-height: 60px;
    height: auto;
    background: #fff;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    position: relative;
    border-left: solid 3px transparent;

    &.rejected {

      // background:rgba(255, 0, 20, 0.11);
      &:before {
        content: "\f024";
        font-family: "Font Awesome 5 Free", sans-serif;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background: #d50000;
        color: #fff;
        font-size: 8px;
        text-align: center;
        line-height: 14px;
        position: absolute;
        margin-top: 0px;
        left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }

      &.active,
      &:hover {
        border-left: solid 3px #ff0014;
        background: rgba(255, 0, 20, 0.11);
      }
    }

    &.accepted {
      @extend .rejected;

      // background:#edf6f5;
      &:before {
        content: "\f024";
        background: #00c853;
        font-size: 7px;
      }

      &.active,
      &:hover {
        border-left: solid 3px #4fa399;
        background: #edf6f5;
      }
    }

    &.auto_accepted {
      @extend .rejected;
      // background:#edf6f5;

      &:before {
        content: '\f024' + ' Auto';
        background: #00C853;
        font-size: 10px;
        width: 40px !important;
        border-radius: 10% !important;
        left: 2px !important;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        height: 18px;
      }

      &.active,
      &:hover {
        border-left: solid 3px #4fa399;
        background: #edf6f5;
      }
    }

    &.is-unread {
      background-color: rgb(241, 246, 253);

      &:before {
        content: "";
        position: absolute;
        left: 0.5rem;
        top: 3.5rem;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: rgb(54, 127, 238);
      }
    }

    &:after {
      width: 95%;
      height: 1px;
      background: #ccc;
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    &:hover {
      background: rgba(86, 136, 161, 0.15);
      border-left: solid 3px #5688a1;
    }

    &.active {
      background: rgba(86, 136, 161, 0.15);
      border-left: solid 3px #5688a1;
    }

    p {
      font-size: 12px;
      color: #333;
      font-weight: 400;
      margin: 0;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      strong {
        font-weight: 400;
        color: #777;
      }
    }

    .navigate-right-arr {
      position: absolute;
      color: #ccc;
      font-size: 12px;
      right: 10px;
      top: 50%;
      margin-top: -5px;
    }

    .created-due-date {
      p {
        text-align: right;
      }
    }

    .tasks-queries-list-inner {
      width: 100%;

      p {
        width: 100%;
      }
    }
  }
}

// TODO : buttons will be shown on li:hover
.delay-button-holder {
  display: flex;
}

.delay-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .delay-btn {
    font-family: $revamp-font;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    background: #6200ea;
    border-radius: 2px;
    padding: 5px 8px;
    margin-left: 10px;
    border: 0;
  }
}

.check-circle {
  border: solid 1px #ccc;
  color: #ccc;
  @include border-radius(50%);
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 10px;

  &.active {
    border-color: #4fa399;
    color: #fff;
    background: #4fa399;
  }
}

.check-circle.small-check {
  width: 18px;
  height: 18px;

  i {
    font-size: 12px;
    width: 18px;
    text-align: center;
  }
}

.check-circle.large-check {
  width: 28px;
  height: 28px;

  i {
    font-size: 16px;
  }
}

.large-check {
  &.check-query {
    i {
      color: #ccc;
      font-size: 32px;
      margin-right: 10px;
    }
  }
}

.ques {
  margin-right: 10px;

  &.active {
    i {
      color: #4fa399 !important;
    }
  }
}

.ques.small-ques {
  i {
    font-size: 21px;
    color: #ccc;
  }
}

.ques.large-ques {
  i {
    font-size: 32px;
  }
}

.task-name-assigned {
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 120px);

  i {
    float: left;
    margin-right: 6px;
    font-size: 13px;
    margin-top: 2px;
    color: #ccc;

    &.red {
      color: #e9645d;
    }

    &.yellow {
      color: #ffdb58;
    }

    &.green {
      color: #4fa399;
    }
  }
}

.assignedByName {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
  font-weight: 400;
  position: relative;

  .initial-circle {
    width: 24px;
    height: 24px;
    @include border-radius(50%);
    background: #4fa399;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  p.assignedBy-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    margin: 0;
  }
}

.task-due {
  label {
    margin: 0 !important;
    font-size: 12px;
    color: #333;
    font-weight: 400;
  }

  .due-date-calender {
    margin-left: 7px;

    i {
      margin-right: 5px;
    }
  }
}

.timeline {
  width: 28px;
  height: 28px;
  border: solid 1px transparent;
  @include border-radius(50%);
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #e9645d;
    font-size: 20px;
  }
}

.task-detail {
  padding: 10px 20px;

  .task-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    h4 {
      margin: 0;
      color: #333;
      font-size: 18px;
      font-weight: 400;
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.del-task {
  font-size: 14px;
  color: #e9645d;
  font-weight: 400;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}

.save-task {
  font-size: 14px;
  color: #e9645d;
  font-weight: 400;
  cursor: pointer;
  margin-right: 1rem;

  &:last-child {
    margin: 0;
  }

  i,
  fa-icon {
    margin-right: 5px;
  }

  &.hide {
    visibility: hidden;
  }
}

.task-detail-row {
  margin-bottom: 1.3rem;
  align-items: center;

  .task-heading-row {
    display: flex;
    justify-content: space-between;

    label {
      margin-right: 0;
    }
  }

  .custom-radio {
    align-items: center;
    display: flex;
    padding-left: 2px;

    label {
      margin: 0 30px 0 0;
    }

    .radio-checkmark {
      margin-right: 5px;
    }
  }

  label {
    font-size: 12px;
    color: #333;
    margin: 0 14px 0 0;
    font-weight: 500;

    &.inc-border {
      border-bottom: solid 1px #ccc;
      width: 100%;
      padding-bottom: 8px;
    }
  }

  .pro-status {
    .status-option {

      i,
      fa-icon {
        font-size: 14px;
        color: #ccc;
        margin-right: 5px;

        &.red {
          color: #e9645d;
        }

        &.yellow {
          color: #ffdb58;
        }

        &.green {
          color: #4fa399;
        }
      }

      select {
        -webkit-appearance: none;
        outline: none;
        border: none;
        font-size: 12px;
        color: #777;
        min-width: 95px;
        padding: 0 10px;

        background: {
          image: url("../../assets/images/down-arrow-dark.png");
          color: transparent;
          repeat: no-repeat;
          position: right center;
          size: 10px auto;
        }
      }
    }
  }

  .created-date {
    p {
      font-size: 12px;
      color: #333;
      font-weight: 400;
      margin: 0;

      span {
        color: #777;
      }
    }
  }

  textarea {
    resize: none;
    font-size: 14px;
    color: #333;
    border: solid 1px #ccc;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
      border-color: #ccc;
    }
  }

  .attach-file-info {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      color: #777;
      margin-right: 6px;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    fa-icon {
      color: #333;
      margin-right: 6px;

      &.red {
        cursor: pointer;
        color: #e9645d;
      }
    }
  }
}

ul.Comments-list {
  margin: 12px 0;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    flex-wrap: wrap;

    .comm-detail {
      position: relative;
      width: calc(100% - 35px);

      h4 {
        font-size: 12px;
        color: #333;
        font-weight: 600;
        margin: 5px 0;
      }

      p {
        font-size: 12px;
        color: #777;
        margin: 6px 0 2px;
        font-family: "MyriadPro-Regular", sans-serif;
        font-weight: normal;
        line-height: 18px;
        padding-right: 10px;
        word-wrap: break-word;
      }

      .date-time {
        font-size: 11px;
        color: #888;
        position: absolute;
        top: 2px;
        right: 0;

        i {
          color: #333;
          font-size: 14px;
          padding: 0 6px;
          cursor: pointer;
        }

        .edit-del-dropdown {
          border: none;
          background: none;
          outline: none;
        }

        .dropdown-menu {
          right: 0 !important;
          left: auto !important;
          transform: translate3d(0, 20px, 0px) !important;
          box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.25) !important;
          border: none;
          width: 100px;
          min-width: 100px;
          padding: 5px 0;

          &:after {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
            content: "";
            position: absolute;
            top: -5px;
            right: 4px;
          }

          a {
            color: #e9645d;
            font-size: 14px;
            padding: 3px 12px;
            background: transparent !important;

            i {
              color: #e9645d;
              font-size: 13px;
              padding: 0 4px 0 0;
              cursor: pointer;
            }
          }
        }
      }

      .comment-attached-file {
        display: flex;
        justify-content: flex-start;
        margin: 6px 15px 0 0;

        .attach-file-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          #doc-name {
            width: auto;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .initial {
      width: 24px;
      height: 24px;
      background: #5674b9;
      @include border-radius(50%);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.commentArea {
  width: 100%;
  height: 127px;
  background: #f5f5f5;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: solid 1px #ccc;
  padding: 12px 20px;

  .commentTextArea {
    background: #fff;
    @include border-radius(4px);
    border: solid 1px #ccc;
    height: 104px;
    padding: 10px;
    position: relative;

    textarea {
      resize: none;
      border: none;
      font-size: 14px;
      color: #777;
      outline: none;
      padding: 0;
      height: 55px;

      &:focus {
        box-shadow: none;
      }
    }

    .body-required {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      left: 12px;
      top: 35px;
    }

    .commentAreaBtn {
      .attached-file-edit {
        .attach-file-info {
          font-size: 12px;
          margin-right: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            color: #777;
            margin-right: 6px;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          fa-icon {
            color: #333;
            margin-right: 6px;

            &.red {
              cursor: pointer;
              color: #e9645d;
            }
          }
        }
      }

      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;

      .attach-file {
        margin: 4px 15px 0 0;

        i {
          font-size: 18px;
        }

        input {
          max-width: 20px;
          width: 20px;
          overflow: hidden;
        }
      }

      button {
        width: 28px;
        height: 28px;
        @include border-radius(50%);
        background: #e9645d;
        border: none;
        outline: none;

        img {
          width: 17px;
        }
      }
    }
  }
}

.tasks-queries-list.hide {
  display: none;
}

.task-detail.hide {
  display: none;
}

.commentArea.hide {
  display: none;
}

.todos-right-header-inner.hide {
  display: none;
}

.no-results-found.hide {
  display: none;
}

.project-success-msg {
  position: fixed;
  width: 100%;
  max-width: 70%;
  @include border-radius(5px);
  padding: 12px 30px;
  top: 58px;
  left: 15%;
  z-index: 99999;
  background: rgba(17, 17, 17, 0.8);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin: 0;
    padding: 0 20px;
  }

  img {
    width: 22px;
  }

  a {
    font-size: 16px;
    color: #fff;

    &:not([href]):not([tabindex]) {
      color: #fff;
    }
  }
}

.timeline-modal {
  .timeline-main-block {
    padding: 0 3rem;

    .timeline-block {
      max-height: 350px;

      background: {
        image: url("../../assets/images/timeline-shape.png");
        repeat: repeat-y;
        position: 8px top;
      }

      overflow-y: auto;
      margin-bottom: 30px;

      >ul {
        margin: 0;
        padding: 30px 0;

        >li {
          font-size: 12px;
          color: #777;
          margin-bottom: 30px;

          background: {
            image: url("../../assets/images/disc.png");
            repeat: no-repeat;
            position: 2px center;
          }

          padding-left: 25px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &.date-placed {
            background: {
              image: url("../../assets/images/circle.png");
              position: 4px center;
            }

            span {
              color: #4fa399;
              font-size: 12px;
              border: solid 1px #4fa399;
              padding: 2px 10px;
              @include border-radius(20px);
              display: inline-block;
            }
          }

          &.no-bullet {
            background: none;
            display: inherit;

            p {
              span {
                color: #4fa399;
                font-weight: 600;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    >h2 {
      padding: 0;
    }

    >h3 {
      font-size: 14px;
      color: #333;
      font-weight: 500;
      margin-bottom: 1.5rem;
      line-height: 1.4;
      word-break: break-all;
    }
  }

  .cancel-btn {
    @include border-radius(4px);
    font-size: 14px;
    box-shadow: none;
    border: solid 1px #e9645d;
    min-width: 85px;
    margin-left: 10px;
    background-color: transparent;
    color: #e9645d;
  }
}

.new-project-block {
  position: absolute;
  left: -4px;
  top: 0;
  max-width: 25%;
  z-index: 999;
}

.code-repo-block {
  height: calc(100vh - 199px);
  display: flex;
  justify-content: space-between;
  padding: 0 !important;

  #codeRepo {
    width: calc(100% - 10px);
    overflow-y: auto;
    padding: 0 9px;
    margin-left: -7px;
    height: 100%;

    .no-results-found {
      height: 100%;
    }
  }

  .codeRepoList {
    width: 100%;

    .no-results-found {
      height: 100%;
    }
  }

  .code-repo-timeline {
    box-shadow: 0 4px 5.9px 0.1px rgba(0, 0, 0, 0.5);
    width: 285px;
    margin-top: -20px;
    padding: 15px;
    height: calc(100% + 20px);
  }
}

.code-repo {
  .row {
    margin-left: -9px;
    margin-right: -9px;
  }

  .col-lg-12,
  .col-lg-4 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .codeRepo-card {
    border-top: solid 6px #62b26d;
    min-height: 220px;
    height: auto;

    h4.block-title {
      font-size: 17px;
      font-weight: 500;
    }

    .codeRepoCardDetail {
      padding: 15px;

      .codeRepoCardRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .codeRepoIcon {
          width: 55px;
          height: 55px;
          border: solid 1px #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          @include border-radius(50%);

          img {
            width: 27px;
          }
        }

        ul.codeRepoInfoList {
          margin: 0;

          li {
            font-size: 13px;
            color: #777;
            line-height: 20px;
            margin-bottom: 3px;

            @media (max-width: 1280px) {
              font-size: 12px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            span {
              min-width: 120px;
              float: left;
              text-align: right;
              margin-right: 15px;
            }

            strong {
              color: #333;
              font-weight: 500;
            }
          }
        }

        &.codeRepoGitUrl {
          margin-top: 30px;
          justify-content: center;

          a {
            font-size: 15px;
            color: #e9645d;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
            position: relative;
            z-index: 99;
          }
        }
      }
    }

    &:hover {
      &::before {
        background: rgba(98, 178, 109, 0.1);
        border: 2px solid #62b26d;
        border-top: none;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.code-repo-head-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e2e2e2;
  padding: 12px 0;
  margin-bottom: 15px;

  .viewBtn {
    background: #e9645d;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 6px 20px;
    min-width: 120px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    @include border-radius(5px);
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .codeRepoIcon {
      width: 45px;
      height: 45px;
      border: solid 1px #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius(50%);

      img {
        width: 25px;
      }
    }

    .titleUrlLink {
      margin-left: 15px;

      .copyLink {
        color: #e9645d;
        font-size: 15px;
        font-weight: 400;
        text-decoration: none;
        margin-left: 30px;

        em {
          margin-right: 4px;
        }
      }

      h4 {
        color: #333;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .right {
    ul.codeRepoInfoList {
      margin: 0;

      li {
        font-size: 14px;
        color: #777;
        line-height: 16px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          min-width: 120px;
          float: left;
          text-align: right;
          margin-right: 15px;
        }

        strong {
          color: #333;
          font-weight: 500;
        }
      }
    }
  }
}

.calenderView {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .calender {
    .date-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;

      label {
        font-size: 13px;
        color: #333;
      }

      .my-date-picker-new {
        width: 140px;
        margin-left: 12px;
      }
    }

    .invalid-feedback {
      margin: 0;
      text-align: right;
      padding-right: 5px;
    }
  }

  .viewBtn {
    background: #e9645d;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 6px 20px;
    text-align: center;
    min-width: 120px;
    display: inline-block;
    text-decoration: none;
    margin-left: 20px;
    @include border-radius(5px);
  }
}

.code-repo-detail-view {
  height: calc(100vh - 245px);

  .activity-feed {
    margin-top: 1rem;

    .activity-feed-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 14px;
        color: #333;
        font-weight: 500;
        border-bottom: solid 1px #e2e2e2;
        padding-bottom: 10px;
        margin-bottom: 15px;
        width: 100%;
      }

      select.feed-dropdown {
        -webkit-appearance: none;

        background: {
          image: url("../../assets/images/red-arrow.png");
          position: right center;
          color: transparent;
          repeat: no-repeat;
        }

        color: #515974;
        font-size: 12px;
        border: none;
        padding-right: 18px;
        outline: none;
      }
    }

    .code-repo-graph {
      padding: 30px 0;

      img {
        max-width: 100%;
      }
    }
  }

  .code-repo-timeline {
    margin-top: 1rem;
    border-left: 1px solid #e2e2e2;
    padding-left: 30px;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 1rem);
    width: 100%;

    .timeline-block {
      background: {
        image: url("../../assets/images/timeline-shape.png");
        repeat: repeat-y;
        position: 8px top;
      }

      overflow-y: auto;
      height: calc(100% - 45px);
      padding-right: 10px;
      position: absolute;
      top: 45px;
      left: 45px;
      width: calc(100% - 45px);

      ul {
        margin: 0;
        padding: 30px 0;

        li {
          font-size: 14px;
          color: #333;
          margin-bottom: 30px;

          background: {
            image: url("../../assets/images/green-disc.png");
            repeat: no-repeat;
            position: 2px 2px;
          }

          span {
            display: block;
            color: #777;
            font-size: 12px;

            &.description {
              padding: 4px 0;
              line-height: 16px;
            }

            &.time {
              color: #999;
              font-size: 11px;
            }
          }

          padding-left: 25px;

          &.date-placed {
            background: {
              image: url("../../assets/images/circle.png");
              position: 4px center;
              repeat: no-repeat;
            }

            span {
              color: #4fa399;
              font-size: 12px;
              border: solid 1px #4fa399;
              padding: 2px 10px;
              display: inline-block;
              @include border-radius(20px);
            }
          }

          // &.ios {
          //   background: {
          //     image: url('../../assets/images/blue-disc.png');
          //     repeat: no-repeat;
          //     position: 2px 2px;
          //   }
          // }
          // &.android {
          //   background: {
          //     image: url('../../assets/images/red-disc.png');
          //     repeat: no-repeat;
          //     position: 2px 2px;
          //   }
          // }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    h4 {
      font-size: 14px;
      color: #333;
      font-weight: 500;
      border-bottom: solid 1px #e2e2e2;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
  }
}

.no-data-found {
  min-height: 400px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .no-data-block {
    text-align: center;

    img {
      width: 55px;
      margin-bottom: 12px;
    }

    p {
      font-size: 15px;
      color: #333;
      font-weight: 500;
    }
  }
}

.build-tab .tab-content {
  height: calc(100vh - 179px);

  &.epicPhaseTabCon {
    height: auto;
  }
}

.build-tab .nav-tabs {
  margin-bottom: 0;
}

.build-tab .tab-content .tab-pane {
  height: 100%;
}

.no-shipped-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .no-shipped-con {
    width: 350px;
    text-align: center;
    position: relative;
    top: -20px;

    img {
      width: 95px;
    }

    p {
      width: 100%;
      margin: 2.5rem 0 1.5rem;
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }
  }
}

.no-shipped-block.hide {
  display: none;
}

#createBuildPopup {
  .date-placed {
    position: relative;

    .date-icon {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-left: solid 1px #ccc;
      cursor: pointer;
    }
  }
}

.shippedDraftCon {

  .todos-left,
  .todos-right {
    width: 60%;
  }

  .todos-left {
    margin-right: -1px;
    width: calc(40% + 1px);

    ul.tasks-queries-list li {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: auto;
      align-items: center;

      >p {
        margin-top: 6px;
        font-size: 11px;
        white-space: normal;
      }

      .task-name-assigned {
        float: left;

        span:first-child {
          font-size: 14px;
        }
      }

      .created-due-date {
        float: right;
        padding: 0;
      }
    }
  }

  .todos-right {
    .assignedByName {
      font-size: 18px;
      max-width: calc(100% - 120px);
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.edit-fix {
        h4 {
          max-width: 245px;
        }
      }

      &.fullwidth {
        width: 100%;
        max-width: 100%;
      }
    }

    .todos-inner {
      height: calc(100% - 45px);

      .task-detail-row {
        >p {
          font-size: 12px;
          color: #333;
          margin-bottom: 10px;

          span {
            color: #777;
            margin-left: 5px;
          }
        }

        .trans-box {
          border: solid 1px #ccc;
          background: transparent;
          margin-top: 12px;
          @include border-radius(5px);
          padding: 0 10px;
          min-height: 40px;
          line-height: 1.6;

          &.read-only-bg {
            background-color: #e9ecef;

            input {
              background-color: transparent;
            }
          }

          p {
            margin: 0;
            line-height: 40px;
            font-size: 14px;
            color: #555;
          }

          input.live-link {
            border: none;
            width: 100%;
            outline: none;
            line-height: 40px;
            font-size: 14px;
            color: #555;
          }
        }

        .feedbackHead {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 12px;
            color: #777;
            margin: 0;
          }
        }
      }

      .no-file-uploaded {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;
          padding: 0 40px;
          text-align: center;
        }
      }
    }

    .attach-file {
      overflow: hidden;
      margin-left: 0;
      margin-top: 3px;
    }

    .del-draft {
      color: #e9645d;
      font-size: 14px;
      cursor: pointer;

      i {
        margin-right: 6px;
      }
    }
  }
}

.manager-block {
  margin-top: 9px;

  .projects-search-filter {
    right: 0;
    position: absolute;
    top: -9px;
    margin-bottom: 15px;
    z-index: 9;
  }

  .manager-card {
    border-top: solid 6px #62b26d;
    position: relative;

    .project-card-bottom-list {
      table {
        td {
          strong {
            min-width: 40px;
          }
        }
      }
    }

    .projectCardHeader {
      .projectCardHeaderLeft {
        h4.block-title {
          max-width: 85%;

          &.fullWidth {
            max-width: 100%;
          }
        }
      }

      .projectCardHeaderRight {
        bottom: 18px;

        p.edit-client {
          color: #e86c65;
          font-size: 13px;
          font-weight: 400;
          cursor: pointer;
          margin: 0;
        }
      }
    }

    &:hover {
      &::before {
        background: rgba(98, 178, 109, 0.1);
        border: solid 2px #62b26d;
        border-top: none;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &.create-new-project {
      border: dashed 1px #555 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: none;
      height: 270px;

      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }

  .create-new-project {
    border: dashed 1px #555;
  }

  ul.project-card-bottom-list li strong {
    font-weight: 400;

    span {
      font-size: 11px;
    }
  }

  .project-card-bottom-list {
    i {
      margin-right: 3px;
      font-size: 11px;
      margin-top: 2px;
      color: #ccc;

      &.yellow {
        color: #c99e12;
      }

      &.green {
        color: #4fa399;
      }

      &.red {
        color: #e9645d;
      }
    }
  }
}

.manager-detail-block {
  padding: 10px;

  h4.backToManager {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
    cursor: pointer;

    background: {
      image: url("../../assets/images/back-arrow.png");
      repeat: no-repeat;
      position: left center;
    }
  }

  .manager-detail-top-row {
    border-bottom: solid 2px #e2e2e2;
    padding-bottom: 8px;

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 14px;
        color: #333;
        padding-right: 15px;

        &:last-child {
          padding: 0;
          color: #777;
        }

        i {
          font-size: 10px;
          position: relative;
          top: -1px;
          margin-right: 3px;

          &.yellow {
            color: #c99e12;
          }

          &.green {
            color: #4fa399;
          }

          &.red {
            color: #e9645d;
          }
        }
      }
    }

    ul.edit-del-list {
      li {
        color: #e9645d;
        cursor: pointer;

        i {
          margin-right: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .manager-info {
    label {
      font-size: 12px;
      font-weight: 500;
      color: #777;
      display: block;

      cite {
        color: red;
        font-style: normal;
      }
    }

    span {
      height: 40px;
      font-size: 12px;
      color: #111;
      border: solid 1px #ccc;
      display: block;
      padding: 9px;
      @include border-radius(5px);
    }

    .col-lg-4 {
      .form-group {
        margin-bottom: 2rem;
      }
    }
  }

  .project-list-main {
    h4 {
      font-size: 14px;
      color: #333;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    .project-list {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #ccc;
        padding: 15px 0;

        p {
          width: 20%;
          flex-shrink: 0;
          font-size: 13px;
          color: #333;
          margin: 0;
          overflow: hidden;
          padding-right: 15px;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:nth-child(3) {
            text-transform: capitalize;
          }

          button {
            background: transparent;
            border: solid 1px #6cb5d8;
            @include border-radius(30px);
            font-size: 12px;
            padding: 2px 15px;
            min-width: 95px;
            color: #6cb5d8;
            outline: none;
            margin-right: 3px;

            &.archive-completed-btn {
              border: solid 1px #61ad31;
              color: #61ad31;
            }

            &.archive-blocked-btn {
              border: solid 1px #e9645d;
              color: #e9645d;
            }
          }
        }

        .progress {
          width: 20%;
          height: 12px;
          font-size: 10px;
          background: #ccc;
          @include border-radius(15px);

          .progress-bar {
            background: #4fa399;
          }
        }

        &:first-child {
          p {
            color: #9e9e9e;
          }
        }
      }
    }
  }
}

#deleteManagerModal {
  h2 {
    padding: 0;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: #333;
  }

  ul.archive-list {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #ccc;
      padding: 12px 10px;
      cursor: pointer;

      p {
        font-size: 15px;
        color: #333;
        font-weight: 400;
        margin: 0;

        &:last-child {
          min-width: 95px;
        }
      }

      button {
        background: transparent;
        border: solid 1px #6cb5d8;
        @include border-radius(30px);
        font-size: 12px;
        padding: 2px 15px;
        min-width: 95px;
        color: #6cb5d8;
        outline: none;
        cursor: pointer;

        &.archive-completed-btn {
          border: solid 1px #61ad31;
          color: #61ad31;
        }

        &.archive-blocked-btn {
          border: solid 1px #e9645d;
          color: #e9645d;
        }
      }

      &:first-child p {
        color: #9e9e9e;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.projects-detail {

  // margin-top: 10px;
  h4 {
    font-size: 13px;
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: $heading-font;
  }

  .sep {
    width: 100%;
    height: 2px;
    background: #e2e2e2;
  }

  .tab-content {
    padding: 0;
  }

  .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .select-box {
    select {
      -webkit-appearance: none;
      background-color: transparent;
      box-shadow: none;
      color: #555;
      font-size: 14px;
      font-weight: 400;
      height: 40px !important;
      border-color: #ccc;

      &:focus {
        border-color: #ccc;
      }
    }
  }

  input[type='text'],
  input[type='password'] {
    background-color: transparent;
    box-shadow: none;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    height: 40px;

    &:focus {
      border-color: #ccc;
    }
  }

  .search-manager-block {
    position: relative;

    .added-mem-count {
      font-size: 12px;
      font-weight: 500;
      color: #4fa399;
      position: absolute;
      right: 0;
      top: 3px;
    }

    .infoMsg {
      color: #999;
      position: absolute;
      right: 0;
      font-size: 13px;
      top: 72px;
      line-height: 15px;
      font-style: italic;
    }

    .memberList {
      margin-top: 5px;

      .viewAddedMem {
        color: #e9645d;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
      }

      ul.memberListing {
        margin: 12px 0 6px;

        li {
          background-color: #4fa399;
          color: #fefefe;
          margin-bottom: 8px;
          font-size: 13px;
          padding: 8px;
          font-weight: 400;
          margin-right: 12px;
          @include border-radius(4px);
          display: inline-flex;
          align-items: center;

          i {
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
          }

          em {
            color: #ffbb1d;
            margin-right: 5px;
            font-size: 14px;

            &.purple {
              color: #800080;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .closeList {
        color: #e9645d;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .mutiple-assign {
    position: relative;

    .mutiple-select {
      height: 40px;
      border: solid 1px #ccc;
      color: #555;
      font-size: 14px;
      line-height: 39px;
      display: block;
      padding: 0 0.75rem;
      font-weight: 400;
      padding-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      background: {
        image: url("../../assets/images/down-arrow.png");
        repeat: no-repeat;
        position: 97% center;
      }

      @include border-radius(4px);
    }

    .mutiple-assign-box {
      @include border-radius(4px);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      border: solid 1px #cccccc;
      position: absolute;
      width: 100%;
      z-index: 999;
      top: 39px;
      max-height: 345px;
      overflow: auto;

      ul.mutiple-assign-list {
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 7px 1rem;

          .checkmark {
            left: 15px;
            top: 11px;
          }

          &:hover {
            background-color: #f4f4f6;
          }

          &.selected {
            background-color: #f4f4f6;
          }

          i,
          fa-icon {
            color: #ccc;

            &.golden {
              color: #feb045;
            }
          }
        }
      }
    }
  }

  .form-group {
    position: relative;

    .installmentContentTooltip {
      width: auto;
      min-width: 415px;
      min-height: 75px;
      height: auto;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #ccc;
      background-color: #fff;
      position: absolute;
      right: -13px;
      top: 64px;
      padding: 0 20px;
      cursor: default;
      align-items: center;
      justify-content: center;
      @include border-radius(3px);
      z-index: 9;
      display: none;

      .triangle {
        width: 50px;
        height: 22px;
        position: absolute;
        overflow: hidden;
        top: -22px;
        right: 13px;
        box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
      }

      .triangle:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background: #fff;
        transform: rotate(45deg);
        top: 19px;
        left: 25px;
        border: solid 1px #ccc;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
      }

      p {
        font-size: 14px;
        color: #555;
        margin: 0;
        font-weight: 400;
        line-height: 1.6;
      }
    }

    .purposeTooltip {
      position: absolute;
      left: 106px;
      top: -3px;

      em {
        color: #555;
        font-size: 18px;
        cursor: pointer;
        z-index: 99;
        position: relative;
      }

      .installmentContentTooltip {
        min-width: 476px;
        top: 28px;
        right: auto;
        left: -21px;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;

        .triangle {
          right: auto;
          left: -10px;
        }

        .tooltipContent {
          ul {
            margin: 6px 0 0;

            li {
              font-size: 14px;
              color: #555;
              margin: 0;
              font-weight: 400;
              line-height: 1.6;

              strong {
                font-weight: 500;
              }
            }
          }
        }
      }

      &:hover .installmentContentTooltip {
        display: flex;
      }
    }

    &.kickOffDate {
      &:hover .installmentContentTooltip {
        display: flex;
      }

      &.enableError {
        &:hover {
          .kickoffDateError {
            display: block;
          }
        }
      }

      input.dateInput {
        border: none;
        background: #fff !important;
        position: absolute;
        z-index: 999;
        left: 4px;
        width: calc(100% - 40px);
        outline: none;
        padding: 0 7px;
        top: 29px;
        height: 30px !important;

        &.disabledInput {
          background: #f4f4f4 !important;
        }
      }
    }
  }

  .assign-btn {
    background: #e9645d;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: none;
    @include border-radius(4px);
    box-shadow: none;
    @include border-radius(4px);
    line-height: 30px;
    padding: 0 20px;
    margin-top: 5px;
    outline: none;
    cursor: pointer;
    min-width: 120px;

    &.disabled {
      background: {
        color: #ffffff;
      }
    }
  }

  .start-stop-installments {
    display: inline-block;
    position: relative;
    left: 10px;
    top: 4px;
    cursor: pointer;

    .installment-content {
      position: absolute;
      right: -4px;
      top: -29px;
      height: 42px;
      display: none;

      .installment-content-inner {
        background: #fff;
        border: solid 1px #ccc;
        @include border-radius(2px);
        padding: 6px 8px;
        width: 350px;

        p {
          font-size: 13px;
          color: #555;
          margin: 0;
          font-weight: 400;

          a {
            color: #d64d4d;
            text-decoration: underline;
            margin-left: 4px;
          }
        }

        .arrow-down {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #fff;
          position: absolute;
          right: 6px;
          bottom: 4px;
        }
      }
    }

    .installmentContentTooltip {
      width: auto;
      min-width: 385px;
      min-height: 50px;
      height: auto;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #ccc;
      background-color: #fff;
      position: absolute;
      right: -22px;
      top: 29px;
      cursor: default;
      align-items: center;
      justify-content: center;
      @include border-radius(3px);
      z-index: 9;
      display: none;

      .triangle {
        width: 50px;
        height: 22px;
        position: absolute;
        overflow: hidden;
        top: -22px;
        right: 13px;
        box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
      }

      .triangle:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background: #fff;
        transform: rotate(45deg);
        top: 19px;
        left: 25px;
        border: solid 1px #ccc;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
      }

      p {
        font-size: 14px;
        color: #555;
        margin: 0;
        font-weight: 400;
      }
    }

    em {
      color: #777;
      font-size: 18px;
      position: relative;
      top: -2px;
      z-index: 99;
    }

    &:hover .installmentContentTooltip {
      display: flex;
    }
  }

  .start-payment-msg {
    font-size: 13px;
    float: right;
    font-style: italic;
    color: #999;
    line-height: 16px;
    margin: 10px 0 0;
  }

  .managersSummaryList {
    margin-top: 20px;

    .card {
      background-color: transparent;
      border-radius: 4px !important;
      border: solid 1px #ccc !important;
      margin-bottom: 25px;

      .card-header {
        background: transparent;
        padding: 11px 12px;
        border-color: #ccc;
        position: relative;
        border-bottom: none;

        button {
          color: #333;
          font-size: 13px;
          font-weight: 500;
          width: 100%;
          text-align: left;
          padding-left: 0;
          text-decoration: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 200px);
        }

        .selected-members {
          color: #333;
          font-size: 13px;
          font-weight: 700;
          float: right;
          margin: 10px 35px 0 0;
        }
      }

      .collapse {
        &.show {
          padding: 12px;
          border-top: 1px solid rgba(0, 0, 0, 0.125);
        }
      }

      .card-body {
        padding-left: 0;

        label {
          font-size: 12px;
          font-weight: 500;
          color: #333;
        }
      }

      &.acc-arr-down {
        background: url("../../assets/images/arrow-down.png") 98% 26px no-repeat;
      }

      &.acc-arr-up {
        background: url("../../assets/images/arrow-up.png") 98% 26px no-repeat;
      }
    }

    .pagination-loader {
      float: left;
      width: 100%;
    }

    table,
    tbody,
    thead,
    tr {

      td,
      th {
        color: #333;
        font-size: 13px;
        font-weight: 400;
        padding: 11px 15px 11px 0;
        position: relative;
        line-height: 17px;
        vertical-align: middle;
        max-width: 200px;

        &:first-child {
          width: 6%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:last-child {
          width: 22%;
        }

        .custom-checkbox {
          .checkmark {
            top: 18px;
            left: 33px;
          }
        }

        ul {
          margin: 0;
          display: flex;
          justify-content: flex-start;

          li {
            color: #333;
            font-size: 13px;
            font-weight: 400;
            padding: 0 12px;
            border-right: solid 1px #ccc;

            img {
              width: 12px;
              margin-right: 5px;
            }

            span {
              position: relative;
              top: 1.5px;
            }

            i {
              font-size: 9px;
              position: relative;
              top: -2px;
              margin-right: 3px;

              &.blue {
                color: #6cb5d8;
              }

              &.green {
                color: #4fa399;
              }

              &.red {
                color: #e9645d;
              }
            }

            &:last-child {
              border-right: none;
              padding-right: 0;
            }

            &:first-child {
              padding-left: 0;
            }
          }
        }

        .td-inner {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          small {
            color: #9e9e9e;
            font-size: 10px;
          }
        }
      }

      th {
        color: #9e9e9e;
        border-bottom: solid 1px #ccc;
        position: relative;
        padding: 10px 0 20px;

        &.activeProjectsLabel {
          overflow: initial;

          .activeProjects {
            position: absolute;
            right: -20px;
            top: -30px;
            height: 42px;
            display: none;

            .activeProjectsInner {
              background: #fff;
              border: solid 1px #ccc;
              @include border-radius(2px);
              padding: 6px 8px;
              width: 350px;

              p {
                font-size: 13px;
                color: #555;
                margin: 0;
                font-weight: 400;

                a {
                  color: #d64d4d;
                  text-decoration: underline;
                  margin-left: 4px;
                }
              }

              .triangle {
                width: 50px;
                height: 22px;
                position: absolute;
                overflow: hidden;
                bottom: -10px;
                left: 50%;
                margin-left: -25px;
                box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
              }

              .triangle:after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #fff;
                transform: rotate(45deg);
                top: -22px;
                left: 25px;
                border: solid 1px #ccc;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
              }
            }
          }

          &:hover .activeProjects {
            display: block;
          }
        }

        .allocationSummaryTooltip {
          position: absolute;
          top: 10px;
          left: 98px;

          em {
            color: #555;
            font-size: 18px;
            cursor: pointer;
            z-index: 99;
            position: relative;
          }

          .installmentContentTooltip {
            width: auto;
            height: auto;
            min-width: 325px;
            min-height: 50px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #ccc;
            background-color: #fff;
            position: absolute;
            right: -20px;
            top: 26px;
            padding: 10px 16px;
            cursor: default;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            z-index: 9;
            display: none;

            .triangle {
              width: 50px;
              height: 22px;
              position: absolute;
              overflow: hidden;
              top: -22px;
              right: 13px;
              box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
            }

            .triangle:after {
              content: "";
              position: absolute;
              width: 25px;
              height: 25px;
              background: #fff;
              transform: rotate(45deg);
              top: 19px;
              left: 25px;
              border: solid 1px #ccc;
              box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
            }

            .tooltipContent {
              p {
                font-size: 14px;
                color: #555;
                margin: 0;
                font-weight: 400;
                line-height: 1.6;
              }
            }
          }

          &:hover .installmentContentTooltip {
            display: flex;
          }
        }

        &.assignedProjectsLabel {
          .allocationSummaryTooltip {
            left: 118px;

            .installmentContentTooltip {
              min-width: 330px;
            }
          }
        }

        &.pipelineLabel {
          .allocationSummaryTooltip {
            left: 55px;

            .installmentContentTooltip {
              min-width: 340px;
            }
          }
        }
      }

      td {
        border-top: solid 1px #ccc;

        .star {
          position: absolute;
          top: 17px;
          left: 0;
          cursor: pointer;

          em {
            font-size: 18px;

            &.gray {
              color: #e2e2e2;
            }

            &.amber {
              color: #ffbb1d;
            }

            &.purple {
              color: #800080;
            }

            &.no-fill {
              color: #4fa399;
            }
          }
        }

        &.no-data {
          width: 100%;
          text-align: center;

          img {
            width: 70px;
            margin: 30px 0 10px;
          }

          p {
            font-size: 14px;
            color: #777;
            margin: 0;
          }
        }

        &.manager-list-last {
          width: 100%;
          padding: 0;
          min-height: 1px;
          border: none;
        }

        .clipboard-block {
          position: absolute;
          left: 0;
          bottom: -29px;
          padding: 0 15px;
          @include border-radius(0 4px 4px 0);
          height: 40px;
          justify-content: center;
          border: 1px solid #ced4da;
          align-items: center;
          background: #fff;
          z-index: 99;
          display: none;

          a {
            color: #e9645d;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;

            i {
              margin-right: 6px;
            }

            .copy-clipboard {
              top: -40px;
              left: -70px;
            }
          }

          .arrow-up {
            width: 13px;
            height: 8px;
            background: url('../../assets/images/up-arrow-b.png') center center no-repeat;
            background-size: 100%;
            position: absolute;
            left: 50%;
            top: -7px;
            margin-left: -7px;
          }
        }

        &.emailed:hover .clipboard-block {
          display: flex;
        }
      }
    }

    table {
      width: 100%;
      float: left;
    }

    thead,
    tr {
      display: table;
      table-layout: fixed;
      width: 100%;

      &.thEqual {
        width: calc(100% - 10px);
      }
    }

    tbody {
      max-height: 275px;
      overflow: auto;
      width: 100%;
      display: block;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .no-results-found {
      height: auto;
      left: 0;
      margin: 30px 0;

      img {
        width: 80px;
        margin-bottom: 10px;
      }
    }
  }

  ul.project-allocation-edit {
    position: absolute;
    right: 0;
    top: 6px;
    margin: 0;

    li {
      float: left;
      margin-left: 20px;

      a {
        text-decoration: none;
        font-size: 14px;
        color: #e9645d !important;
        font-weight: 400;
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    i {
      margin-right: 3px;
    }
  }

  .project-status {
    .mutiple-assign {
      position: relative;

      .mutiple-select {
        height: 40px;
        border: solid 1px #ccc;
        color: #555;
        font-size: 14px;
        line-height: 39px;
        display: block;
        padding: 0 0.75rem;
        font-weight: 400;
        @include border-radius(4px);

        background: {
          image: url("../../assets/images/down-arrow.png");
          repeat: no-repeat;
          position: 97% center;
        }

        &:hover {
          background: {
            color: transparent;
          }
        }

        &.disabled {
          background: {
            color: #f4f4f4;
          }
        }

        i,
        fa-icon {
          color: #c99e13;
          font-size: 11px;
          position: relative;
          padding-right: 4px;
          top: -1px;

          &.golden {
            color: #c99e13;
          }

          &.blue {
            color: #6cb5d8;
          }

          &.green {
            color: #4fa399;
          }

          &.red {
            color: #e9645d;
          }

          &.lightGreen {
            color: #00cf00;
          }

          &.purple {
            color: #b39cdd;
          }
        }
      }

      .mutiple-assign-box {
        @include border-radius(4px);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
        border: solid 1px #cccccc;
        position: absolute;
        width: 100%;
        z-index: 999;
        top: 39px;

        ul.mutiple-assign-list {
          margin: 0;

          li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            padding: 7px 12px;
            color: #555;
            font-size: 14px;
            line-height: 22px;

            &:hover {
              background-color: #f4f4f6;
            }

            i,
            fa-icon {
              color: #c99e13;
              font-size: 11px;
              position: relative;
              padding-right: 9px;
              top: -1px;

              &.golden {
                color: #c99e13;
              }

              &.blue {
                color: #6cb5d8;
              }

              &.green {
                color: #4fa399;
              }

              &.red {
                color: #e9645d;
              }

              &.lightGreen {
                color: #00cf00;
              }

              &.purple {
                color: #b39cdd;
              }
            }
          }
        }
      }
    }

    .msg_prt {
      padding-left: 15px;
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      margin-top: 0;
      display: inline-block;
      width: 80%;
      vertical-align: middle;
    }

    .assign-btn {
      display: none;

      &.show {
        display: inline-block;

        &.disabled {
          background: #e9645d;
        }
      }

      &.disabled {
        background: {
          color: #ffffff;
        }
      }
    }

    .submit-live-links {
      h3 {
        font-size: 14px;
        color: #333;
        border-bottom: solid 2px #e2e2e2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        display: block;
        font-weight: 500;
        font-family: $heading-font;
      }

      .live-links-row {
        margin-bottom: 30px;

        .plateform-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;

          img {
            margin-right: 12px;
            max-width: 35px;
            max-height: 50px;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
          }

          h4 {
            color: #777;
            font-weight: 700;
            font-size: 13px;
            margin: 0;
          }
        }

        .form-group {
          label {
            color: #777;
            font-weight: 400;
            font-size: 13px;

            span {
              color: #f32121;
            }
          }

          input [type='text'] {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .show-password {
            position: absolute;
            right: 25px;
            bottom: 13px;
            cursor: pointer;
            color: #ccc;
          }

          .invalid-feedback {
            font-size: 13px;
            font-weight: 700;
            color: #ed1c24;
          }

          input.dirtyField {
            border-color: #ed1c24;
          }
        }
      }
    }

    .portfolio-deck {
      h3 {
        font-size: 14px;
        color: #333;
        border-bottom: solid 2px #e2e2e2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        display: block;
        font-weight: 600;
      }

      .portfolio-deck-row {
        margin-bottom: 20px;

        .form-group {
          position: relative;

          label {
            color: #777;
            font-weight: 400;
            font-size: 13px;

            span {
              color: #f32121;
            }
          }

          input.deck-url {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #2349c7;
            text-decoration: underline;
            height: 40px;
          }

          ul.urlCopyList {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            top: 0px;

            li {
              padding: 0 15px;
              border-left: solid 1px #dcdcdc;

              a {
                color: #e9645d;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;

                em {
                  margin-right: 6px;
                }

                img {
                  margin-right: 6px;
                  position: relative;
                  top: -2px;
                }

                .copy-clipboard {
                  top: -40px;
                  left: -70px;
                }
              }

              &:first-child {
                border: none;
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }

          .custom-checkbox {
            label {
              font-size: 14px;
              color: #333;
              font-weight: 700;
            }

            .checkmark {
              left: 0;
            }
          }

          .invalid-feedback {
            font-size: 13px;
            font-weight: 700;
            color: #ed1c24;
          }

          input.dirtyField {
            border-color: #ed1c24;
          }
        }
      }
    }
  }

  .team-allocation {

    // h3 {
    //   font-size: 14px;
    //   color: #333;
    //   border-bottom: solid 2px #e2e2e2;
    //   padding-bottom: 10px;
    //   margin-bottom: 10px;
    //   display: block;
    //   font-weight: 500;
    // }
    button.viewAllFeature {
      position: absolute;
      right: 15px;
      top: 0;
      background: transparent;
      border: none;
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      outline: none;

      i {
        margin-right: 3px;
      }
    }

    ul.status-block-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      li {
        border: dashed 1px #ccc;
        padding: 10px;
        min-width: 290px;
        @include border-radius(5px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          margin-right: 15px;
        }

        span {
          font-size: 14px;
          color: #9e9e9e;
          font-weight: 400;
          line-height: 28px;
          text-align: center;

          strong {
            font-size: 28px;
            color: #333;
          }
        }
      }
    }

    .nav-tabs {
      margin-bottom: 0;
    }

    .tab-content {
      .tab-inner {
        border: 2px solid #e2e2e2;
        border-top: none;
        float: left;
        width: 100%;
        overflow: hidden;
        height: calc(100vh - 510px);

        .tab-inner-left {
          width: 240px;
          float: left;
          border-right: solid 2px #e2e2e2;
        }

        .tab-inner-right {
          width: calc(100% - 240px);
          float: left;
          min-height: 242px;
        }

        .tab-inner-header {
          width: 100%;
          background: #f5f5f5;
          min-height: 50px;
          padding: 12px 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

          h4 {
            font-size: 14px;
            font-weight: 500;
            color: #333;
            margin: 0;

            .tooltipIcon {
              margin-left: 8px;
              font-size: 16px;
              vertical-align: middle;
              cursor: pointer;
              position: relative;
            }
          }

          .projects-search {
            margin-left: auto;
            margin-right: 0;
          }

          .expandCollapseView {
            margin-left: 12px;
            cursor: pointer;
            position: relative;

            .icons {
              position: relative;
              z-index: 99;

              img {
                width: 18px;
                opacity: 0.7;
                position: relative;
                top: -2px;
              }
            }

            &:hover .msgTooltip {
              display: flex;
            }
          }
        }

        .tab-inner-content {
          padding: 5px 15px;
          height: calc(100vh - 551px);
          overflow: auto;
          position: relative;

          ul.skillsList {
            li {
              width: 100%;
              display: block;
              border: solid 1px #ccc;
              @include border-radius(4px);
              font-size: 13px;
              font-weight: 400;
              color: #333;
              white-space: nowrap;
              padding: 6px 20px 6px 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              min-height: 35px;
              margin-bottom: 12px;
              position: relative;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              cursor: pointer;

              span {
                position: absolute;
                right: 3px;
                top: 7px;
              }

              i {
                opacity: 0;
                color: #4fa399 !important;
              }

              &:hover {
                color: #4fa399;
                border-color: #4fa399;
                border-left-width: 6px;

                i {
                  opacity: 1;
                }
              }

              &.active {
                color: #4fa399;
                border-color: #4fa399;
                border-left-width: 6px;

                i {
                  opacity: 1;
                }
              }
            }
          }

          .request-table {

            table,
            tbody,
            thead,
            tr {

              td,
              th {
                width: 10%;
                color: #333;
                font-size: 13px;
                font-weight: 400;
                border-top: solid 1px #ccc;
                position: relative;
                padding: 11px 13px 11px 0;
                line-height: 17px;
                vertical-align: middle;
                max-width: 200px;

                small {
                  font-size: 10px;
                }

                .td-inner {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;

                  &.disabledBtn {
                    overflow: visible;
                  }

                  em {
                    font-style: normal;
                    margin-right: 3px;
                  }

                  p {
                    margin: 0;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    white-space: nowrap;
                    overflow: hidden;

                    &:first-child {
                      margin-bottom: 5px;
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }

                  .currentAllocation {
                    span {
                      color: #9e9e9e;
                      font-style: italic;
                      font-size: 12px;
                      margin: -3px 0 0;
                      line-height: 16px;
                      display: inline-block;
                      &.no-italic {
                        font-style: normal;
                      }
                    }
                  }
                }

                .cancel-req {
                  position: absolute;
                  right: 0;
                  top: 18px;
                  display: flex;
                  cursor: pointer;

                  .cancel-req-btn-area {
                    position: absolute;
                    left: -124px;
                    bottom: -44px;
                    height: 50px;
                    display: none;

                    .cancel-req-btn {
                      padding: 0 12px;
                      @include border-radius(0 4px 4px 0);
                      height: 40px;
                      justify-content: center;
                      border: 1px solid #ced4da;
                      align-items: center;
                      background: #fff;
                      z-index: 99;
                      width: 155px;
                      color: #e9645d;
                      font-size: 14px;
                      font-weight: 400;
                      margin-top: 10px;
                      display: flex;

                      em {
                        font-size: 21px;
                        margin-right: 10px;
                      }

                      .arrow-up {
                        width: 13px;
                        height: 8px;
                        background: url('../../assets/images/up-arrow-b.png') center center no-repeat;
                        background-size: 100%;
                        position: absolute;
                        right: 6px;
                        top: 4px;
                        margin-left: -7px;
                        left: auto;
                      }
                    }
                  }

                  em {
                    color: #e9645d;
                    font-size: 16px;
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                  }

                  &:hover .cancel-req-btn-area {
                    display: flex;
                  }
                }

                &:first-child {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 15%;
                }

                &:nth-child(5) {
                  width: 15%;
                }

                &:nth-child(6) {
                  width: 15%;
                }

                &:last-child {
                  width: 10%;
                }
              }

              th {
                color: #9e9e9e;
                border: none;
                // padding: 15px 0;
              }

              td {
                .addProjectBtn {
                  background: none;
                  border: solid 1px #e9645d;
                  color: #e9645d;
                  font-size: 14px;
                  font-weight: 400;
                  @include border-radius(4px);
                  cursor: pointer;
                  padding: 3px 8px;
                  display: block;
                  line-height: 20px;
                  outline: none;

                  &+.installmentContentTooltip {
                    p {
                      text-transform: none;
                    }
                  }

                  &:disabled {
                    border-color: #ccc;
                    color: #ccc;

                    &:hover+.installmentContentTooltip {
                      display: block;
                    }
                  }
                }

                .installmentContentTooltip {
                  width: auto;
                  min-width: 450px;
                  min-height: 20px;
                  height: auto;
                  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
                  border: solid 1px #ccc;
                  background-color: #fff;
                  position: absolute;
                  left: auto;
                  top: auto;
                  z-index: 9;
                  padding: 8px 15px;
                  border-radius: 3px;
                  display: none;
                  right: 0;
                  margin-top: 7px;

                  .triangle {
                    width: 50px;
                    height: 22px;
                    position: absolute;
                    overflow: hidden;
                    bottom: auto;
                    left: auto;
                    box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                    -webkit-transform-origin: 0 0;
                    -moz-transform-origin: 0 0;
                    -ms-transform-origin: 0 0;
                    -o-transform-origin: 0 0;
                    transform-origin: 0 0;
                    top: 0;
                    right: -73px;
                  }

                  .triangle:after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    transform: rotate(45deg);
                    top: -22px;
                    left: 25px;
                    border: solid 1px #ccc;
                    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
                  }

                  p {
                    font-size: 14px;
                    color: #555;
                    margin: 0;
                    font-weight: 400;
                  }
                }

                span {
                  color: #e9645d;
                  font-size: 14px;
                  font-weight: 400;
                  margin-right: 12px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 140px;

                  &.approved {
                    color: #4fa399;
                  }

                  &.requested {
                    color: #d29d23;
                  }
                }

                img.reason-why {
                  width: 16px;
                  cursor: pointer;
                }

                i {
                  color: #e9645d;
                  cursor: pointer;
                }

                small {
                  color: #9e9e9e;
                }

                .clipboard-block {
                  position: absolute;
                  left: 0;
                  bottom: -29px;
                  padding: 0 15px;
                  @include border-radius(0 4px 4px 0);
                  height: 40px;
                  justify-content: center;
                  border: 1px solid #ced4da;
                  align-items: center;
                  background: #fff;
                  z-index: 99;
                  display: none;

                  a {
                    color: #e9645d;
                    font-size: 13px;
                    font-weight: 500;
                    text-decoration: none;

                    i {
                      margin-right: 6px;
                    }

                    .copy-clipboard {
                      top: -40px;
                      left: -70px;
                    }
                  }

                  .arrow-up {
                    width: 13px;
                    height: 8px;
                    background: url('../../assets/images/up-arrow-b.png') center center no-repeat;
                    background-size: 100%;
                    position: absolute;
                    left: 50%;
                    top: -7px;
                    margin-left: -7px;
                  }
                }

                &.emailed:hover .clipboard-block {
                  display: flex;
                }

                &.expertise {
                  text-transform: capitalize;
                }

                .actions {
                  position: relative;

                  .actionsLabel {
                    font-size: 14px;
                    color: #333;
                    opacity: 0.7;
                    margin: 0;
                    cursor: pointer;
                  }

                  .dropdown-menu {
                    left: auto;
                    right: -14px;
                    top: 23px;
                    padding: 0;
                    min-width: 120px;
                  }

                  .dropdown-item {
                    font-size: 14px;
                    font-weight: 500;
                    color: #777;
                    letter-spacing: 0.2px;
                    border: none;
                    padding: 12px 12px 0;
                    background: transparent !important;

                    &:last-child {
                      padding-bottom: 12px;
                    }

                    &:hover {
                      color: #4fa399;
                    }

                    &:focus {
                      color: #4fa399;
                    }
                  }

                  .triangle {
                    width: 50px;
                    height: 12px;
                    position: absolute;
                    overflow: hidden;
                    top: -12px;
                    right: 3px;
                    box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
                  }

                  .triangle:after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    transform: rotate(45deg);
                    top: 10px;
                    left: 25px;
                    border: solid 1px #ccc;
                    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
                  }
                }

                p.extenApproval {
                  font-size: 12px;
                  font-weight: 500;
                  margin: 0;
                  color: #fd9a00;
                  line-height: 16px;

                  &.extended {
                    color: #39c718;
                  }

                  &.rejected {
                    color: #e9645d;
                  }
                }
              }
            }

            &.current-table {

              td,
              th {
                &:first-child {
                  width: 25%;
                }

                &:nth-child(2) {
                  width: 12%;
                }

                &:nth-child(3) {
                  width: 10%;
                }

                &:nth-child(4) {
                  width: 12%;
                }

                &:nth-child(5) {
                  width: 12%;
                  max-width: 100px;
                }

                &:nth-child(6) {
                  width: 14%;
                  max-width: 160px;
                }

                &:last-child {
                  width: 7%;
                }
              }
            }

            &.accepted-table {

              td,
              th {
                &:nth-child(6) {
                  width: 19%;
                }
              }
            }

            table {
              float: left;
              width: 100%;
            }

            .pagination-loader {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 15px 0;
              float: left;
              width: 100%;
            }
          }

          .no-team-members {
            position: absolute;
            width: calc(100% - 30px);
            height: calc(100% - 20px);
            display: flex;
            justify-content: center;
            align-items: center;

            .no-members-content {
              text-align: center;

              img {
                width: 80px;
                margin-bottom: 20px;
              }

              p {
                font-size: 16px;
                color: #333;
                margin-bottom: 0;
              }
            }
          }

          .no-members-found {
            position: absolute;
            width: calc(100% - 30px);
            height: calc(100% - 20px);
            display: flex;
            justify-content: center;
            align-items: center;

            .no-members-content {
              text-align: center;

              img {
                width: 80px;
                margin-bottom: 20px;
              }

              p {
                font-size: 16px;
                color: #333;
                margin-bottom: 0;
              }
            }
          }
        }

        &.request-tab {
          .tab-inner-right {
            &.right-fluid {
              width: 100%;
            }
          }
        }

        &.expandView {
          height: calc(100vh - 193px);

          .tab-inner-content {
            height: calc(100vh - 232px);
          }
        }
      }
    }

    ul.overall-count {
      position: absolute;
      right: 15px;
      top: 10px;
      margin: 0;

      li {
        float: left;
        margin-left: 20px;
        font-size: 14px;
        color: #4fa399;
        font-weight: 400;
      }
    }
  }

  .all-features {
    .all-features-table {

      table,
      tbody,
      thead,
      tr {
        width: 100%;
        display: inline-block;

        td,
        th {
          width: 10%;
          float: left;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          border-top: solid 1px #ccc;
          position: relative;
          min-height: 52px;
          padding: 11px 0;
          line-height: 27px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 15px;

          &:first-child {
            width: 20%;
          }

          &:nth-child(2) {
            width: 60%;
          }
        }

        th {
          color: #9e9e9e;
          border: none;
          padding-top: 0;
        }

        td {
          .addProjectBtn {
            background: none;
            border: solid 1px #e9645d;
            color: #e9645d;
            font-size: 14px;
            font-weight: 400;
            @include border-radius(4px);
            cursor: pointer;
            padding: 3px 10px;
            display: block;
            line-height: 20px;
            outline: none;
          }

          span {
            color: #e9645d;
            font-size: 14px;
            font-weight: 400;
            margin-right: 12px;

            &.approved {
              color: #4fa399;
            }

            &.requested {
              color: #d29d23;
            }
          }

          img.reason-why {
            width: 16px;
            cursor: pointer;
          }

          i {
            color: #e9645d;
            cursor: pointer;
          }

          &.complexity-skill {
            text-transform: capitalize;
          }

          &.description {
            white-space: normal;
          }
        }
      }

      &.current-table {

        td,
        th {
          width: 9%;

          &:first-child {
            width: 19%;
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(4) {
            width: 7%;
          }

          &:nth-child(6) {
            width: 13%;
          }

          &:nth-child(7) {
            width: 11%;
          }

          &:last-child {
            width: 5%;
          }
        }
      }
    }
  }

  &.view-all-features {
    .projects-search-filter {
      right: 0;
      top: 4px;
      margin: 0;
      position: absolute;

      .projects-search {
        margin: 0;
      }
    }
  }
}

.mutiple-assign.show .mutiple-assign-box {
  display: block;
}

.delTask-modal {
  .modal-dialog {
    max-width: 440px;
  }

  h3 {
    font-size: 24px !important;
    color: #000 !important;
    font-weight: 400;
    padding: 0 !important;
    margin-bottom: 1rem !important;
  }

  p {
    font-size: 13px;
    color: #333;
    font-weight: 500;
    margin-bottom: 1rem;

    span {
      color: #777;

      &.revised-date {
        color: #4fa399;
      }
    }
  }

  .cancel-btn {
    @include border-radius(4px);
    font-size: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: solid 1px #e9645d;
    min-width: 105px;
    margin-left: 10px;
    background-color: transparent;
    color: #e9645d;
  }

  &.archivePopup {
    p {
      span {
        color: #333;
      }
    }
  }
}

// Add delay modals
.want-to-add-delay,
.delay-days-and-reason {
  font-family: 'Rubik', sans-serif;

  h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .modal-dialog {
    max-width: 600px;

    .modal-content {
      border-radius: 0.3rem;
    }

    .modal-body {
      padding: 20px;

      .popup-circular-close {
        height: 32px;
        width: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dfe0e6;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
      }

      .form-body {
        .form-group {
          padding: 0;

          .select-box-container {
            margin-bottom: 20px;

            select {
              width: 100%;
              border: 1px solid #afb2c0;
              height: 40px;
              border-radius: 3px;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      input,
      label {
        font-size: 14px;
      }

      form {
        .row {
          margin-bottom: 20px;

          label {
            color: #000;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            margin-bottom: 0;
          }

          .description {
            color: #3c3e49;
            font-size: 14px;
            line-height: 20px;

            p {
              margin: 0;
            }
          }
        }
      }
    }

    .content-box {
      padding: 10px;

      .btn {
        border-radius: 4px;
        font-size: 14px;
        box-shadow: none;
        min-width: 56px;

        &.cancel-btn {
          border: 1px solid #000;
          color: #000;

          &:hover {
            background: #f8f9fa;
          }
        }

        &.confirm-btn {
          background: #00e676;
          border: 1px solid #00e676;
          color: #000;
          margin-left: 10px;

          &:hover {
            background: #07c166;
          }
        }
      }
    }
  }
}

.add-folder-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .add-folder-con {
    width: 100%;
    text-align: center;
    position: relative;
    top: -20px;

    img {
      width: 84px;
    }

    p {
      width: 100%;
      margin: 1.5rem 0;
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }
  }

  &.hide {
    display: none;
  }
}

.document-tab .nav-tabs {
  margin-bottom: 0;
}

.topAddUploadfile {
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 1;

  button.addQues-btn,
  button.addUploadfile-btn {
    color: #e9645d;
    font-size: 14px;
    font-weight: 400;
    box-shadow: none !important;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    padding-left: 20px;

    i {
      margin-right: 5px;
    }
  }
}

.folder-main-block {
  padding: 0 20px;
  margin: 20px 0;
  position: relative;

  .new-folder-block {
    position: absolute;
    left: 5px;
    top: 0;
    max-width: 50%;
    max-width: calc(50% - 5px);

    img {
      width: 14px;
      margin-bottom: 5px;
    }
  }

  .project-card {
    @include border-radius(4px);
    height: 90px;
    padding: 5px 10px;
    border-top: solid 6px #4fa399;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .folder-con {
      width: 100%;
    }

    p {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      margin: 0 0;
      margin-top: 2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h5 {
      color: #555;
      font-size: 11px;
      font-weight: 400 im !important;
      margin: 0 0 4px;
      // span {
      //   font-weight: 500;
      // }
    }

    &:hover {
      border-color: #4fa399;
      background: #edf6f5;
    }

    .del-folder {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #4fa399;
      background: #edf6f5;
      position: absolute;
      right: -10px;
      top: -9px;
      cursor: pointer;
      @include border-radius(50%);
      i,
      fa-icon {
        color: #e9645d;
        font-size: 14px;
      }
    }
  }

  .selected {
    .project-card {
      border-color: #4fa399;
      background: #edf6f5;
    }
  }
}

.file-folder-list-block {
  padding: 10px 20px;
  position: relative;
  height: 100%;

  ul.file-listing {
    margin: 0;
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    overflow: auto;
    bottom: 0;
    top: 60px;

    &::-webkit-scrollbar {
      width: 2px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-right: none;
      border-left: none;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid 1px #ccc;
      font-size: 12px;
      color: #777;
      font-weight: 400;
      height: 60px;
      padding: 0 12px;
      margin: 7px 0;

      .action {
        width: 30%;
        display: flex;

        .requestFor {
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: "Rubik", sans-serif;
          border-right: 1px solid #c0c3ce;
          padding: 0 8px 0 0;
          margin: 0 8px 0 0;
          width: 200px;

          &.blueColor {
            color: #6200ea;
            text-decoration: underline;
            cursor: pointer;
          }

          &.orangeColor {
            color: #ff6f00;
            pointer-events: none;
          }

          &.greenColor {
            color: #2e7d32;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .downloadIcon {
          color: #6200ea;
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin: 0 8px 0 0;
        }

        .deleteIcon {
          color: #ff0000;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }

      span {
        width: 20%;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 12px;
        text-overflow: ellipsis;

        .custom-checkbox {
          position: absolute;
          left: 0;
          top: 0;

          label {
            margin-bottom: 0.5rem;
          }
        }

        &:first-child {
          width: 30%;
          font-family: "Rubik", sans-serif;
          font-weight: 500;
        }

        .checkmark {
          position: absolute;
          top: 4px;
          left: 0;
          height: 12px;
          padding-right: 0;
          width: 12px !important;
          border: solid 1px #ccc;

          &:after {
            left: 3px;
            top: 0;
            width: 5px;
            height: 8px;
          }
        }

        .custom-checkbox input:checked~.checkmark {
          background-color: #777;
        }
      }
    }

    &.head {
      position: absolute;
      width: calc(100% - 40px);
      height: auto;
      background: #fff;
      margin: 0;
      bottom: auto;
      top: 0;

      li {
        color: #333;
        border: none;
      }
    }
  }
}

.folder-header {
  position: relative;
  padding-left: 20px;

  .custom-checkbox {
    position: absolute;
    left: 0;
    top: 1px;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 12px;
    width: 12px !important;
    border: solid 1px #ccc;

    &:after {
      left: 3px;
      top: 0;
      width: 5px;
      height: 8px;
    }
  }

  .custom-checkbox input:checked~.checkmark {
    background-color: #777;
  }

  input.folder-edit-field {
    font-size: 14px;
    color: #333;
    background: transparent;
    border: none;
    outline: none;
    height: 21px;
  }

  h4 {
    font-size: 14px;
    color: #333;
    margin: 0;
    font-weight: 400;
    // max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: $heading-font;
    i,
    fa-icon {
      color: #e9645d;
      margin-left: 2px;
      font-size: 18px;
    }
  }

  ul.sort-filter-list {
    li {
      margin-right: 25px;
    }
  }
}

.add-file-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .add-file-con {
    width: 100%;
    text-align: center;
    position: relative;
    top: 0;
    height: 100%;
    padding: 20px;

    img {
      width: 84px;
    }

    p {
      max-width: 450px;
      width: 100%;
      margin: 1.5rem auto;
      font-size: 16px;
      color: #333;
      font-weight: 400;

      span {
        color: #e9645d;
      }
    }
  }

  &.hide {
    display: none;
  }
}

.client-modal#addFilePopup,
.client-modal#createFolderPopup,
.client-modal#deleteFolderPopup,
.client-modal#deleteIdeaPopup {
  .modal-dialog {
    max-width: 415px;

    .modal-body {
      padding: 1.5rem;
    }

    h2 {
      padding: 0;
    }

    .form-group {
      padding: 0;
      margin-bottom: 24px;

      &:last-child {
        margin: 0;
      }

      .create-new-folder {
        color: #e9645d;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        text-align: right;
        width: 100%;
        margin: 10px 0 30px;
        float: left;
      }

      label {
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      p {
        font-weight: 500;
        font-size: 13px;
        color: #333;
      }
    }
  }
}

span.browse-file input.form-control.bulkupload {
  right: 100px;
}

span.browse-file {
  position: relative;
  width: 100%;
  text-align: right;
  float: left;
  margin: 10px 0 20px;
  color: #e9645d;
  font-size: 14px;
  font-weight: 400;

  input[type='file'] {
    opacity: 0;
    width: 100px;
    height: 20px;
    right: 0px;
    bottom: 0;
    position: absolute;
  }
}

.ideaboard {
  .add-task-block {
    .add-task-con {
      width: 100%;
      max-width: 800px;

      p {
        span {
          color: #e9645d;
        }
      }
    }
  }
}

.add-idea-modal {
  .attach-snapshot {
    border: solid 1px #ccc;
    @include border-radius(4px);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    i {
      color: #e9645d;
      font-size: 20px;
    }

    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.ideaboard {
  ul.tasks-queries-list.ideaboard-list {
    li {
      img {
        margin-right: 6px;
      }

      align-items: center;

      p {
        span {
          &.title {
            font-size: 14px;
          }

          &.blue {
            color: #6cb5d8;
          }

          &.red {
            color: #e9645d;
          }
        }
      }

      // &.need-review {
      //   border-left: solid 3px #4fa399;
      // }
    }
  }

  .task-detail-row {
    border-bottom: solid 1px #ccc;
    padding-bottom: 20px;

    .pro-status {
      i {
        &.blue {
          color: #6cb5d8;
        }
      }

      .status-option {
        select {
          min-width: 140px;
        }
      }
    }

    &.b-none {
      border: none;
    }

    label {
      margin-bottom: 8px;
    }

    textarea {
      margin-top: 0;
    }

    .select-box {
      max-width: 100%;
      width: 300px;
    }

    .attach-snapshot {
      border: dashed 1px #555;
      @include border-radius(4px);
      min-height: 80px;
      height: auto;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        max-width: 200px;
      }
    }
  }

  .ideaboard-right-block {
    .todos-inner {
      height: calc(100% - 45px);
    }

    .ideaboard-right-header {
      h4 {
        font-size: 18px;
        color: #333;
        margin: 0;
        font-weight: 400;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        fa-icon {
          color: #e9645d;
          margin-left: 2px;
          font-size: 18px;
        }
      }
      i,
      fa-icon {
        color: #e9645d;
        margin-left: 4px;
        font-size: 18px;
      }

      ul.sort-filter-list {
        &.renameTitleSave {
          display: none;
        }
      }

      input.title-edit-field {
        font-size: 18px;
        color: #333;
        background: transparent;
        border: none;
        outline: none;
        height: 21px;
      }
    }
  }
}

.project-progress {
  h3 {
    font-size: 14px;
    color: #9e9e9e;
    border-bottom: solid 2px #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: block;
    font-weight: 400;
  }

  textarea {
    border-color: #ccc;
    resize: none;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;

    &:focus {
      border-color: #ccc;
      box-shadow: none;
    }
  }
}

p.change-ongoing-error {
  color: #e9645d;
  font-size: 15px;
}

.supporting-doc {
  position: relative;

  h3 {
    color: #333;
    font-weight: 700;
    font-size: 13px;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  .attach-file {
    position: absolute;
    right: 17px;
    top: 0;
    margin-left: 0;
  }

  .docList-block {
    margin: 0 30px 10px 0;
    float: left;
    width: 140px;
    height: 170px;
    text-align: center;

    .tag {
      background-color: #f4f4f4;
      transition: all 0.3s ease-in-out;
      width: 140px;
      height: 120px;
      position: relative;
      overflow: visible;

      .del-folder {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #4fa399;
        background: #edf6f5;
        position: absolute;
        right: -13px;
        top: -14px;
        cursor: pointer;
        opacity: 1;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        @include border-radius(50%);
        i,
        fa-icon {
          color: #e9645d;
          font-size: 16px;
        }
      }

      img {
        width: 48px;
      }

      &:hover .del-folder {
        opacity: 1;
        visibility: visible;
      }
    }

    p {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #555;
    }
  }
}

.client-modal#ProTimelinePopup {
  .modal-dialog {
    max-width: 650px;

    .timeline-main-block {
      padding: 0;

      .timeline-block {
        max-height: 460px;
      }

      ul {
        margin: 0;
        padding: 30px 0;

        li {
          background: {
            position: 2px 2px;
          }

          h4 {
            color: #777;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px; // margin: 10px 0;
            margin: 0;

            &.highlight {
              font-weight: 700;
            }
          }

          &.date-placed {
            background: {
              position: 4px 6px;
            }
          }

          >span.commentTitle {
            color: #555;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 6px;
            display: block;
          }

          p {
            color: #888;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 6px;
            line-height: 1.7;
          }

          &.new-feature-status {
            display: block;

            .feature-status {
              border: 1px solid;
              padding: 17px 17px 11px 17px;
              margin-right: 20px;
              margin-top: 13px;
              border-radius: 5px;
              background: #ccc;

              &.green {
                border-color: #4c9d93;
                background: rgba(76, 157, 147, 0.16);
                word-break: break-all;
              }

              &.red {
                border-color: #d64d4d;
                background: rgba(214, 77, 77, 0.16);
              }

              p {
                font-size: 11px;
                font-weight: 500;
                color: #777;
                line-height: 1.6;

                span {
                  color: #333;

                  &.blk-prt {
                    color: #777;
                    display: block;
                    margin-bottom: 3px;
                  }
                }
              }

              .list-inline {
                padding: 0;
                margin-bottom: 6px;

                li {
                  display: inline-block;
                  background: none;
                  padding: 0;
                  position: relative;
                  margin: 0;
                  vertical-align: top;
                  font-size: 11px;
                  padding-left: 9px;
                  padding-right: 9px;
                  font-weight: 500;
                  color: #555;

                  &:first-child {
                    padding-left: 0;

                    &:before {
                      display: none;
                    }
                  }

                  &:before {
                    content: "";
                    height: 11px;
                    width: 1px;
                    background: #777;
                    position: absolute;
                    left: 0;
                    top: 3px;
                  }
                }
              }

              .bullet-list {
                padding: 0 0 0 15px;
                margin: 0 0 6px !important;
                li {
                  position: relative;
                  color: #333;
                  margin-bottom: 3px;
                  padding-right: 0;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 5px;
                    height: 4px;
                    width: 4px;
                    border-radius: 50%;
                    background-color: #333;
                  }
                  .list-title {
                    color: #777;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.blocked-detail {
  .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .gray-box {
    min-height: 40px;
    font-size: 14px;
  }

  .supporting-doc {
    h3 {
      display: block;
      border-bottom: solid 2px #e2e2e2;
    }
  }

  input[type='text'] {
    border: solid 1px #ccc;
    font-size: 14px;
    color: #555;
    background: transparent;
    height: 40px;

    &:focus {
      box-shadow: none;
      border-color: #ccc;
    }
  }

  textarea {
    resize: none;
    min-height: 90px;
    background: transparent;
    border: solid 1px #ccc;
    font-size: 14px;
    color: #555;

    &:focus {
      box-shadow: none;
      border-color: #ccc;
    }
  }

  .supporting-doc {
    .blockedTag {
      float: left;
      margin: 0 30px 30px 0;
      text-align: center;
      position: relative;

      .del-folder {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #4fa399;
        background: #edf6f5;
        position: absolute;
        right: -13px;
        top: -9px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        @include border-radius(50%);
        i,
        fa-icon {
          color: #e9645d;
          font-size: 16px;
        }
      }

      .tag {
        margin: 0;
        float: none;

        &:hover {
          background: #edf6f5;
          border-color: #4fa399;
        }
      }

      p {
        font-size: 14px;
        color: #555;
        margin: 6px 0 0;
      }

      &:hover .del-folder {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  ul.blockedReasonEdit {
    position: absolute;
    right: 0;
    top: 5px;
    margin: 0;

    li {
      float: left;
      margin-left: 20px;

      a {
        text-decoration: none;
        font-size: 14px;
        color: #e9645d !important;
        font-weight: 400;
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    i {
      margin-right: 3px;
    }
  }
}

.right-content.checklist-main {
  padding-bottom: 0;
}

.checklist-tab {
  .nav-tabs {
    margin-bottom: 0;
  }

  .tab-content {
    height: calc(100vh - 308px); // border-left: solid 1px #e2e2e2;

    // border-right: solid 1px #e2e2e2;
    .tab-pane {
      height: 100%;
      position: relative; // &#infrastructure {

      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   p {
      //     margin: 0;
      //     color: #c5c2c2;
      //     font-size: 40px;
      //   }
      // }
      .add-db-block {
        img {
          width: 97px;
        }
      }

      .create-code-repo-block {
        img {
          width: 52px;
        }
      }
    }

    .builderTrackerTable {
      margin-top: 15px;

      table,
      tbody,
      thead,
      tr {

        td,
        th {
          width: 15%;
          color: #333;
          font-size: 13px;
          font-weight: 400;
          border-top: solid 1px #ccc;
          position: relative;
          padding: 11px 15px 11px 0;
          line-height: 17px;
          vertical-align: middle;
          max-width: 200px;
          font-family: $heading-font;

          small {
            font-size: 10px;
          }

          .td-inner {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 40px;

            em {
              font-style: normal;
              color: #999;
              font-size: 15px;
            }

            input[type='text'] {
              height: 40px;
              border-radius: 4px;
              border: solid 1px #4fa399;
              background-color: #ffffff;
              width: 95%;
              outline: none;
              padding: 0 10px;
            }

            ul.actionList {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 0;
              padding: 0;

              li {
                width: 24px;
                height: 24px;
                border-radius: 100px;
                justify-content: center;
                display: flex;
                border: solid 1px #999;
                align-items: center;
                margin-right: 10px;
                flex-shrink: 0;
                cursor: pointer;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .setupChatBtn {
              background: #e9645d;
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              padding: 5px 20px;
              min-width: 100px;
            }
          }

          &:first-child {
            width: 20%;
          }

          &:last-child {
            width: 84px;
          }
        }

        th {
          color: #9e9e9e;
          border: none;
          padding: 15px 0;
          border-bottom: solid 1px #ccc;
        }

        td {
          top: -1px;
        }
      }

      table {
        float: left;
        width: 100%;
      }

      thead,
      tr {
        display: table;
        table-layout: fixed;
        width: 100%;
      }

      tbody {
        max-height: 520px;
        overflow: auto;
        display: block;
        width: 100%;
      }

      .pagination-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
        float: left;
        width: 100%;
      }

      .no-results-found {
        height: 100%;
      }
    }
  }
}

.db-modal {
  .modal-dialog {
    .modal-body {
      padding: 3rem;
    }

    max-width: 895px;
  }

  h3 {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
    color: #555;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .cancel-btn {
    @include border-radius(4px);
    font-size: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: solid 1px #e9645d;
    min-width: 85px;
    margin-left: 10px;
    background-color: transparent;
    color: #e9645d;
  }

  .form-group {
    padding: 0;
    position: relative;

    &:last-child {
      .sep {
        display: none;
      }
    }
  }

  .plateform-block {
    position: relative;

    .form-group {
      input.dirtyField {
        border-color: #ed1c24;
      }
    }
  }

  .closePlateform {
    position: absolute;
    right: 0;
    top: 0;
    color: #e9645d;
    font-size: 16px;
    cursor: pointer;
  }

  label {
    font-weight: 500;
    margin-bottom: 6px;
  }

  input[type='text'] {
    margin-bottom: 10px;
  }

  .btn-block.genrateLink {
    height: 40px;
    display: none !important;
  }

  .sep {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    background: #d7d7d7; // display: none;
  }

  button.addAnotherBoardBtn {
    background: transparent;
    border: none;
    color: #e9645d;
    font-size: 14px;
    padding: 0;
    font-weight: 400;
    outline: none;
    cursor: pointer;

    i {
      margin-right: 6px;
    }
  }
}

.checklist-common-list {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;

  .add-task-con {
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    img {
      width: 70px;
    }

    p {
      width: 100%;
      margin: 1rem 0;
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }

    .add-task-btn {
      background: #e9645d;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 5px 20px;
      min-width: 100px;

      &:focus {
        box-shadow: none;
      }
    }

    .setupChatBtn {
      button {
        margin: 0 6px;
      }
    }
  }

  &.code-repo-list {
    .add-task-con {
      img {
        width: 33px;
      }
    }
  }

  &.builder-planner-list {
    .add-task-con {
      img {
        width: 54px;

        &.mattermostIcon {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.account-setup-list {
    .add-task-con {
      img {
        width: 50px;
      }
    }
  }

  .checklist-common-table {
    &.code-repo-table {
      table {

        th,
        td {
          width: 12%;

          &:nth-child(3) {
            width: 28%;
          }

          &:last-child {
            width: 24%;
          }
        }
      }
    }

    .table-header {
      padding-right: 0;
    }

    .table-content {
      height: 275px;
      overflow: auto;

      &.ovf-vis {
        overflow: visible;
      }
    }

    table {
      width: 100%;

      tr {
        width: 100%;
        float: left;
        outline: none;

        &.disabled-row {
          background-color: #f1f1f1;

          td {
            color: #c3c1c1;

            a {
              color: #c3c1c1;
              pointer-events: none;
              cursor: default;
            }
          }
        }
      }

      td,
      th {
        border-bottom: solid 1px #ccc;
        padding: 15px;
        width: 15%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;

        &.ovf-vis {
          overflow: visible;
        }
      }

      th {
        color: #9e9e9e;
        font-weight: 400;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          width: 30%;
        }

        &:nth-child(3) {
          width: 40%;
        }
      }

      td {
        color: #333;
        font-weight: 400;
        min-height: 52px;
        max-width: 460px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          color: #e9645d;
          width: 30%;
          position: relative;
        }

        &.editCopyListing {
          padding-right: 0;
          color: #e9645d;
          width: 30%;
          position: relative;
          overflow: visible;
        }

        &:nth-child(3) {
          width: 40%;
        }

        a {
          display: inline-block;
          cursor: pointer;
          margin-right: 20px;
          color: #e9645d;
          text-decoration: none;

          i {
            margin-right: 5px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .builder-planner-table {
    table {

      td,
      th {
        width: 12%;
      }

      th {
        &:first-child {
          width: 10%;
        }

        &:last-child {
          width: 30%;
        }

        &:nth-child(2) {
          width: 14%;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(4) {
          width: 34%;
        }
      }

      td {
        &:first-child {
          width: 10%;
        }

        &:last-child {
          width: 30%;
          overflow: visible;
        }

        &:nth-child(2) {
          width: 14%;
          max-width: 200px;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(4) {
          width: 34%;
          max-width: 460px;
        }
      }
    }
  }

  .code-repo-table,
  .design-board-table {
    table {
      td {
        &:nth-child(3) {
          max-width: 530px;
        }
      }
    }
  }

  .account-setup-table {
    table {
      tr {

        //display: flex;
        th {
          &:nth-child(3) {
            width: 50%;
          }

          &:last-child {
            width: 20%;
          }
        }

        td {
          &:nth-child(3) {
            width: 50%;
            max-width: 620px;
          }

          &:last-child {
            width: 20%;
          }
        }
      }
    }
  }
}

.checklist-common-list.hide {
  display: none;
}

.add-slack-modal {
  .modal-dialog {
    max-width: 660px;
  }
}

/*==================Loader style start==================*/

.pm-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.loader {
  width: 60px;
  height: 60px;
  padding: 10px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader-content,
.loader-rounded {
  position: absolute;
  top: 0;
  left: 0;
}

.loader-rounded {
  @include border-radius(50%);
  background: linear-gradient(#b11c81 30%, #ee1453 100%, #d731a2 10%);
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  animation: spin-outer 0.8s linear infinite;
}

.loader-content {
  width: 50px;
  height: 50px;
  left: 5px;
  top: 5px;
  text-align: center;
  @include border-radius(50%);
  padding: 10px 0;
  background: url('../../assets/images/pm-loader-img.png') no-repeat 16px center #fff;
  background-size: 23px;
}

@keyframes spin-outer {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*==================New builder Loader start==================*/

.initialLoader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%; // background: #E8EFFA;
  background: rgba(0, 0, 0, 0.7);
  background-attachment: fixed;
  text-align: center;
  z-index: 99999;
  top: 0;
  left: 0;

  &.toasterwrap {
    .project-success-msg {
      max-width: 820px;
      border-radius: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #e8f5e9;

      &.project-error-msg {
        background-color: #f8d7da;
        border-color: #f5c6cb;

        h3 {
          color: #721c24;
          padding: 0;
        }

        img {
          display: none;
        }

        a:not([href]):not([tabindex]) {
          color: #721c24;
        }
      }

      img {
        width: auto;
      }

      h3 {
        color: #388e3c;
        font-size: 12px;
      }

      a:not([href]):not([tabindex]) {
        color: #388e3c;
      }
    }

    &:before {
      display: none;
    }
  }
}

.initialLoader:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 104px;
  height: 104px;
  box-shadow: 1px 4px 40px rgba(67, 117, 195, 0.2);
  background: #fff;
  padding: 20px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 6px;
}

.initialLoader .homeLoader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  z-index: 9999;
  animation: homeloading 1.55s ease-in-out infinite;
}

.initialLoader .homeLoader img {
  width: 100%;
  height: auto;
}

.initialLoader .loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

@-webkit-keyframes homeloading {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.8);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes homeloading {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/*==================New builder Loader end==================*/

/*==================Loader style end==================*/

#addTaskPopup,
.task-due {
  .due-date-calender {
    padding: 0;
    height: 31px;
    float: left;

    .my-date-picker-new {
      width: 110px;
      float: left;
      position: relative;
      z-index: 99;

      .btnclear {
        display: none;
      }
    }

    .mydp,
    .mydp .headertodaybtn {
      border: none;
      background: transparent;
      width: 100%;
    }

    .mydp {

      .btnclear,
      .btndecrease,
      .btnincrease,
      .btnpicker,
      .headertodaybtn {
        background: transparent !important;
      }

      .btnpicker {
        width: 100%;
        text-align: left;
        padding-left: 9px;
      }

      .headertodaybtn {
        border: none;
        background: transparent;
      }

      .selectiongroup {
        background: transparent;

        .selbtngroup {
          height: 28px !important;
        }
      }

      .selection {
        background: transparent;
        color: #e9645d;
      }

      .mydpicon {
        color: #e9645d;
      }
    }

    .cal-input {
      position: absolute;
      background: transparent !important;
      border: none;
      z-index: 9;
      right: 0;
      padding: 0 0px 0 0;
      top: 6px;
      width: 80px;
      color: #e9645d;

      &::-webkit-input-placeholder {
        color: #e9645d;
      }

      &::-moz-placeholder {
        color: #e9645d;
      }

      &:-ms-input-placeholder {
        color: #e9645d;
      }

      &:-moz-placeholder {
        color: #e9645d;
      }
    }
  }
}

a.view-slack-guideline {
  display: block;
  color: #e9645d;
  font-size: 14px;
  text-decoration: none;
  margin: 10px 0;
}

.demographics-accordion,
.slack-list-accordion {
  .card {
    background-color: transparent;
    border: none;
    border-radius: 0;

    .card-header {
      background: transparent;
      padding: 5px 0;
      border-color: #ccc;
      position: relative;

      button {
        color: #666;
        font-size: 14px;
        width: 100%;
        text-align: left;
        padding-left: 0;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 200px);
        font-family: $heading-font;
      }
    }

    .collapse {
      &.show {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
    }

    .card-body {
      padding-left: 0;

      label {
        font-size: 12px;
        font-weight: 500;
        color: #333;

        span {
          color: #f00;
        }
      }
    }

    &.acc-arr-down {
      background: url("../../assets/images/arrow-down.png") right 20px no-repeat;
    }

    &.acc-arr-up {
      background: url("../../assets/images/arrow-up.png") right 20px no-repeat;
    }

    &.disabled-row {
      background-color: #f1f1f1;

      .card-header {
        button {
          color: #c3c1c1;
        }

        ul.edit-archive-slack {
          li {
            a {
              color: #c3c1c1;
              pointer-events: none;
              cursor: default;
            }
          }
        }
      }
    }
  }

  .slackView {
    input[type="text"] {
      pointer-events: none;
    }
  }
}

ul.edit-archive-slack {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: auto;
  position: absolute;
  top: 11px;
  right: 20px;

  li {
    a {
      color: #e9645d;
      font-size: 14px;
      text-decoration: none;
      padding: 0 8px;
    }
  }

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
  }
}

.confidenceTopRow {
  .epic-phase-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .epic-phase-top-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 50%;
      border-right: solid 1px #ccc;

      img {
        margin-right: 20px;
      }

      .epic-phase-input-area {
        text-align: center;
        position: relative;

        .infoMsg {
          color: #999;
          position: absolute;
          left: 0;
          font-size: 13px;
          bottom: -18px;
          line-height: 15px;
          font-style: italic;
        }

        p {
          margin-bottom: 8px;
          color: #9e9e9e;
          font-size: 14px;
        }

        input {
          color: #9e9e9e;
          font-size: 14px;
          border-color: #ccc;

          &:focus {
            box-shadow: none;
            border-color: #ccc;
          }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        h3 {
          color: #61ad31;
          font-size: 28px;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.epic-phase-block {
  border-top: solid 1px #e2e2e2;
  border-top: none;
  padding: 20px 0;

  .epicPahseAccBlock {
    width: 100%;

    .epicPahseListBlock {
      .epicPahseListRow {
        width: 100%;
        height: 70px;
        background-color: #fff;
        border: solid 1px #ccc;
        border-left: solid 7px #4fa399;
        @include border-radius(4px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        transition: all 0.2s ease-in-out;
        margin-bottom: 15px;

        .epicPahseListRowLeft {
          width: calc(100% - 400px);
        }

        .epicPahseListRowRight {
          width: 280px;
          margin-right: 30px;

          .epic-phase-input-area {
            text-align: center;

            p {
              margin-bottom: 2px;
              color: #9e9e9e;
              font-size: 14px;
            }

            h3 {
              color: #61ad31;
              font-size: 18px;
              font-family: $heading-font;
            }
          }
        }

        h3 {
          color: #333;
          font-size: 18px;
          font-weight: 500;
          padding: 0;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          margin: 0;
          font-family: $heading-font;
        }

        &.acc-arr-down {
          background-image: url("../../assets/images/downArrowPahse.png");
          background-position: 98% center;
          background-repeat: no-repeat;
          background-size: 25px;
        }

        &.acc-arr-up {
          background-image: url("../../assets/images/upArrowPahse.png");
          background-position: 98% center;
          background-size: 25px;
          background-repeat: no-repeat;
        }

        &:hover {
          background-color: #edf6f5;
        }

        &.active {
          background-color: #edf6f5;
        }
      }

      &.epicPahseListRow {
        .card {
          border: none;

          .collapse {
            margin-bottom: 15px;
            margin-top: -18px;
            padding-top: 18px;
            padding-bottom: 18px;
            border: solid 1px #ccc;
            border-left: solid 7px #4fa399;
            @include border-radius(0 0 5px 5px);
            border-top: none;

            .epic-phase-url {
              margin-bottom: 20px;

              input {
                background: #f4f4f4;
                border-color: #ccc;
                font-size: 14px;
                color: #444;

                &:focus {
                  box-shadow: none;
                  border-color: #ccc;
                }
              }

              button.reqAccess {
                background: #e9645d;
                color: #fff;
                border: none;
                font-size: 14px;
                @include border-radius(5px);
                padding: 7px 20px;
                margin-top: 30px;
                font-weight: 500;
                min-width: 170px;
                outline: none;
                cursor: pointer;
              }
            }

            p {
              font-size: 13px;
              margin: 0 0 6px;
              font-weight: 600;
              color: #555;
              line-height: 24px;
            }
          }
        }

        .ques-list-row {
          height: 65px;

          h3 {
            padding: 0;
          }

          ul.edit-del-list {
            padding: 0;
          }
        }
      }
    }
  }
}

.feed-all-phases {
  background: {
    image: url("../../assets/images/red-arrow.png");
    position: right center;
    color: transparent;
    repeat: no-repeat;
  }

  top: 3px;
  right: 0;
  z-index: 9;
  position: absolute;

  select {
    color: #888;
    font-size: 16px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    height: 30px !important;
    padding: 0 1rem;
    box-shadow: none !important;
    background-color: transparent !important;

    &:focus {
      color: #888;
    }
  }
}

.feed-all-phases {
  background: {
    image: url("../../assets/images/red-arrow.png");
    position: right center;
    color: transparent;
    repeat: no-repeat;
  }

  top: 3px;
  right: 0;
  z-index: 9;
  position: absolute;

  select {
    color: #888;
    font-size: 16px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    height: 30px !important;
    padding: 0 1rem;
    box-shadow: none !important;
    background-color: transparent !important;

    &:focus {
      color: #888;
    }
  }
}

.epic-all-urls.custom-radio {
  height: 25px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 9;

  label {
    margin: 0 20px 0 0;
    font-size: 12px;
  }

  .radio-checkmark {
    margin-right: 5px;
  }
}

.epic-listing-main {
  .epic-listing-header {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 8px;
    margin: 0;
    border-bottom: solid 1px #e2e2e2;

    li {
      width: 33.33%;
      color: #9e9e9e;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .epic-listing-content {

    // overflow: auto;
    // height: 337px;
    .epic-listing-content-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: solid 1px #e2e2e2;
      padding: 14px 0;

      .epic-listing-block {
        width: 33.33%;
        text-align: center;
        border-right: solid 1px #e2e2e2;
        padding: 20px 30px;
        color: #333;
        font-size: 18px;
        font-weight: 500;

        &:last-child {
          border: none;
        }

        .progress {
          height: 12px;
          margin-top: 9px;
          @include border-radius(15px);

          .progress-bar {
            font-size: 10px;

            &.not-started-bar {
              background: #d3d3d3;
            }

            &.ongoing-bar {
              background: #ffc200;
            }

            &.accepted-bar {
              background: #61ad31;
            }

            &.rejected-bar {
              background: #e9645d;
            }
          }
        }
      }
    }
  }

  &.phase-listing {
    ul.cancel-save-list {
      /*justify-content: flex-end;*/
      position: absolute;
      right: 15px;
      top: 0;
    }

    .epic-listing-header {
      li {
        // width: 25%;
        width: 33.33%;
      }
    }

    .epic-listing-content {
      // height: 311px;
      height: auto;
      overflow: visible;

      .epic-listing-content-row {
        .epic-listing-block {
          // width: 25%;
          width: 33.33%;
          position: relative;

          .invalid-feedback {
            position: absolute;
            bottom: 4px;
            margin: 0;
            left: 30px;
          }

          .progress {
            height: 12px;
            font-size: 10px;
            background: #ccc;
            margin-top: 0;
            @include border-radius(15px);

            .progress-bar {
              font-size: 10px;
              background: #4fa399;

              &.not-started-bar {
                background: #d3d3d3;
              }

              &.ongoing-bar {
                background: #ffc200;
              }

              &.accepted-bar {
                background: #61ad31;
              }

              &.rejected-bar {
                background: #e9645d;
              }
            }
          }
        }
      }
    }
  }
}

.noDataFound {
  p {
    font-size: 20px !important;
    font-weight: 400 !important;
    margin: 10px 0 !important;
  }
}

.invoice-tab .tab-content {
  height: calc(100vh - 179px);
}

.invoice-tab .nav-tabs {
  margin-bottom: 0;
}

.invoice-tab .tab-content .tab-pane {
  height: 100%;

  .no-invoice-block {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .no-invoice-con {
      width: 100%;
      text-align: center;
      position: relative;
      top: -20px;

      img {
        width: 60px;
      }

      p {
        width: 100%;
        margin: 1.5rem 0;
        font-size: 16px;
        color: #333;
        font-weight: 400;
      }
    }

    &.hide {
      display: none;
    }
  }

  .invoice-main-block {
    border: solid 1px #e2e2e2;
    border-top: none;
    padding: 10px 0;
    height: 100%;

    .invoice-top-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      >img {
        padding: 0 15px;
      }

      .invoice-inner-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        width: calc(33.33% - 6px);
        min-height: 123px;
        border: dashed 1px #ccc;
        @include border-radius(4px);
        position: relative;

        img {
          margin-right: 20px;
        }

        .depositAddedInPaid {
          /*position: absolute;
          bottom: 8px;
          left: 0;*/
          width: 100%;
          text-align: center;
          color: #9e9e9e;
          font-size: 12px;
        }

        p {
          margin-bottom: 8px;
          color: #9e9e9e;
          font-size: 14px;
          text-align: center;
          line-height: 30px;

          .cost-amount {
            color: #333;
            font-size: 20px;

            sub {
              font-size: 50%;
              bottom: 0;
              left: -5px;
            }

            &.paid-amm {
              color: #61ad31;
            }

            &.due-amm {
              color: #e9645d;
            }
          }
        }
      }
    }

    .invoice-table {
      position: relative;

      .checklist-common-list {
        .invoice-table-inner {
          .table-content {
            max-height: 360px;
            overflow: auto;
            height: auto;
          }

          .table-header {
            padding-right: 15px;
          }

          table {
            tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: solid 1px #ccc;

              td {
                border: none;
                width: 12.5%;

                .action-btn {
                  background: none;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  text-align: center;
                  margin-right: 5px;

                  i {
                    color: #e9645d;
                  }
                }

                .send-btn {
                  background: #e9645d;
                  border: none;
                  color: #fff;
                  padding: 5px 20px;
                  outline: none;
                  cursor: pointer;
                  @include border-radius(4px);
                }

                .payNowBtn {
                  background: #e9645d;
                  border: none;
                  color: #fff;
                  padding: 5px 20px;
                  outline: none;
                  cursor: pointer;
                  @include border-radius(4px);
                }

                .status-btn {
                  background: none;
                  border: none;
                  outline: none;
                  padding: 2px 10px;
                  min-width: 105px;
                  @include border-radius(20px);

                  &.status-received {
                    border: solid 1px #61ad31;
                    color: #61ad31;
                  }

                  &.status-pending {
                    border: solid 1px #c99e12;
                    color: #c99e12;
                  }

                  &.status-preauth {
                    border: solid 1px #c99e12;
                    color: #c99e12;
                  }

                  &.status-failed {
                    border: solid 1px #e9645d;
                    color: #e9645d;
                  }

                  &.status-partially-paid {
                    border: solid 1px #fcc200;
                    color: #fcc200;
                  }
                }

                &:first-child {
                  width: 5%;
                }

                &:nth-child(2) {
                  width: 12%;
                }

                &:nth-child(3) {
                  width: 14%;
                }

                &:nth-child(4) {
                  width: 15%;
                }

                &:nth-child(5) {
                  width: 12%;
                }

                &:nth-child(6) {
                  width: 13%;
                }

                &:nth-child(7) {
                  width: 15%;
                }

                &:last-child {
                  width: 17%;
                }
              }

              th {
                border: none;
                width: 12.5%;
                font-family: $heading-font;

                &:first-child {
                  width: 5%;
                }

                &:nth-child(2) {
                  width: 12%;
                }

                &:nth-child(3) {
                  width: 14%;
                }

                &:nth-child(4) {
                  width: 15%;
                }

                &:nth-child(5) {
                  width: 12%;
                }

                &:nth-child(6) {
                  width: 13%;
                }

                &:nth-child(7) {
                  width: 15%;
                }

                &:last-child {
                  width: 17%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.copy-clipboard {
  box-sizing: border-box;
  @include border-radius(2px);
  transition: opacity 0.15s ease-in-out;
  background-color: #4fa399;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  opacity: 0;
  padding: 0 10px;
  position: absolute;
  top: 10px;
  left: -50px;
  pointer-events: none;
  width: 185px;
  height: 28px;
  z-index: 40;

  &:before {
    content: "Link has been copied";
    position: absolute;
    top: 3px;
    left: 9px;
  }

  &:after {
    margin-top: 9px;
    margin-right: -2px;
    content: "";
    float: right;
    display: block;
    background-size: 11px 9px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/tiny-checkmark-icon.png");
    width: 11px;
    height: 9px;
  }
}

.copied .copy-clipboard {
  opacity: 1;
  pointer-events: auto;
}

.meetings {
  .add-task-block {
    .add-task-con {
      img {
        width: auto;
      }

      p {
        margin-top: 0;
      }
    }
  }

  .meetings-listing-tab {

    // height: 100%;
    .nav-tabs {
      .nav-item {
        &:first-child {
          padding-left: 1rem;
        }
      }
    }

    .tab-content {
      height: calc(100% - 40px);

      ul.tasks-queries-list.meetings-list {
        // overflow-x: auto;
        height: 100%;

        li {
          border-left: solid 3px transparent;
          align-items: center;

          img {
            margin-right: 6px;
          }

          .meetingTime {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            min-width: 85px;
          }

          .task-name-assigned {
            border-left: solid 1px #e1e1e1;
            padding-left: 8px;
            margin-left: 8px;
          }

          p {
            span {
              &.title {
                font-size: 14px;
              }

              &.blue {
                color: #6cb5d8;
              }

              &.green {
                color: #4fa399;
              }

              &.red {
                color: #e9645d;
              }
            }
          }

          &:hover {
            border-left: solid 3px #4fa399;
          }

          &.active {
            border-left: solid 3px #4fa399;
          }
        }
      }
    }
  }

  .task-detail-row {
    border-bottom: solid 1px #ccc;
    padding-bottom: 12px;
    margin-bottom: 12px;

    ul.list-inline li {
      display: inline-block;
      font-size: 13px;
      margin-left: 9px;
      color: #e9645d;
      padding-left: 9px;
      cursor: pointer;
      position: relative;

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        content: "";
        position: absolute;
        height: 12px;
        width: 1px;
        background: #e9645d;
        left: 0;
        top: 5px;
      }
    }

    ul.list-inline {
      margin-bottom: 0;
    }

    i.fa-download,
    fa-icon.fa-download {
      color: #e9645d;
      font-size: 14px;
      cursor: pointer;
      margin-left: 6px;
    }

    p {
      color: #777777;
      font-size: 12px;
      margin: 0;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        text-decoration: underline;
        color: #0645ad;
      }
    }

    .scheduled-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      p {
        color: #333;
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }

        span {
          color: #4fa399;
        }
      }
    }

    .meeting-attachment-row {
      display: flex;
      justify-content: flex-start;
    }

    ul.attendees-list {
      margin: 5px 0 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .custom-label {
          font-size: 12px;

          label {
            margin: 0;
          }
        }

        .custom-checkbox {
          position: relative;

          label {
            margin: 0;
          }

          .checkmark {
            left: -14px;
          }
        }

        .attd-nm-dp,
        .attd-ys-no {
          display: flex;
          align-items: center;
        }

        &:last-child {
          margin: 0;
        }

        a {
          font-size: 12px;
          margin: 0;
          text-decoration: underline;
        }

        img {
          margin-right: 12px;
        }
      }

      &.recordingKLinks {
        li {
          word-break: break-all;
        }
      }
    }

    &.notes {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &.b-none {
      border: none;
    }

    label {
      margin-bottom: 8px;
    }

    .clipboard {
      >a {
        display: inline-block;
        cursor: pointer;
        margin-left: 20px;
        color: #e9645d;
        font-size: 14px;
        text-decoration: none;
        position: relative;
      }
    }

    .copy-clipboard {
      box-sizing: border-box;
      @include border-radius(2px);
      transition: opacity 0.15s ease-in-out;
      background-color: #4fa399;
      color: #fff;
      font-size: 15px;
      line-height: 22px;
      opacity: 0;
      padding: 0 10px;
      position: absolute;
      top: -33px;
      left: 22px;
      pointer-events: none;
      width: 185px;
      height: 28px;
      z-index: 40;

      &:before {
        content: "Link has been copied";
        position: absolute;
        top: 3px;
        left: 9px;
      }

      &:after {
        margin-top: 9px;
        margin-right: -2px;
        content: "";
        float: right;
        display: block;
        background-size: 11px 9px;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/tiny-checkmark-icon.png");
        width: 11px;
        height: 9px;
      }
    }

    .copied .copy-clipboard {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .meetings-right-block {
    .todos-inner {
      height: calc(100% - 45px);
    }

    .meetings-right-header {
      h4 {
        font-size: 18px;
        color: #333;
        margin: 0;
        font-weight: 400;
        max-width: 450px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ul.cancel-save-list {
        &.renameTitleSave {
          display: none;
        }
      }

      input.title-edit-field {
        font-size: 18px;
        color: #333;
        background: transparent;
        border: none;
        outline: none;
        height: 21px;
        display: none;
      }
    }
  }
}

.meetings-modal {
  .time-dropdown {
    .select-box {
      background-position: 92% center;
    }

    ul.time-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;

      li {
        margin-right: 15px;
        position: relative;
        @include border-radius(4px);
        background-color: #f4f4f4;
        border: solid 1px #cccccc;
        padding: 10px 12px;
        font-size: 14px;
        color: #555;
        font-weight: 400;
        line-height: 18px;
        min-height: 38px;
        pointer-events: none;

        &:first-child {
          &:before {
            position: absolute;
            right: -10px;
            top: 10px;
            content: ":";
          }
        }

        &:last-child {
          &.time-hrs {
            background: #4fa399;
            border-color: #4fa399;
            color: #fff;
          }
        }
      }
    }

    button {
      outline: none;
      box-shadow: none;
      background: #4fa399;
      color: #fff;
    }

    .btn-link {
      .bs-chevron {
        border-color: #4fa399;
      }
    }

    .form-group {
      padding: 0;
    }
  }

  .meeting-duration {
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
    color: #4fa399;
    white-space: nowrap;
  }

  .added-mem-count {
    font-size: 12px;
    font-weight: 500;
    color: #4fa399;
    float: right;
    margin-top: 7px;
  }

  .memberList {
    margin-top: 5px;

    .viewAddedMem {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    ul.memberListing {
      margin: 12px 0 6px;

      li {
        background-color: #4fa399;
        color: #fefefe;
        margin-bottom: 8px;
        font-size: 13px;
        padding: 8px;
        font-weight: 400;
        margin-right: 12px;
        @include border-radius(4px);
        display: inline-flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-left: 10px;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .closeList {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.meeting-attachment {
  display: flex;
  justify-content: flex-start;

  .attached-file {
    display: flex;
    justify-content: flex-start;
    margin: 0 15px 0 0;
    align-items: center;

    .attach-file-info {
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      #doc-name {
        width: auto;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i,
      fa-icon {
        color: #333;
        margin-right: 6px;

        &.red {
          cursor: pointer;
          color: #e9645d;
          margin-left: 6px;
          margin-right: 0;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

a {
  cursor: pointer;
}

/*==========================Capacity Module style===========================*/

.partner-tab {
  margin-top: 0;

  .tab-content {
    height: calc(100vh - 217px);

    button.projectAllocateBtn {
      position: absolute;
      right: 0;
      top: -30px;
      background: transparent;
      border: none;
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      outline: none;

      i {
        margin-right: 4px;
      }
    }

    .partner-detail-block {
      height: 100%;
      padding-right: 6px;

      .view-features {
        position: absolute;
        right: 15px;
        top: 0;
        color: #e9645d;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
      }

      .col-lg-4 {
        padding-left: 15px;
        padding-right: 15px;
      }

      .add-b-border {
        h4 {
          border-width: 2px;
          border-color: #e2e2e2;
          margin: 0;
        }
      }

      .gray-box {
        margin-bottom: 25px;
      }

      .member-top-detail {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 10px;
        padding: 15px 0;
        border-bottom: solid 2px #e2e2e2;

        li {
          width: 25%;

          h5 {
            margin: 0 0 4px;
            font-size: 16px;
            color: #000030;
          }

          p {
            margin: 0 0 4px;
            font-size: 14px;
            color: #555;

            i,
            fa-icon {
              color: #e9645d;
              font-size: 11px;
              margin-right: 2px;

              &.green {
                color: #4fa399;
              }

              &.yellow {
                color: #c99e13;
              }
            }

            span.exp-item-wrp {
              color: #000030;
              position: relative;
              margin-left: 9px;
              padding-left: 9px;

              &:first-child {
                margin-left: 0;
                padding-left: 0;
              }

              &:first-child:before {
                display: none;
              }

              &:before {
                content: "";
                position: absolute;
                height: 20px;
                width: 1px;
                background: #ededed;
                left: 3px;
              }

              .prc-dtl {
                color: #555555;
              }
            }
          }
        }
      }

      ul.projects-list {
        margin: 0;

        li {
          @include border-radius(4px);
          background-color: #f4f4f4;
          border: solid 1px #cccccc;
          border-left: solid 9px #6cb5d8;
          min-height: 55px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          padding: 0 15px;

          p {
            margin: 0;
            font-size: 16px;
            color: #555;

            ~p {
              font-size: 14px;
              color: #333;

              span {
                color: #666;
              }
            }
          }
        }
      }

      .docList {
        margin-top: 12px;
        width: 100%;
        float: left;
      }
    }
  }

  .no-results-found {
    height: 100%;

    img {
      width: 52px;
    }
  }

  .partner-main-block {
    border-top: none;
    padding: 0 0 10px;
    height: 100%;

    .capacity-partner-table {
      position: relative;

      .checklist-common-list {
        .invoice-table-inner {
          .table-content {
            max-height: 480px;
            overflow: auto;
            height: auto;
          }

          .table-header {
            padding-right: 15px;
          }

          table {
            tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: solid 1px #ccc;
              background-color: #fff;

              td {
                border: none;
                width: 9%;
                min-height: 46px;
                padding: 13px;
                color: #333;

                &:first-child {
                  width: 14%;
                }

                &:nth-child(2) {
                  width: 14%;
                }

                .noPdf {
                  width: 60px;
                  display: inline-block;
                  text-align: center;
                }
              }

              th {
                border: none;
                width: 9%;
                min-height: 46px;
                padding: 13px;
                font-family: $heading-font;

                &:first-child {
                  width: 14%;
                }

                &:nth-child(2) {
                  width: 14%;
                }
              }

              &:hover {
                background-color: #edf6f5;
              }
            }

            thead {
              tr {
                &:hover {
                  background: transparent;
                }
              }
            }
          }

          &.cp-table {
            .table-header {
              padding-right: 0;
            }

            .table-content {
              max-height: none;
              overflow: auto;
            }
          }

          &.clickableTable {
            .table-body {
              tr {
                cursor: pointer;
              }
            }
          }

          &.recomm-table {
            .table-content {
              max-height: none;
              overflow: auto;
            }

            table {
              tr {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px #ccc;
                background-color: #fff;

                td {
                  border: none;
                  width: 10%;
                  min-height: 46px;
                  padding: 13px;
                  color: #333;

                  &:first-child {
                    width: 14%;
                  }

                  &:nth-child(2) {
                    width: 12%;
                  }

                  &:nth-last-child(2) {
                    width: 13%;
                  }

                  &:last-child {
                    width: 10%;
                  }
                }

                th {
                  border: none;
                  width: 10%;
                  min-height: 46px;
                  padding: 13px;
                  font-family: $heading-font;

                  &:first-child {
                    width: 14%;
                  }

                  &:nth-child(2) {
                    width: 12%;
                    position: relative;
                    overflow: visible;

                    .select-box {
                      width: 55px;
                      cursor: pointer;
                    }
                  }

                  &:nth-last-child(2) {
                    width: 13%;
                  }

                  &:last-child {
                    width: 10%;
                  }
                }

                &:hover {
                  background-color: #edf6f5;
                }

                .skill-block {
                  position: absolute;
                  width: 195px;
                  z-index: 9;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                  background-color: #ffffff;
                  border: solid 1px #cccccc;
                  @include border-radius(4px);
                  top: 47px;
                  left: 0;
                  padding: 10px;

                  .skill-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 6px;

                    p {
                      margin: 0;
                      font-size: 14px;
                      color: #9e9e9e;
                    }

                    i {
                      font-size: 14px;
                      color: #9e9e9e;
                      cursor: pointer;
                    }
                  }

                  ul.mutiple-assign-list {
                    margin: 0;
                    max-height: 155px;
                    overflow: auto;

                    li {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      position: relative;
                      margin-top: 5px;

                      .checkmark {
                        left: 0;
                        top: 3px;
                        border-color: #ccc;
                      }

                      .custom-checkbox {
                        input:checked~.checkmark {
                          background-color: #777;
                        }

                        label {
                          color: #555;
                        }
                      }
                    }
                  }
                }
              }

              thead {
                tr {
                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #partners-team {
    .partner-main-block {
      .capacity-partner-table {
        .checklist-common-list {
          .invoice-table-inner {
            table {
              tr {
                td {
                  width: 20%;
                  position: relative;

                  &:first-child {
                    width: 15%;
                  }

                  &:nth-child(3) {
                    width: 15%;
                  }

                  &:last-child {
                    width: 15%;

                    i {
                      color: #e9645d;
                      font-size: 11px;
                      margin-right: 2px;

                      &.green {
                        color: #4fa399;
                      }

                      &.yellow {
                        color: #c99e13;
                      }
                    }
                  }

                  .project-info {
                    position: absolute;
                    @include border-radius(2px);
                    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.25);
                    background-color: #fff;
                    z-index: 99;
                    padding: 15px;
                    top: 40px;
                    left: 8px;
                    width: 160px;
                    display: none;

                    &:after {
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid #fff;
                      content: "";
                      position: absolute;
                      top: -5px;
                      left: 3px;
                    }

                    p {
                      font-size: 14px;
                      color: #333;
                      margin: 0 0 4px;

                      &:last-child {
                        margin: 0;
                      }
                    }

                    &.small {
                      width: 80px;
                      padding: 10px;

                      p {
                        font-size: 10px;
                      }
                    }
                  }

                  &:hover .project-info {
                    display: none;
                  }
                }

                th {
                  width: 20%;

                  &:first-child {
                    width: 15%;
                  }

                  &:nth-child(2) {
                    position: relative;
                    overflow: visible;

                    i {
                      font-size: 10px;
                      position: relative;
                      top: -1px;
                    }
                  }

                  &:nth-child(3) {
                    width: 15%;
                    text-overflow: initial;
                  }

                  &:last-child {
                    width: 15%;
                  }

                  .select-box {
                    display: inline-block;

                    select {
                      border: none;
                      outline: none;
                      width: 100%;
                      height: auto !important;
                      padding-right: 20px;
                      padding-left: 3px;
                      color: #e9645d;
                    }
                  }
                }

                &:hover {
                  background-color: #edf6f5;
                }

                .skill-block {
                  position: absolute;
                  width: 195px;
                  z-index: 9;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                  background-color: #ffffff;
                  border: solid 1px #cccccc;
                  @include border-radius(4px);
                  top: 47px;
                  left: 0;
                  padding: 10px;

                  .skill-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                      margin: 0;
                      font-size: 12px;
                      color: #9e9e9e;
                    }

                    i {
                      font-size: 14px;
                      color: #9e9e9e;
                      cursor: pointer;
                    }
                  }

                  ul.mutiple-assign-list {
                    margin: 0;

                    li {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      position: relative;
                      margin-top: 5px;

                      .checkmark {
                        left: 0;
                        top: 3px;
                        border-color: #ccc;
                      }

                      .custom-checkbox {
                        input:checked~.checkmark {
                          background-color: #777;
                        }

                        label {
                          color: #555;
                        }
                      }
                    }
                  }
                }
              }

              thead {
                tr {
                  &:hover {
                    background: transparent;
                  }
                }
              }
            }

            &.team-table {
              .table-header {
                padding-right: 0;

                th {
                  width: 9%;
                  padding: 13px 10px;

                  &:first-child {
                    width: 14%;
                  }

                  &:nth-child(2) {
                    width: 10%;
                  }

                  &:nth-child(6) {
                    width: 12%;
                  }

                  &:nth-child(4),
                  &:nth-child(5) {
                    width: 9%;
                  }

                  &:nth-child(7),
                  &:nth-child(8) {
                    width: 12%;
                  }

                  &:nth-child(9) {
                    width: 10%;
                  }
                }
              }

              .table-content {
                &.scrollify {
                  &::-webkit-scrollbar {
                    width: 10px;
                  }

                  &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #888;
                  }

                  &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                  }

                  .table-body {
                    tr {
                      td {
                        &:nth-child(9) {
                          width: calc(10% - 10px);
                        }
                      }
                    }
                  }
                }

                .table-body {
                  tr {
                    cursor: pointer;

                    td {
                      width: 9%;
                      padding: 13px 10px;

                      &:first-child {
                        width: 14%;
                      }

                      &:nth-child(2) {
                        width: 10%;
                      }

                      &:nth-child(6) {
                        width: 12%;
                      }

                      &:nth-child(4),
                      &:nth-child(5) {
                        width: 9%;
                      }

                      &:nth-child(8),
                      &:nth-child(7) {
                        width: 12%;
                      }

                      &:nth-child(9) {
                        width: 10%;
                      }
                    }

                    .td-inner {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;

                      em {
                        font-style: normal;
                        margin-right: 3px;
                      }

                      p {
                        margin: 0;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                        white-space: nowrap;
                        overflow: hidden;

                        &:first-child {
                          margin-bottom: 5px;
                        }

                        &:last-child {
                          margin-bottom: 0;
                        }
                      }

                      .currentAllocation {
                        span {
                          color: #9e9e9e;
                          font-style: italic;
                          font-size: 12px;
                          margin: -3px 0 0;
                          line-height: 16px;
                          display: inline-block;
                          max-width: 85px;
                          text-overflow: ellipsis;
                          overflow: hidden;
                          &.no-italic {
                            font-style: normal;
                          }                  
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #project-allocation {
    .partner-main-block {
      .capacity-partner-table {
        .checklist-common-list {
          .invoice-table-inner {
            table {
              tr {
                td {
                  width: 10%;
                  position: relative;

                  &:first-child {
                    width: 12%;
                    max-width: 150px;
                  }

                  &:nth-child(5) {
                    width: 12%;
                  }

                  &:nth-child(6) {
                    width: 8%;
                  }

                  &:nth-child(7) {
                    width: 7%;
                  }

                  &:nth-child(8) {
                    width: 13%;
                  }

                  &:nth-child(10) {
                    .status-btn {
                      background: none;
                      border: none;
                      outline: none;
                      padding: 2px 10px;
                      min-width: 80px;
                      @include border-radius(20px);
                      cursor: pointer;

                      &.status-approved {
                        border: solid 1px #61ad31;
                        color: #61ad31;
                      }

                      &.status-rejected {
                        border: solid 1px #e9645d;
                        color: #e9645d;
                      }

                      &.status-pending {
                        border: solid 1px #c99e12;
                        color: #c99e12;
                      }
                    }
                  }

                  &:last-child {
                    width: 7%;

                    i {
                      color: #e9645d;
                      font-size: 14px;
                      font-weight: 400;
                      cursor: pointer;
                      margin-right: 8px;

                      &:last-child {
                        margin: 0;
                      }
                    }
                  }
                }

                th {
                  width: 10%;

                  &:first-child {
                    width: 12%;
                  }

                  &:nth-child(5) {
                    width: 12%;
                  }

                  &:nth-child(6) {
                    width: 8%;
                  }

                  &:nth-child(7) {
                    width: 7%;
                  }

                  &:nth-child(8) {
                    width: 13%;
                  }

                  &:last-child {
                    width: 7%;
                  }
                }

                &:hover {
                  background-color: #edf6f5;
                }
              }

              thead {
                tr {
                  &:hover {
                    background: transparent;
                  }
                }
              }
            }

            .table-header {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

.allocate-project-modal {
  .skills-count {
    font-size: 12px;
    font-weight: 500;
    color: #4fa399;
    float: right;
    margin-top: 7px;
  }

  .skillList {
    margin-top: 5px;

    .viewAddedSkills {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    ul.skillListing {
      margin: 12px 0 6px;

      li {
        background-color: #4fa399;
        color: #fefefe;
        margin-bottom: 8px;
        font-size: 13px;
        padding: 8px;
        font-weight: 400;
        margin-right: 12px;
        @include border-radius(4px);
        display: inline-flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-left: 10px;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .closeList {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .costType {
    input[type="text"] {
      background-color: #f4f4f4;
      cursor: default;
      pointer-events: none;
    }

    .custom-radio {
      margin-left: 3px;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey-box {
  background-color: #f4f4f4;
  pointer-events: none;
}

.custom-phase-modal {
  .custom-radio {
    height: 38px;
    align-items: center;
    display: flex;
    padding-left: 2px;

    label {
      margin: 0 30px 0 0;
    }

    .radio-checkmark {
      margin-right: 5px;
    }
  }

  .time-dropdown {
    button {
      outline: none;
      box-shadow: none;
      background: #4fa399;
      color: #fff;
    }

    .btn-link {
      .bs-chevron {
        border-color: #4fa399;
      }
    }

    .form-group {
      padding: 0;
    }
  }

  .meeting-duration {
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
    color: #4fa399;
    white-space: nowrap;
  }

  .added-mem-count {
    font-size: 12px;
    font-weight: 500;
    color: #4fa399;
    float: right;
    margin-top: 7px;
  }

  .memberList {
    margin-top: 5px;

    .viewAddedMem {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    ul.memberListing {
      margin: 12px 0 6px;

      li {
        background-color: #4fa399;
        color: #fefefe;
        margin-bottom: 8px;
        font-size: 13px;
        padding: 8px;
        font-weight: 400;
        margin-right: 12px;
        @include border-radius(4px);
        display: table;
        align-items: center;

        i {
          font-size: 20px;
          margin-left: 10px;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .closeList {
      color: #e9645d;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  input.form-control,
  textarea.form-control,
  select.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
  }

  .mydp,
  .mydp .headertodaybtn {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
  }
}

.addEqalSymbol {
  font-size: 30px;
  color: #666;
  text-align: center;
  margin-top: 34px;
}

.mydp {
  .selectedday {
    .datevalue {
      background-color: transparent !important;
    }
  }

  table.header {
    tr {
      float: none;
      display: inline-block;
      width: auto;
      padding: 0;
      background: transparent !important;

      td {
        width: auto !important;
        min-height: 1px !important;
        padding: 0 !important;
      }
    }
  }

  table.caltable {
    tr {
      float: none;
      width: auto;
      padding: 0;
      background: transparent !important;
      display: table-row;

      td {
        width: auto !important;
        min-height: 1px !important;
        line-height: 7px;
      }
    }
  }
}

.basic-info-module {
  .project-success-msg {
    position: fixed;
    width: 70%;
    top: 80px;
    left: 15%;
    z-index: 99999;
  }
}

.kickoffDateError {
  left: 15px;
  position: absolute;
  font-size: 13px;
  line-height: 16px;
  color: #ff0000;
  top: 70px;
  display: none;
}

.invoice-detail {
  h3 {
    margin-bottom: 2rem;
  }

  button {
    outline: none;
  }

  .transaction-detail-table {
    .table-header {
      margin-bottom: 15px;
    }

    .table-content {
      max-height: 400px;
      overflow: auto;
      height: auto;

      tr {
        border-bottom: solid 1px #ccc;
      }
    }

    table {
      width: 100%;

      tr {
        td {
          border: none;
          width: 16.66%;
          font-size: 14px;
          color: #333;
          font-weight: 400;
          padding: 12px 0;

          .status-btn {
            background: none;
            border: none;
            outline: none;
            padding: 2px 10px;
            min-width: 105px;
            @include border-radius(20px);

            &.status-received {
              border: solid 1px #61ad31;
              color: #61ad31;
            }

            &.status-pending {
              border: solid 1px #c99e12;
              color: #c99e12;
            }

            &.status-failed {
              border: solid 1px #e9645d;
              color: #e9645d;
            }

            &.status-partially-paid {
              border: solid 1px #fcc200;
              color: #fcc200;
            }
          }

          &:first-child {
            width: 10.66%;
          }

          &:nth-child(2) {
            width: 20.66%;
          }

          &:nth-child(3) {
            width: 14.66%;
          }

          &:nth-child(4) {
            width: 20.66%;
          }

          &:last-child {
            width: 16.66%;
          }
        }

        th {
          border: none;
          width: 16.66%;
          font-size: 14px;
          color: #9e9e9e;
          font-weight: 400;

          &:first-child {
            width: 10.66%;
          }

          &:nth-child(2) {
            width: 20.66%;
          }

          &:nth-child(3) {
            width: 14.66%;
          }

          &:nth-child(4) {
            width: 20.66%;
          }

          &:last-child {
            width: 16.66%;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.repo-tab-main {
  h3 {
    font-size: 16px;
    color: #777;
    font-weight: 400;
    margin: 0;
    padding: 10px 0;
  }

  .topAddUploadfile {
    top: 48px;
  }

  .repo-tab {
    margin-top: 0;

    .tab-content {
      height: calc(100vh - 188px);
    }
  }
}

.hideForm {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.7; //opacity: 0.4;
}

.pagination {
  justify-content: center;
  margin: 20px 0 10px;

  .page-link {
    color: #e9645d;
  }

  .page-item {
    &.active {
      .page-link {
        background-color: #37756e;
        border-color: #37756e;
      }
    }
  }
}

.pagination-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.date-picker-new {
  .mydp {
    height: 40px;

    .selectiongroup {
      height: 38px;

      input {
        height: 38px !important;
      }
    }

    .selection {
      background: transparent;

      &.selectiondisabled {
        background-color: #f4f4f4;
      }
    }

    .btnclear,
    .btnpicker {
      background: transparent;
    }
  }
}

.addQuesPopup,
.editQuesPopup {
  .ngx-dropdown-container {
    .ngx-dropdown-button {
      font-size: 14px;
      color: #555 !important;
      font-weight: 400 !important;
      line-height: 1.9 !important;
      border-color: #ccc !important;
      outline: none;
      height: 40px;
      background-color: transparent !important;

      background: {
        image: url("../../assets/images/down-arrow.png") !important;
        position: 97% center !important;
        repeat: no-repeat !important;
      }
    }
  }
}

.feature-list-block {
  float: left;
  width: 100%;

  .feature-list-search {
    background-color: rgba(155, 155, 155, 0.1);
    @include border-radius(4px);
    height: 40px;
    min-width: 220px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px #dadada;
    padding: 0 6px;
    margin: 12px 20px 15px 0;

    input[type='submit'] {
      width: 13px;
      height: 13px;
      background-image: url("../../assets/images/search-icon.png");
      background-repeat: no-repeat;
      background-size: 13px 13px;
      background-position: center center;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: xx-large;
      font-size: -webkit-xxx-large;
    }

    input[type='text'] {
      width: 100%;
      margin-left: 6px;
      font-size: 14px;
      font-weight: 400;
      color: #555;
      height: 24px;
      background: transparent;
      border: none;
      outline: none;
    }
  }

  .no-results-found {
    position: absolute;
    width: 100%;
    height: calc(100% - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 100px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: #333;
      margin-bottom: 0;
    }

    &.hide {
      display: none;
    }
  }

  .feature-bucket {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .feature-bucket-block {
      width: calc(20% - 16px);
      border: solid 1px #ccc;
      @include border-radius(4px);
      margin: 0 10px 20px;
      text-align: center;
      padding: 20px 6px;
      cursor: default;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #edf6f5;
      }

      p {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;
        margin: 0;
        font-family: $heading-font;
      }

      span {
        color: #777;
        font-size: 16px;
        font-weight: 500;
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    }
  }
}

.feature-ques-block {
  width: 100%;

  .features-breadcrum {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      color: #9e9e9e;
      font-size: 14px;
      font-weight: 500;
      padding: 0 10px;
      cursor: pointer;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        color: #111;
        cursor: text;
        padding-right: 0;
      }
    }
  }

  .ques-list-block {
    .ques-list-row {
      width: 100%;
      height: 80px;
      background: #fff;
      border: solid 1px #ccc;
      border-left: solid 7px #4fa399;
      @include border-radius(4px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      transition: all 0.2s ease-in-out;
      margin-bottom: 15px;

      .ques-list-row-left {
        width: calc(100% - 460px);
      }

      .ques-list-row-right {
        width: 450px;

        ul.ques-listing-con {
          justify-content: flex-end;
        }
      }

      h3 {
        color: #333;
        font-size: 18px;
        font-weight: 500;
        padding: 0 0 3px;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: $heading-font;
      }

      ul.ques-listing-con {
        display: flex;
        margin: 0;

        li {
          color: #777;
          font-size: 14px;
          font-weight: 400;
          padding: 0 5px;

          strong {
            color: #333;
            font-weight: 500;
            font-family: $heading-font;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      ul.edit-del-list {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0 0 6px;

        li {
          margin-right: 20px;
          color: #e9645d;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          i {
            margin-right: 4px;
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }

      &:hover {
        background: #edf6f5;
      }

      &.active {
        background: #edf6f5;
      }
    }

    &.quick-tips-listing {
      .card {
        border: none;

        .collapse {
          margin-bottom: 15px;
          margin-top: -18px;
          padding-top: 18px;
          padding-bottom: 18px;
          border: solid 1px #ccc;
          border-left: solid 7px #4fa399;
          @include border-radius(0 0 5px 5px);
          border-top: none;

          p {
            font-size: 14px;
            font-weight: 400;
            color: #777;
            line-height: 24px;
          }

          .listing-attachment {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            label {
              font-size: 12px;
              font-weight: 600;
              margin: 0 10px 0 0;
              color: #333;
            }

            .attached-file {
              display: flex;
              justify-content: flex-start;
              margin: 0;
              align-items: center;
              width: calc(100% - 270px);
              flex-wrap: wrap;

              .attach-file-info {
                font-size: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 15px;
                margin-bottom: 5px;

                .doc-name {
                  width: auto;
                  overflow: hidden;
                  display: inline-block;
                  vertical-align: middle;
                  max-width: 100px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                i,
                fa-icon {
                  color: #333;
                  margin-right: 6px;

                  &.red {
                    cursor: pointer;
                    color: #e9645d;
                    margin-left: 8px;
                    margin-right: 0;
                    position: relative;
                    top: 1px;
                  }
                }
              }
            }

            .created-by {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: auto;

              p {
                margin: 0;
                font-size: 12px;
                color: #333;
                line-height: 18px;
              }
            }
          }
        }
      }

      .ques-list-row {
        height: 65px;

        h3 {
          padding: 0;
        }

        ul.edit-del-list {
          padding: 0;
        }
      }
    }
  }
}

.answer-bottom-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 10px;
  flex-wrap: wrap;

  ul.ques-listing-con {
    display: flex;
    margin: 5px 0;

    li {
      color: #777;
      font-size: 14px;
      font-weight: 400;
      padding: 0 5px;

      strong {
        color: #333;
        font-weight: 500;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.quick-tips-tab {
  .add-folder-block {
    .add-folder-con {
      img {
        width: auto;
      }
    }
  }
}

.view-live-links {
  h3 {
    font-size: 14px;
    color: #333;
    border-bottom: solid 2px #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: block;
    font-weight: 600;
  }

  ul.liveLinksEdit {
    position: absolute;
    right: 15px;
    top: -2px;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 20px;

      a {
        text-decoration: none;
        font-size: 14px;
        color: #e9645d !important;
        font-weight: 400;
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    em {
      margin-right: 3px;
    }
  }

  .live-links-row {
    margin-bottom: 30px;
    max-width: 85%;

    .plateform-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      img {
        margin-right: 12px;
        max-width: 35px;
        max-height: 50px;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }

      h4 {
        color: #777;
        font-weight: 700;
        font-size: 13px;
        margin: 0;
      }
    }

    .form-group {
      position: relative;

      label {
        color: #777;
        font-weight: 400;
        font-size: 13px;

        span {
          color: #f32121;
        }
      }

      input[type='text'],
      input[type='password'] {
        background-color: transparent;
        box-shadow: none;
        color: #555;
        font-size: 14px;
        font-weight: 400;
        height: 40px;

        &:focus {
          border-color: #ccc;
        }
      }

      input[type='text'] {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #2349c7;
        text-decoration: underline;
      }

      input.deck-url {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #2349c7;
        text-decoration: underline;
        height: 40px;
      }

      .show-password {
        position: absolute;
        right: 25px;
        bottom: 13px;
        cursor: pointer;
        color: #ccc;
      }

      ul.urlCopyList {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: -30px;

        li {
          padding: 0 15px;
          border-left: solid 1px #dcdcdc;

          a {
            color: #e9645d;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;

            em {
              margin-right: 6px;
            }

            img {
              margin-right: 6px;
              position: relative;
              top: -2px;
            }

            .copy-clipboard {
              top: -40px;
              left: -70px;
            }
          }

          &:first-child {
            border: none;
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        &.admin-copy {
          bottom: auto;
          top: 1px;
        }
      }

      .custom-checkbox {
        label {
          font-size: 14px;
          color: #333;
          font-weight: 700;
        }

        .checkmark {
          left: 0;
        }
      }

      &.user-pwd {

        input[type='text'],
        input[type='password'] {
          color: #555;
          text-decoration: none;
        }
      }

      .invalid-feedback {
        font-size: 13px;
        font-weight: 700;
        color: #ed1c24;
      }

      input.dirtyField {
        border-color: #ed1c24 !important;
      }
    }
  }

  .portfolio-deck-row {
    margin-bottom: 30px;
    max-width: 85%;

    .form-group {
      position: relative;

      label {
        color: #777;
        font-weight: 700;
        font-size: 13px;
      }

      input.deck-url {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #2349c7;
        text-decoration: underline;
        height: 40px;
        box-shadow: none;
        font-size: 14px;
        background-color: transparent;

        &:focus {
          &:focus {
            border-color: #ccc;
          }
        }
      }

      ul.urlCopyList {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 1px;

        li {
          padding: 0 15px;
          border-left: solid 1px #dcdcdc;

          a {
            color: #e9645d;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;

            em {
              margin-right: 6px;
            }

            img {
              margin-right: 6px;
              position: relative;
              top: -2px;
            }

            .copy-clipboard {
              top: -40px;
              left: -70px;
            }
          }

          &:first-child {
            border: none;
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      .invalid-feedback {
        font-size: 13px;
        font-weight: 700;
        color: #ed1c24;
      }

      input.dirtyField {
        border-color: #ed1c24 !important;
      }
    }
  }
}

.error-screen {
  background-color: #fff;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  padding: 50px 0 40px;
  display: flex;
  justify-content: center;
  z-index: 9;
  align-items: center;
  width: 100%;
  text-align: center;

  h1 {
    color: #333;
    font-size: 36px;
    font-weight: 500;
    margin: 15px 0;
  }

  h4 {
    color: #4fa399;
    font-size: 16px;
    font-weight: 700;
    margin: 12px 0 0;
  }

  p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  button {
    background-color: #e9645d;
    @include border-radius(4px);
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.divShow {
  display: block !important;
}

.divHide {
  display: none !important;
}

th,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font !important;
  font-weight: 500 !important;
}

/**attatch project ui**/
.with-upload-editor {
  li {
    flex-wrap: wrap;
  }
}

.note-collapser {
  width: 100%;
  margin-top: 17px;

  .lf-rh-align {
    justify-content: space-between;
    margin-top: 10px;

    .attach-file {
      margin-top: 0;
    }

    .btn {
      background: #e9645d;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      line-height: 30px;
      padding: 0 20px;
      margin: 0 0 0 15px;
      outline: none;
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    font-size: 12px;
    color: #555;
    padding: 15px;
    border-radius: 4px;
    border: solid 1px #cccccc;
    display: block;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 1.4;

    &:focus {
      outline: none;
      border-color: #4fa399;
    }
  }
}

.textarea-uploader-wrp {
  border: 1px solid #c0c3ce;
  border-top: none;
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
  // min-height: 3px;
  padding-top: 0px;
  border-bottom: none;

  .item-upload-wrp {
    padding: 0;
  }

  .uploaded-item {
    vertical-align: top;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-file-name {
      font-size: 10px;
      color: #676b7e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px;
      width: 212px;
      display: flex;
      align-items: center;
      background: #f8f9fa;
      border-radius: 3px;
      justify-content: space-between;
      padding: 8px 10px;

      span.file-name {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .uploaded-item-inner {
      width: 107px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;

      &:hover {
        border-color: transparent;
      }

      .circle-prog-wrp {
        position: relative;

        &:before {
          content: "";
          display: inline-block;
          background: rgba(0, 0, 0, 0.58);
          border-radius: 100%;
          width: 52.5px;
          position: absolute;
          height: 52.8px;
          left: 2px;
          top: -19px;
          opacity: 0;
        }

        .rm-upload {
          position: absolute;
          top: 0px;
          left: 20px;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
          opacity: 0;
        }

        &:hover {

          .rm-upload,
          &:before {
            opacity: 1;
          }
        }
      }

      .uploading-text {
        color: #4fa399;
        text-align: center;
        font-size: 9px;
        font-weight: 600;
      }

      .screenshot-img {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.58);
          width: 100%;
          position: absolute;
          height: 100%;
          left: 0px;
          top: 0px;
          opacity: 0;
        }

        img {
          max-width: 100%;
        }

        .remove-download-icon {
          position: absolute;
          top: 50%;
          color: #fff;
          left: 50%;
          width: 100%;
          font-size: 13px;
          transform: translate(-50%, -50%);
          text-align: center;

          em {
            opacity: 0;
            cursor: pointer;

            &:first-child {
              margin-right: 18px;
            }
          }
        }

        &:hover {

          em,
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}

.textarea-uploader-wrp.doc-browser {
  border-top: 1px solid #c0c3ce;
  border-bottom: 1px solid #c0c3ce;
  border-radius: 0.25rem;
}

.form-group span.browse-placeholder-validate {
  font-size: 12px;
  position: relative;
  top: 2px;
}

textarea.editable-area:focus+.textarea-uploader-wrp {
  border-color: #4fa399;
}

.note-collasper-opener {
  position: absolute;
  right: 0;
  top: 21px;
  font-size: 14px;
  font-weight: 500;
  color: #e9645d;
  cursor: pointer;

  em {
    font-weight: normal;
    padding-right: 6px;
  }
}

.email-table-help {
  display: block;
  font-size: 12px;
  color: #757373;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #4fa399;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4fa399;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 28px;
}

.slider.round:before {
  border-radius: 50%;
}

span.yes-label,
span.no-label {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
}

label.switch {
  margin-left: 10px;
  margin-right: 10px;
}

#international_number {
  display: inline-block;

  input {
    display: none;
  }

  .scrollable-menu {
    width: 270px;

    .country-name {
      font-size: 13px;
    }
  }
}

div.input-val {
  display: inline-block;
  border: 1px solid #ccc;
  height: 38px;
  vertical-align: top;
  padding: 6px 11px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  width: 65px;
  font-size: 14px;
}

.ng-draggable {
  position: absolute;
  z-index: 1000;
  right: 40px;
  bottom: 60px;
  cursor: grab;

  img {
    width: 60px;
  }
}

.msgTooltip {
  width: auto;
  min-width: 180px;
  min-height: 40px;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ccc;
  background-color: #fff;
  position: absolute;
  right: -8px;
  top: 29px;
  cursor: default;
  align-items: center;
  justify-content: center;
  @include border-radius(3px);
  z-index: 9;
  display: none;

  .triangle {
    width: 50px;
    height: 22px;
    position: absolute;
    overflow: hidden;
    top: -22px;
    right: 3px;
    box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
  }

  .triangle:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background: #fff;
    transform: rotate(45deg);
    top: 19px;
    left: 25px;
    border: solid 1px #ccc;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
  }

  p {
    font-size: 14px;
    color: #555;
    margin: 0;
    font-weight: 400;
  }
}

.request-tab-wrp {
  position: relative;

  .msgTooltip-wrp {
    position: relative;
    top: -1px;
    display: none;

    &.no-skills {
      right: auto;
      left: 0;
      width: 300px;
      position: absolute;
      top: -24px;

      .triangle {
        right: auto;
        left: 78px;
        top: 27px;
      }

      .msgTooltip {
        left: 0;
        right: auto;
        padding: 3px 15px;
      }
    }

    &.active-state {
      display: block;
    }

    .triangle {
      width: 50px;
      height: 22px;
      position: absolute;
      overflow: hidden;
      top: -2px;
      transform: rotate(180deg);
      right: -20px;
      box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);

      &:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background: #fff;
        transform: rotate(45deg);
        top: 19px;
        left: 25px;
        border: solid 1px #ccc;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
      }
    }

    .msgTooltip {
      top: -43px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      em {
        position: absolute;
        right: 0;
        top: 33px;
        color: #fff;
      }
    }
  }
}

/**Manager status styling**/
div.item-status-manager {
  display: inline-block;
  vertical-align: middle;
  color: #212121;
  position: relative;
  padding-left: 18px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
  }

  &.available-status {
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #2eb52e;
    }
  }

  &.archive-status {
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #e9645d;
    }
  }

  &.not-available-status {
    &:before {
      content: "";
      background-image: url("../../assets/images/not-available-icon.png");
      width: 13px;
      height: 14px;
      background-size: 13px 14px;
      top: 2px;
    }
  }

  &.do-not-disturb-status {
    &:before {
      content: "";
      background-image: url("../../assets/images/do-not-disturb.png");
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
    }
  }
}

.manager-view-status {
  .item-status-manager {
    display: inline-block;
    padding-left: 15px;
    cursor: initial;

    &.available-status:before {
      width: 10px;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.archive-status:before {
      width: 10px;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.not-available-status:before {
      width: 11px;
      height: 12px;
      background-size: 11px 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.do-not-disturb-status:before {
      width: 11px;
      height: 11px;
      background-size: 11px 11px;
      top: 3px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.never-logged {
      color: #e9655e;

      button.send-invite {
        background-color: #e9645d;
        font-size: 8px;
        color: #fff;
        font-weight: 500;
        font-style: normal;
        box-shadow: none !important;
      }
    }
  }
}

.manger-detail-status {
  @extend .manager-view-status;

  &.list-inline {
    margin-bottom: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;

      &.badge-label {
        color: #777;
        padding-right: 0;

        &:before {
          content: "";
          height: 18px;
          width: 1px;
          display: inline-block;
          vertical-align: middle;
          background: #e2e2e2;
          margin-right: 15px;
        }
      }

      &.badge-stl {
        margin-left: 10px;
        background: #fff;
        padding: 2px 7px;
        margin-right: 21px;
        border-radius: 2px;
        color: #555;

        div.item-status-manager {
          padding-left: 15px;

          &.do-not-disturb-status:before {
            width: 10px;
            height: 10px;
            background-size: 10px 10px;
          }

          &.never-logged {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.spec-calls-page {
  .right-content {
    &.light-grey-bg {
      background: #f8f9fa;
    }
  }

  .spec-past-row {
    margin-top: 25px;
  }

  .projects-tab {
    .nav-tabs {
      margin-bottom: 13px;
    }
  }

  .spec-heading {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
    padding: 0 10px;
    margin-bottom: 11px;
  }

  .hidden-spec-item {
    width: 100%;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .view-hide-wrp {
    text-align: right;
    width: 100%;
    padding: 0 10px;
    margin-top: 0px;

    .grp_sh_hd {
      color: #000;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      border-bottom: 2px solid #00d659;
      display: inline-block;

      em {
        font-size: 9px;
        margin-left: 5px;
        vertical-align: middle;

        &.fa-chevron-up {
          vertical-align: 1px;
        }
      }
    }
  }
}

.zoho-integration-wrp {
  &.timeslots {
    .cmn-datepicker-wrp {
      input {
        height: 36px;
        width: 334px;
        pointer-events: none;
        background: #f4f4f4;
      }

      .myDpSelectorAbsolute {
        left: 104% !important;
        top: -46px !important;
      }

      .myDpSelectorArrowLeft:after,
      .myDpSelectorArrowLeft:before {
        left: -12px;
        transform: rotate(-90deg);
        top: 49px;
      }

      .myDpSelectorArrow:after {
        border-width: 11px;
        margin-left: -10px;
      }
    }

    .time-slots-group>div.slot-repeat-item:last-child {
      .form-group {
        margin-bottom: 0;
      }
    }

    .slot-repeat-item {
      display: flex;
      align-items: center;

      &.disabled-item {
        .select-box {
          background-color: #f4f4f4;
          pointer-events: none;
        }
      }

      .separator-dash {
        color: #777;
        align-self: flex-start;
        position: relative;
        margin: 0 15px;
        top: 4px;
      }

      .savebtn-icon {
        align-self: flex-start;
        margin-left: 15px;

        .btn-wrp button.btn {
          vertical-align: top;
          width: 70px;
          height: 41px;
          background: #64a199;
          color: #fff;
          font-size: 14px;
        }
      }

      .select-box {
        select {
          width: 165px;
          height: 41px !important;
        }
      }
    }

    .icon-set {
      align-self: flex-start;
      position: relative;
      top: 10px;
    }

    .cal-icon {
      cursor: pointer;
    }

    .icon-set em {
      margin-left: 15px;
      cursor: pointer;

      &.pmifpencil {
        position: relative;
        top: -2px;
      }
    }
  }

  .top-title {
    img {
      max-width: 24px;
      margin-right: 5px;
    }

    em {
      font-size: 18px;
      margin-right: 5px;
      color: #65a199;
    }
  }

  .int-main {
    margin-top: 29px;

    .list-inline {
      li {
        display: inline-block;
        vertical-align: top;

        &:first-child {
          padding-top: 14px;

          label {
            margin: 0;
            min-width: 170px;
          }
        }

        &:last-child {
          padding-top: 7px;
        }

        .filled-input-wrp {
          .cmn-datepicker-wrp {
            &:before {
              display: none;
            }
          }

          margin-left: 22px;
          margin-right: 13px;

          &.zoom-connect {
            .filled-input-helper {
              display: none;

              &.connect-helper {
                display: block;
              }
            }

            .filled-input {
              .default-view {
                display: none;
              }

              .connect-and-not {
                display: block;
              }
            }
          }

          &.zoom-not-connect {
            .filled-input-helper {
              display: none;

              &.not-connect-helper {
                display: block;
                font-weight: 500;
              }
            }

            .filled-input {
              border-color: #fa0000;
              background: #fee5e5;

              .default-view {
                display: none;
              }

              .connect-and-not {
                display: block;
              }
            }
          }

          .filled-input {
            width: 370px;
            margin-bottom: 0;
            color: #212121;

            .connect-and-not {
              display: none;
            }

            span {
              opacity: 0.37;
            }

            .close-icon {
              float: right;
              font-size: 18px;
              margin-top: 3px;
              cursor: pointer;
            }

            img {
              &.zoom-main-img {
                max-width: 68px;
              }

              max-width: 122px;
            }
          }

          .filled-input-helper {
            margin-top: 4px;

            &.disabled-slot {
              a {
                cursor: default;

                span {
                  opacity: 0.6;
                }
              }
            }

            a {
              display: block;
              font-size: 12px;
              color: #777;
              text-decoration: none;

              span {
                color: #4fa399;
                font-weight: 500;
              }
            }

            &.not-connect-helper {
              font-size: 12px;
              color: #fa0000;
              display: none;
              max-width: 370px;
            }

            &.connect-helper {
              display: none;
            }
          }
        }

        .info-part-zoom {
          position: relative;

          &:hover {
            .msgTooltip-wrp {
              display: block;
            }
          }

          .triangle {
            width: 50px;
            height: 22px;
            position: absolute;
            overflow: hidden;
            top: 70px;
            left: -36px;
            box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0);
            transform: rotate(-90deg);

            &:after {
              content: "";
              position: absolute;
              width: 25px;
              height: 25px;
              background: #fff;
              transform: rotate(45deg);
              top: 19px;
              left: 25px;
              border: solid 1px #ccc;
              box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
            }
          }

          .info-icon {
            &.cal-icon {
              font-size: 15px;
            }

            font-size: 20px;
            color: #555;
            vertical-align: middle;
          }

          .msgTooltip-wrp {
            cursor: pointer;
            position: relative;
            top: -80px;
            right: -30px;
            min-width: 375px;
            display: none;

            .msgTooltip {
              min-width: 100%;
              padding: 10px;
              display: block;

              p {
                font-weight: 500;
              }

              ul {
                margin: 0;
                list-style: none;

                li {
                  &:first-child {
                    padding-top: 5px;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              p,
              li {
                font-size: 13px;
                color: #555;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}

/**Feature Grouping**/
.projects-detail {
  .team-allocation {
    .feature-grouping-wrap {
      margin-bottom: 17px;

      h3 {
        color: #000;
        border: none;
        margin-bottom: 0;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 0px;
      }

      .feature-group-item {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #ccc;
        min-height: 140px;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        padding: 6px;
        background: url('../../assets/images/featuregroup-bg.png') left top no-repeat #fff;
        background-size: 101% 44px;
        cursor: pointer;

        .feature-group-heading {
          color: #ffffff;
          line-height: 1.3;
          font-size: 15px;
          font-weight: 500;
          padding-top: 2px;
          padding-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          top: -1px;
          position: relative;
        }

        .feature-wid-count {
          margin-bottom: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 3px;

          .feature-count-circle {
            width: 52px;
            height: 52px;
            background: rgba(79, 163, 153, 0.2);
            border-radius: 100%;
            color: #fff;
            text-align: center;
            font-size: 20px;
            display: flex;
            justify-content: center;

            .inner-circle-feature {
              background: #4fa399;
              width: 42px;
              height: 42px;
              border-radius: 100%;
              text-align: center;
              margin: 0 auto;
              line-height: 42px;
              margin-top: 5px;
            }
          }

          .feature-text {
            font-size: 12px;
            line-height: 2;
            color: #111;
            padding-left: 8px;
            margin: 0;
          }
        }

        ul.feature-group-listing {
          padding-left: 9px;
          margin: 0;

          li {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.58;
            color: #757373;
            padding-bottom: 2px;
          }
        }

        &:hover {
          background: url('../../assets/images/featuregroup-bg.png') left -1px top no-repeat rgba(79, 163, 153, 0.15);
          background-size: 102% 44px;
          border-color: rgba(79, 163, 153, 0.5);
        }
      }
    }
  }
}

.feature-detail-tab {
  margin-top: 25px;
  margin-bottom: 7px;
}

.commonModal {
  border: none;

  .modal-body {
    padding: 1.5rem;

    .closeModal {
      position: absolute;
      right: 11px;
      font-size: 22px;
      top: 6px;
      cursor: pointer;
      color: #000;
    }

    .fa-angle-times::before {
      content: "\F105\F104";
      letter-spacing: -0.15em;
    }
  }

  .btn-block {
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    font-size: 24px;
    color: #000;
  }

  p {
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }

  label {
    font-size: 14px;
    color: #333;
    font-weight: 500;

    span.mandate {
      color: #ff0000;
      margin-left: 3px;
    }
  }

  textarea.form-control {
    box-shadow: none;
    outline: none;
    font-size: 14px;

    &:focus {
      border-color: #ced4da;
    }
  }

  .status_customer label {
    margin-bottom: 0;
  }

  .status_customer p {
    font-size: 13px;
    font-weight: 400;
  }
}

.cmn-datepicker-wrp {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f073";
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #ccc;
    height: 36px;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    font-size: 14px;
    color: #555;

    &:disabled {
      background-color: #f4f4f4;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #fff;
      display: none;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #fff;
      display: none;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #fff;
      display: none;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #fff;
      display: none;
    }

    &.show-placeholder {
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #a9a9a9;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #a9a9a9;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #a9a9a9;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: #a9a9a9;
      }
    }

    &.height_fix {
      height: 39px;
    }
  }
}

#addCustomPhase {
  .cmn-datepicker-wrp {
    input {
      border: none;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
    }
  }
}

.middle-sec.common-redesign {
  font-family: $heading-font;
  background-image: none;
  padding-top: 0;
  padding-bottom: 0;
  height: calc(100vh - 64px);

  .pm-dashboard-modal {
    border: none;
    max-width: 430px;
    padding-top: 0;
    padding-bottom: 0;

    h2 {
      font-size: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 20px;
    }

    .common-form {
      a.forget-pass {
        font-size: 10px;
        letter-spacing: -0.01em;
        color: #6c00ea;
        text-decoration: none;
      }

      .clear-icon {
        background: url("../../assets/images/view_eye.svg") no-repeat;
        width: 14px;
        height: 14px;
        opacity: 1;

        &.active {
          background: url("../../assets/images/view_icon_active.svg") no-repeat;
        }
      }

      .comm-login-module-btn {
        border-radius: 2px;
        font-size: 13px;
        letter-spacing: 0.03em;
        min-height: 42px;
        margin-top: 15px;
        background: #6c00ea !important;
      }

      .comm-login-module-btn:disabled {
        background: #d0d2da !important;
        opacity: 1;
      }

      .form-group {
        margin-bottom: 25px;

        label {
          font-size: 12px;
          color: #000;
          font-weight: 400;
          letter-spacing: -0.01em;
        }

        .common-input-box {
          position: relative;

          &.sa-name-box {
            &:before {
              content: '';
              background: url('../../assets/images/input-name-icon.svg') no-repeat;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 50%;
              margin-top: -6px;
              left: 13px;
            }
          }

          &.sa-phone-box {
            &:before {
              content: "";
              background: url("../../assets/images/icon-phone.svg") no-repeat;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 50%;
              margin-top: -6px;
              left: 13px;
            }
          }

          &.sa-email-box {
            &:before {
              content: '';
              background: url('../../assets/images/login-username.svg') no-repeat;
              width: 12px;
              height: 10px;
              position: absolute;
              top: 50%;
              margin-top: -5px;
              left: 13px;
            }
          }

          &.sa-password-box {
            &:before {
              content: "";
              background: url("../../assets/images/user-pass.svg") no-repeat;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 50%;
              margin-top: -6px;
              left: 13px;
            }
          }

          input.common-input {
            border: 1px solid #c0c3ce;
            border-radius: 2px;
            padding: 0;
            padding-left: 36px;
            font-size: 13px;
            letter-spacing: 0.03em;
            color: #000;

            &#phone_num {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }

            &:focus:required:invalid,
            &.ng-touched.ng-invalid {
              border-color: #cf1322;
            }

            &:focus.ng-valid {
              border-color: #9f4dff;
            }
          }
        }

        .invalid-feedback {
          font-size: 9px;
          font-weight: 400;
          letter-spacing: 0.03em;
        }
      }
    }
  }
}

/**================Revamp Design===========**/

body.revamp-design.revamp-theme {
  .middle-dashboard.ongoing-max-card-dashboard {
    padding-left: 260px;

    @media only screen and (max-width: 991.98px) {
      padding: 53px 0 47px 0;
    }
  }

  .select-box {
    background-image: url("../../assets/images/revamp-select.svg");
    background-size: 13px 8px;
  }

  .timeline-revamp-icon {
    background: url("../../assets/images/timeline-revamp-icon.svg") no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 5px;
  }

  .cmn-datepicker-wrp:before {
    content: "";
    font-family: initial;
    background: url("../../assets/images/revamp-calendar.svg") no-repeat;
    width: 18px;
    height: 18px;
  }

  .custom-radio-reavamp {
    text-align: center;

    label {
      margin: 0;
      margin-top: 4px;
      cursor: pointer;

      input {
        display: none;

        &:checked+span {
          border-color: $revamp-theme-color;

          &:after {
            opacity: 1;
          }
        }
      }

      span {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        border: 1.5px solid #676b7e;
        position: relative;
        display: block;

        &:after {
          content: "";
          position: absolute;
          background: #6200ea;
          width: 7px;
          height: 7px;
          border-radius: 100%;
          opacity: 0;
          left: 3px;
          top: 3px;
        }
      }
    }
  }

  .copy-element {
    .start-stop-installments {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      left: auto;
      cursor: pointer;

      &.copied {
        .installmentContentTooltip {
          display: block;
        }
      }

      .installmentContentTooltip {
        right: 0;
        height: initial;
        min-height: initial;
        max-height: initial;
        background: #fff;
        top: -22px;
        position: absolute;
        display: none;
        padding: 2px 15px;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .start-stop-installments {
    left: 5px;
    top: 2px;

    .installmentContentTooltip {
      box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);
      left: 50%;
      transform: translateX(-50%);
      top: 14px;
      min-height: 38px;
      max-width: 477px;
      width: max-content;
      min-width: initial;
      padding: 0 15px;
      border: 1px solid #f8f9fa;

      p {
        color: #676b7e;
        font-size: 12px;
      }
    }
  }

  .btn-with-tooltip {
    display: flex;
    align-items: center;

    .start-stop-installments {
      left: 0;

      .installmentContentTooltip {
        transform: none;
        left: auto;
      }
    }

    .btn-text {
      padding-left: 8px;
    }

    .question-mark-tooltip {
      display: block;
      width: 12px;
      height: 12px;
      background: url("../../assets/images/question-circle.svg");
    }
  }

  .tooltip-align {
    display: flex;
    align-items: center;

    .info-revamp-icon {
      display: block;
      width: 13px;
      height: 13px;
      background: url("../../assets/images/info_circle.svg");
    }
  }

  .schedule_btn_row {
    .btn:disabled {
      opacity: 1;
      position: relative;

      .question-mark-tooltip {
        opacity: 0.5;
      }

      &:before {
        position: absolute;
        content: "";
        background: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .pod-table-heading {
    font-weight: 500;
    font-size: 12px;
    color: #3c3e49;
    margin-top: 35px;
    margin-bottom: 10px;
  }

  .revamp-pod-table {
    border: 1px solid #d0d2da;
    border-radius: 3px;
    height: calc(100vh - 461px);

    .showpodlist {
      position: relative;
      display: inline-block;

      &:hover {
        .pop-info-tooltip {
          visibility: visible;
          opacity: 1;
          pointer-events: initial;
          transform: translateY(0px);
        }
      }
    }

    .pop-info-tooltip {
      position: absolute;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      min-width: 280px;
      left: auto;
      top: auto;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      transform: translateY(0);
      z-index: 1;
      cursor: default;
      box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);

      .scroll-tooltip {
        margin-top: 5px;
        max-height: 168px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .tooltip-inner-pod {
          .pod-circle {
            width: 25px;
            height: 25px;
            font-size: 10px;
            border-radius: 100%;
            font-weight: 500;
            border: 1px solid;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            display: flex;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 17px;
              height: 17px;
              background: url("../../assets/images/empty-star.svg") no-repeat;
              right: -8px;
              top: -7px;
              cursor: pointer;
            }

            &.no-star:before {
              display: none;
            }

            &.star-fill:before {
              background: url("../../assets/images/fill-star.svg") no-repeat;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          .pod-name {
            font-weight: 400;
            font-size: 14px;
            color: #3c3e49;
            margin-left: 15px;
          }

          &:nth-child(4n + 1) .pod-circle {
            background: #e6f7ff;
            border-color: #91d5ff;
            color: #1890ff;
          }

          &:nth-child(4n + 2) .pod-circle {
            background: #e8f5e9;
            border-color: #a5d6a7;
            color: #4caf50;
          }

          &:nth-child(4n + 3) .pod-circle {
            background: #fff2e8;
            border-color: #ffbb96;
            color: #fa541c;
          }

          &:nth-child(4n + 4) .pod-circle {
            background: #f4eaff;
            border-color: #d4afff;
            color: #6c00ea;
          }

          display: flex;
          align-items: center;
          box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);
          background: #fff;
          padding: 15px;
          margin-top: 0px;
        }
      }
    }

    .table-body {
      height: 100%;
      overflow-y: auto;

      &.no-scroll {
        overflow-y: initial;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      tr:nth-child(even) {
        background: #f8f9fa;
      }

      tr:nth-child(2),
      tr:nth-child(3) {
        .showpodlist {
          .pop-info-tooltip {
            left: 100%;
            top: 0px;
          }
        }
      }

      tr:nth-child(3) {
        .showpodlist {
          .pop-info-tooltip {
            bottom: 0px;
          }
        }
      }

      tr:nth-child(4),
      tr:nth-child(4)~tr {
        .showpodlist {
          .pop-info-tooltip {
            top: auto;
            bottom: 0;
            left: 100%;
          }
        }
      }
    }

    .no-margin {
      margin-bottom: 0;
    }

    .pod-project-detail {
      display: flex;
      margin: 0;

      li {
        span {
          color: #3c3e49;
          font-weight: 400;
        }

        &:first-child {
          margin-left: 0;
        }

        margin-left: 13px;
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 11;
    }

    th {
      font-size: 12px;
      border-bottom: none;
      border-top: none;
      color: #83889e;
      font-weight: 500;
      padding-top: 15px;
      padding-bottom: 15px;
      background: #fff;
    }

    td {
      font-size: 13px;
      min-height: initial;
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: 500;
      vertical-align: middle;

      label {
        margin-top: 0;
        vertical-align: middle;
      }

      color: #3c3e49;

      &:nth-child(2) {
        //padding:0;
        min-height: initial;

        .showpodlist {
          min-height: initial;
          // padding-top: 17px;
          // padding-bottom: 12px;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }

    th,
    td {
      &:first-child {
        width: 4%;
      }

      &:nth-child(2) {
        width: 23%;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 10%;
      }

      width: 14%;

      &:last-child {
        width: 55%;
        width: 52%;
      }
    }
  }

  .purpose-row-revammp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .purpose-row-item {
      .gray-box {
        background: #f8f9fa;
        border: 1px solid #dfe0e6;
        border-radius: 3px;
      }

      .cmn-datepicker-wrp input {
        border-color: #afb2c0;
        color: #000;
        border-radius: 3px;
      }

      .select-box {
        select {
          border-color: #afb2c0;
          color: #000;
          border-radius: 3px;
        }
      }

      .projects-detail {
        h4 {
          font-size: 12px;
          line-height: 1.6;
          color: #000;
        }
      }

      width: calc(25% - 20px);
    }
  }

  ul.ongoing-dashboard-nav {
    background: #fff;
    box-shadow: none;
    border-right: 1px solid #d9d9d9;
    padding: 65px 10px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      a {
        font-size: 12px;
        border-radius: 4px;
        color: #8c8c8c;
        font-weight: 500;
        padding: 0 10px;
      }

      &.active,
      &:hover {
        a {
          background: #f3ebff;
          color: #6200ea;
        }
      }
    }
  }

  .nav-title {
    background: #fff;
    padding: 18px 10px 28px;
    margin-bottom: 10px;

    h2 {
      font-size: 16px;
      line-height: 18px;
      color: #000;
      position: relative;
      display: flex;
      align-items: center;
    }

    .date {
      font-size: 12px;
      color: #83889e;
    }

    .filter-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border: 1px solid #dfe0e6;
      border-radius: 3px;
      position: absolute;
      top: -6px;
      right: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .title-separator {
    &:after {
      content: "";
      height: 1px;
      background: #dfe0e6;
      position: relative;
      width: calc(100% + 40px);
      bottom: -28px;
      left: -20px;
      display: block;
    }
  }

  .revamp-btn.document-download-btn {
    cursor: pointer;
    padding: 6px 16px;
    height: 22px;
    background: #00e676;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #000;
    border: none;
    display: flex;
    position: relative;
    right: 5px;
  }

  .revamp-btn {
    font-size: 12px;
    font-weight: 400;
    background: #ede7f6;
    color: #6200ea;
    border-radius: 3px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .projects-block {
    .heading-search-block {
      font-family: $revamp-font;
      padding-bottom: 10px;
      padding-top: 7px;

      .projects-search input[type='submit'] {
        background-image: url('../../assets/images/search-icon-reavamp.svg');
        width: 14px;
        height: 14px;
        background-size: 14px 14px;
      }

      .projects-search input[type='text'] {
        font-size: 12px;
        margin-left: 10px;
      }

      .top-title {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .heading-search-block {
    .projects-search-filter.home {
      .projects-search {
        background: #fff;
        border: 1px solid #dfe0e6;
        min-width: 280px;
        border-radius: 3px;
        color: #83889e;
        padding-left: 9px;
        margin: 0;
      }
    }
  }

  .main-header {
    border: 1px solid #dfe0e6;
    box-shadow: none;
    display: flex;
    height: 53px;
    background: #fff;
    align-items: center;
    font-family: $revamp-font;

    img.revamp-logo {
      width: 99px;
      display: block;
    }

    div.item-status-manager {
      color: #262626;
    }

    .list-inline {
      li.badge-label {
        color: #262626;
        font-weight: 500;
        font-size: 14px;
      }

      li.badge-stl {
        background: #ede7f6;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .footer {
    font-family: $revamp-font;
    height: 48px;
    background: #fff;
    border-top: 1px solid #dfe0e6;

    p {
      color: #676b7e;
      font-weight: 400;
    }
  }

  .manger-right-section {
    .right-content .no-results-found {
      height: calc(100% - 15px);
    }
  }

  .projects-tab {
    .tab-content {
      #accInfo {
        font-family: $revamp-font;

        .account-main-block {
          .account-info-table {
            .checklist-common-list {
              .invoice-table-inner {
                table {
                  tr.table-header {
                    border-top: 2px solid #ecedf0;
                    border-bottom: 2px solid #ecedf0;
                    background: transparent;

                    th {
                      display: flex;
                      align-items: center;
                      min-height: 57px;
                      font-size: 13px;
                    }
                  }
                }

                .table-content {
                  table {
                    tr:nth-child(even) {
                      background-color: #f8f9fa;
                    }

                    tr {
                      &:hover {
                        background-color: rgba(179, 136, 255, 0.1);
                      }

                      td {
                        padding-top: 0;
                        padding-bottom: 0;
                        min-height: 57px;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                }

                table {
                  tr {
                    border: none;

                    th,
                    td {
                      color: #3c3e49;
                      width: 20%;

                      &:nth-child(2) {
                        width: 25%;
                      }

                      &:last-child {
                        width: 15%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**========Manager Page Revamp============**/
  .revamp-tab-block {
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 0px;
    margin-top: 0;

    .project-card {
      margin-bottom: 13px;
    }

    .initialize-space {
      margin-left: -6px;
      margin-right: -6px;

      &.pol-row {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    .projects-tab {
      .tab-content {
        padding: 0 30px;
      }

      .nav-tabs {
        padding-left: 30px;
        margin-bottom: 30px;
        padding-top: 22px;
        border-bottom: 1px solid #d9d9d9;
        background: #fff;

        .nav-link {
          color: #afb2c0;
          font-weight: 500;
        }

        .nav-link.blocked-tab-color.active,
        .nav-link.active {
          color: $revamp-theme-color;
          border-color: $revamp-theme-color;
          border-width: 3px;
        }
      }
    }

    .projects-search {
      margin-right: 30px;
      background: #ffffff;
      border: 1px solid #dfe0e6;
      box-sizing: border-box;
      border-radius: 3px;
      height: 30px;
      min-width: 280px;

      &.inbox-search {
        width: 100%;
        height: auto !important;
        padding-right: 0;
        margin: 0;
        padding-left: 10px;
      }

      input[type='submit'] {
        background-image: url('../../assets/images/search-icon-reavamp.svg');
      }
    }

    .projects-search-filter {
      top: 6px;

      &.projects-search-filter {
        top: 6px;
        float: none;
        margin: 15px 20px;
        justify-content: initial;
      }

      input[type='text'] {
        height: 100%;
      }
    }

    .add-pod-block {
      height: 100%;
      width: 100%;
      font-family: $revamp-font;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #afb2c0;
      cursor: pointer;
      min-height: 166px;

      .add-pod-inner {
        text-align: center;

        .add-pod-text {
          font-size: 14px;
          margin-top: 11px;
          color: #3c3e49;
        }
      }
    }

    .revamp-manager-card {
      font-family: $revamp-font;
      background: #ffffff;
      border-radius: 4px;

      .header-row {
        padding: 15px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pod-group-name {
          font-weight: 500;
          font-size: 15px;
          line-height: 1.4;
          color: #3c3e49;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 15px;
        }

        .edit-pod-name {
          cursor: pointer;
          min-width: 60px;
          text-align: right;

          span {
            margin-left: 15px;

            &.studio-pod-info {
              position: relative;
              top: 0;
              left: 0;

              &:hover {
                .installmentContentTooltip {
                  opacity: 1;
                  visibility: visible;
                }
              }

              .installmentContentTooltip {
                display: flex;
                align-items: center;
                left: 2px;
                position: absolute;
                background: #fff;
                transform: none;
                z-index: 1;
                top: 28px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease-in-out;

                p {
                  margin: 0;
                }
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .pod-member-list {
      padding-top: 20px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;

      .pod-list-heading {
        color: #83889e;
        font-size: 12px;
        line-height: 1.16;
        margin-bottom: 16px;
      }

      .show-pod-list {
        margin-bottom: 0;
        display: flex;

        .pod-list-item {
          margin-left: 10px;
          cursor: pointer;
          position: relative;

          &.more-list-pod:last-child {
            .pod-circle {
              background: none;
              border-color: #c0c3ce;
              color: #3c3e49;
            }

            .pop-info-tooltip {
              .scroll-tooltip {
                margin-top: 5px;
                max-height: 275px;
                overflow-y: auto;

                @media only screen and (max-width: 767.98px) {
                  margin-top: 0;
                }

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                .tooltip-inner-pod:first-child {
                  margin-top: 0;
                }
              }

              .tooltip-inner-pod {
                .pod-circle {
                  color: #1890ff;
                }

                &:nth-child(4n + 1) {
                  .pod-circle {
                    background: #e6f7ff;
                    border-color: #91d5ff;
                  }
                }

                &:nth-child(4n + 2) {
                  .pod-circle {
                    background: #e8f5e9;
                    border-color: #a5d6a7;
                  }
                }

                &:nth-child(4n + 3) {
                  .pod-circle {
                    background: #fff2e8;
                    border-color: #ffbb96;
                  }
                }

                &:nth-child(4n + 4) {
                  .pod-circle {
                    background: #f4eaff;
                    border-color: #d4afff;
                  }
                }
              }
            }
          }

          &:hover {
            .pop-info-tooltip {
              visibility: visible;
              opacity: 1;
              pointer-events: initial;
              transform: translateY(0px);

              @media only screen and (max-width: 767.98px) {
                transform: translate(-50%, -50%);
              }
            }
          }

          .pop-info-tooltip {
            position: absolute;
            width: max-content;
            min-width: 220px;
            left: 0;
            top: 25px;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            transform: translateY(11px);
            z-index: 1;
            cursor: default;

            @media only screen and (max-width: 767.98px) {
              position: fixed;
              z-index: 99;
              width: calc(100% - 30px);
              top: 50%;
              left: 50%;
              box-shadow: 0px 0px 70px 2px rgb(0 0 0);
            }

            .tooltip-inner-pod {
              display: flex;
              align-items: center;
              border: 1px solid #f8f9fa;
              box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);
              background: #fff;
              padding: 15px;

              &:first-child {
                margin-top: 5px;

                @media only screen and (max-width: 767.98px) {
                  margin-top: 0;
                }
              }

              .pod-circle {
                width: 32px;
                height: 32px;
                flex-shrink: 0;
                font-size: 13px;
              }

              .pod-name {
                font-weight: 500;
                font-size: 13px;
                color: #3c3e49;
                margin-left: 15px;
              }
            }
          }

          &:first-child {
            margin-left: 0;

            .pod-circle {
              background: #e6f7ff;
              border-color: #91d5ff;
            }
          }

          &:nth-child(4n + 1) {
            .pod-circle {
              background: #e6f7ff;
              border-color: #91d5ff;
            }
          }

          &:nth-child(2),
          &:nth-child(4n + 2) {
            .pod-circle {
              background: #e8f5e9;
              border-color: #a5d6a7;
            }
          }

          &:nth-child(3),
          &:nth-child(4n + 3) {
            .pod-circle {
              background: #fff2e8;
              border-color: #ffbb96;
            }
          }

          &:nth-child(4),
          &:nth-child(4n + 4) {
            .pod-circle {
              background: #f4eaff;
              border-color: #d4afff;
            }
          }

          .pod-circle {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1890ff;
            font-size: 10px;
            border-radius: 100%;
            font-weight: 500;
            border: 1px solid;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .projects-tab {
    .pod-tab-col {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 23px;

      @media (min-width: 1400px) {
        &:nth-child(4n) {
          .revamp-manager-card {
            .header-row {
              .edit-pod-name span.studio-pod-info .installmentContentTooltip {
                left: auto;
                right: 0;
              }
            }

            .pod-member-list {
              .show-pod-list {
                .pod-list-item {
                  .pop-info-tooltip {
                    left: auto;
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: 992px) and (max-width: 1399.98px) {
        &:nth-child(3n) {
          .revamp-manager-card {
            .header-row {
              .edit-pod-name span.studio-pod-info .installmentContentTooltip {
                left: auto;
                right: 0;
              }
            }

            .pod-member-list {
              .show-pod-list {
                .pod-list-item {
                  .pop-info-tooltip {
                    left: auto;
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: 768px) and (max-width: 991.98px) {
        &:nth-child(2n) {
          .revamp-manager-card {
            .header-row {
              .edit-pod-name span.studio-pod-info .installmentContentTooltip {
                left: auto;
                right: 0;
              }
            }

            .pod-member-list {
              .show-pod-list {
                .pod-list-item {
                  .pop-info-tooltip {
                    left: auto;
                    right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.revamp-right-content {
  padding: 0;
  overflow-x: hidden;
  @include customScrollbar;
}

.project-allocation-revamp {
  padding: 30px 35px;
  font-family: $revamp-font;
  padding-bottom: 0;

  .main-inner-block {
    padding: 0;
  }

  ul.project-breadcrumb {
    font-family: $revamp-font;

    li {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.16;
      color: #3c3e49;

      span.customPrototype {
        color: #676b7e;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.18;
      }
    }
  }

  .revamp-nav-part {
    margin: 30px 0;
    border-bottom: 1px solid #d9d9d9;

    .ravamp-nav-part-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;

      .nav-tabs {
        border: none;

        .nav-item {
          margin-bottom: -2px;
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }

        .nav-link {
          color: #afb2c0;
          font-weight: 500;
          font-size: 14px;
          border: none;
          border-bottom: 3px solid transparent;
          padding: 0;
          padding-bottom: 10px;

          &.active {
            border-color: #6200ea;
            color: #6200ea;
          }
        }
      }

      ul {
        margin: 0;

        &.project-allocation-edit.allocation-edit-mode {
          display: flex;

          li {
            font-size: 14px;
            color: #3c3e49;
            padding-bottom: 10px;
            margin-left: 15px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

/****modal revamp*****/
.revamp-modal {
  font-family: $revamp-font;

  .modal-dialog {
    max-width: 428px;

    .modal-body {
      padding: 30px;

      .popup-remove {
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
      }

      h2 {
        font-weight: 500;
        font-size: 24px;
        color: #3c3e49;
        margin-bottom: 0;
      }

      .pod-form {
        .form-wrp {
          padding-top: 30px;

          .revamp-submit-btn {
            background: #00d659;
            border-radius: 3px;
            padding: 6px 16px;
            font-size: 12px;
            font-weight: 500;

            &:disabled {
              background: #d9d9d9;
              color: #fff;
            }
          }

          .form-wrp-inner {
            p.assigntopodwarn {
              font-size: 12px;
            }

            .scroll-all-project {
              border: 1px solid #d0d2da;
              padding: 0;
              font-size: 12px;
              border-radius: 3px;
              max-height: 113px;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              &::-webkit-scrollbar-thumb {
                background: #888;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }

              ul {
                margin: 0;

                li {
                  padding: 5px 15px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &:nth-child(even) {
                    background: #f5f5f9;
                  }
                }
              }
            }

            .custom-radio-reavamp {
              label {
                display: flex;
                align-items: center;
                margin: 0;

                span {
                  border-color: $revamp-theme-color;
                }

                cite {
                  font-size: 12px;
                  font-weight: normal;
                  color: #3c3e49;
                  font-style: normal;
                  margin-left: 6px;
                }
              }
            }

            label {
              font-weight: 500;
              font-size: 12px;
              line-height: 1.6;
              color: #000000;
              display: block;
              margin-bottom: 4px;
            }

            .input-wrp {
              position: relative;

              input[type='text'],
              .tagwrp-input {
                width: 100%;
                border: 1px solid #d0d2da;
                box-sizing: border-box;
                border-radius: 3px;
                height: 40px;
                font-size: 14px;
                padding: 0 84px 0 16px;
                color: #000000;

                &:focus {
                  border-color: #651fff;
                  outline: none;

                  &+span.helper-name {
                    color: #000000;

                    .hyphen-text {
                      background: #000;
                    }
                  }
                }
              }

              .tagwrp-input {
                height: auto;
                min-height: 40px;
                padding-top: 8px;
                padding-bottom: 8px;
                max-height: 118px;
                overflow-y: auto;
                padding-right: 20px;

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                .tag-item {
                  margin-right: 8px;
                  display: inline-block;
                  background: #f5f5f9;
                  font-size: 12px;
                  line-height: 14px;
                  padding: 6px 10px;
                  margin-bottom: 10px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 97%;
                  vertical-align: top;

                  .remove-pod {
                    background: url("../../assets/images/remove-pod.svg");
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }

                .tag-editable {
                  outline: none;
                  padding-left: 1px;
                  vertical-align: top;
                }

                &:focus-within {
                  border-color: #651fff;
                }

                &[placeholder]:empty:before {
                  content: attr(placeholder);
                  color: #afb2c0;
                }

                &[placeholder]:empty:focus:before {
                  content: "";
                }
              }

              span.helper-name {
                position: absolute;
                right: 16px;
                top: 11px;
                font-size: 14px;
                line-height: 17px;
                color: #bfbfbf;
                margin-right: 16px;

                span.hyphen-text {
                  margin-right: 16px;
                  height: 20px;
                  width: 1px;
                  background: #bfbfbf;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }

            .custom-dropdown {
              box-shadow: 0px 4px 4px rgba(131, 136, 158, 0.08);
              border: 1px solid #f8f9fa;
              background: #fff;
              position: absolute;
              width: 100%;
              padding: 0 20px 16px 20px;
              z-index: 1;
              min-height: 100px;

              ul.drop-down-list {
                max-height: 250px;
                overflow-y: auto;
                margin-right: -20px;
                margin-bottom: 25px;

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                li {
                  padding-top: 20px;

                  label {
                    letter-spacing: -0.01em;
                    color: #3c3e49;
                    font-size: 14px;
                    position: relative;
                    font-weight: 400;

                    input[type='checkbox'] {
                      display: none;
                    }

                    input[type='checkbox']:checked {
                      &+.custom-checkbox-revamp {
                        &:before {
                          background: #7c4dff;
                          border-color: #7c4dff;
                          background-image: url("../../assets/images/check-icon.svg");
                          background-size: 9px 7px;
                          background-position: top 2px center;
                          background-repeat: no-repeat;
                        }
                      }
                    }

                    .custom-checkbox-revamp {
                      padding-left: 30px;
                      position: relative;

                      &:before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        left: 0;
                        border: 2px solid #000;
                        border-radius: 3px;
                        top: 0px;
                      }
                    }
                  }
                }
              }

              .done-state {
                text-align: right;

                .done-btn {
                  background: #3c3e49;
                  border-radius: 3px;
                  padding: 6px 16px;
                  font-size: 12px;
                  color: #fff;
                  font-weight: 500;
                }
              }

              .load-more-pages {
                margin: 0;

                img {
                  max-width: 50px;
                }
              }
            }

            .keyword-loader {
              position: absolute;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              left: 0;
              z-index: 1;
              margin: 0;
              background: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
    }
  }
}

/**basic information revamp**/
body.revamp-design.revamp-theme {
  .projects-tab {
    .basic-info-revamp {
      .projectDetail {
        .product-template-block h3 {
          background: none;
        }

        .project-info-top {
          background: #f8f9fa;
          font-family: $revamp-font;
          padding: 24px;
          border-radius: 0;

          .progress {
            height: 3px;
            border-radius: 40px;
            background: #dfe0e6;
            margin-top: 38px;

            .progress-bar {
              background: #6200ea;

              span {
                top: -21px;
                color: #6200ea;
                font-size: 12px;
                font-weight: 500;
                opacity: 1;
              }
            }
          }

          .project-info-row {
            .duration-heading-info {
              width: 45%;

              @media (max-width: 991.98px) {
                width: 100%;
              }
            }

            h3 {
              font-weight: 500;
              font-size: 19px;
              color: #000;
              line-height: 1.16;
            }

            .alias {
              color: #262626;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              margin: 8px 0 11px;

              .alias-tag {
                background: #e5e7ff;
                border-radius: 4px;
                padding: 3px 6px;
                font-size: 12px;
                line-height: 14px;
                color: #3c3e49;
                margin-right: 5px;
                margin-top: 0;
              }
            }

            .duration-info {
              margin-top: 6px;
              margin-bottom: 12px;

              p {
                color: #676b7e;
                font-size: 12px;
                line-height: 1.16;

                strong {
                  font-weight: 500;
                }

                cite {
                  span {
                    color: #d50000;
                  }
                }
              }

              .info-box {
                display: flex;
                align-items: baseline;

                p {
                  max-width: 220px;
                }

                .dropdown-wrapper {
                  position: relative;

                  .zmdi {
                    color: #000;
                    cursor: pointer;
                  }

                  .ddl-button {
                    border: 0;
                    background: transparent;
                    color: #651fff;
                    font-size: 12px;
                    line-height: 18px;

                    &:hover,
                    &:active,
                    &:visited,
                    &:focus {
                      outline: none;
                      background: transparent;
                    }
                  }

                  .dropdown-menu {
                    border: 1px solid #dfe0e6;
                    border-radius: 5px;
                    position: absolute;
                    top: 28px;
                    left: 0;
                    padding: 0;
                    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
                    max-height: 310px;
                    overflow-y: auto;

                    ul {
                      padding: 0 14px;
                      margin: 0;
                      width: 320px;

                      li {
                        color: #3c3e49;
                        font-size: 12px;
                        line-height: 16px;
                        padding: 14px 0px;
                        border-bottom: 1px solid #dfe0e6;

                        &:last-child {
                          border: none;
                        }

                        .note {
                          color: #83889e;
                        }

                        .txt-red {
                          color: #d50000;
                        }
                      }
                    }
                  }
                }
              }
            }

            ul.list-inline {
              li {
                padding-right: 0;
                margin-right: 15px;
                padding-bottom: 1px;
                border-bottom: 2px solid #00d659;

                &:before {
                  display: none;
                }

                &:last-child {
                  padding-right: 0;
                }

                .view-build-card-btn {
                  padding: 0;
                  font-size: 12px;
                  line-height: 1.6;
                  letter-spacing: -0.005em;
                  color: #000;

                  em {
                    padding-right: 0;
                    padding-left: 6px;
                  }
                }
              }
            }

            .final-cost {
              p {
                span {
                  font-weight: 400;
                  opacity: 1;
                  font-size: 18px;
                }

                font-size: 18px;
                color: #3c3e49;
                line-height: 1.16;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .round-img-cover {
    width: 44px;
    height: 44px;
    background: #f8f9fa;
    border-radius: 100%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-cover {
    margin-right: 16px;
  }
}

.phase-tab {
  font-family: $revamp-font;

  ul {
    padding: 0;
    display: flex;
    margin: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      margin-right: 40px;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      font-weight: 500;
      cursor: pointer;
      color: #afb2c0;

      &.active {
        color: $revamp-theme-color;
        border-color: $revamp-theme-color;
      }
    }
  }
}

.phasedevices {
  font-family: $revamp-font;
  background: #f8f9fa;
  padding: 30px 40px;
  margin-top: 20px;

  .phaseheading {
    font-weight: 500;
    font-size: 14px;
    color: #676b7e;
  }

  ul.phasedevicelist {
    display: flex;
    margin: 0;
    margin: 20px 0 0 0;
    border-bottom: 1px solid #dfe0e6;
    padding-bottom: 30px;
    flex-wrap: wrap;

    .phaseicon {
      img {
        max-height: 26px;
        filter: grayscale(100%);
      }
    }

    li {
      padding-right: 40px;
      text-align: center;
      font-size: 10px;
      color: #676b7e;

      span {
        display: block;
        margin-top: 7px;
      }
    }
  }
}

ul.phaseinfolist {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    border-right: 1px solid #dfe0e6;
    margin-right: 25px;
    margin-top: 25px;
    padding-right: 25px;

    &:last-child {
      border: none;
    }
  }

  button.view-features,
  .feature-badge {
    font-size: 12px;
    line-height: 20px;
    background: none;
    border: none;
    font-weight: 400;
    padding: 0;
    border-bottom: 2px solid #00d659;
    margin-left: 10px;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .feature-badge {
    padding-bottom: 0px;
  }

  .phaseinfodetail {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .phaseinfohead {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #676b7e;
  }
}

body.revamp-design.revamp-theme {

  .btn.disabled,
  .btn:disabled {
    opacity: 0.5;
  }

  button.btn.revamp-btn.document-download-btn:disabled {
    cursor: none;
  }

  .cost-section-revamp {
    border: 1px solid #ecedf0;
    border-radius: 4px;
    font-family: $revamp-font;
  }

  .upper-cost-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 20px 10px;
    overflow: auto;
  }

  .cost-section-item {
    display: flex;
    align-items: center;
    margin: 0 10px;

    .round-img-cover {
      background: #ecedf0;
      width: 44px;
      height: 44px;
    }

    &.no-flx {
      display: block;
    }
  }

  .cost-section-heading span {
    font-size: 12px;
    line-height: 14px;
    color: #676b7e;
    margin-bottom: 6px;
    display: block;
    white-space: nowrap;
  }

  .main-cost {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #3c3e49;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .main-cost-input .form-control {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 158px;
    border: none;
    height: 30px;
    border-radius: 0;
    background: #ffffff;
    font-size: 12px;
  }

  .bottom-cost-section {
    text-align: right;
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;

    .payment-btn-wrapper {
      position: relative;
      margin-right: 24px;
      .infoTooltip {
        position: absolute;
        bottom: 100%;
        z-index: 1;
        width: 302px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 8px;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #3C3E49;
        }
        .innerTooltip {
          margin: 0;
          background: #3C3E49;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: #fff;
          border-radius: 3px;
          height: 40px;
          a {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
            max-width: 180px;
            margin: 0 7px;
            color: #fff;
            text-decoration: underline;
          }
          .copy-link {
            color: #00E676;
            cursor: pointer;
          }
        }
      }
    }

    button {
      background: #00d659;
      border-radius: 3px;
      padding: 6px 16px;
      color: #000000;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      &.payment-link {
        background: none !important;
        border-radius: 3px;
        border: 1px solid #00d659 ;
        padding: 6px 24px;
        color: #00d659 !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        .icon {
          display: inline-flex;
          margin-left: 8px;
          animation: 2s round-anim linear infinite;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@keyframes round-anim {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.remove-download-icon {

  em,
  img {
    padding-left: 5px;
    cursor: pointer;
    vertical-align: middle;
  }
}

.projects-tab {
  .archive-unarchive-row {
    text-align: right;
    margin-bottom: 10px;

    .createNewBtn {
      position: static;
      padding: 0;
    }
  }
}

.custom-phase-block {
  position: relative;

  .edit_delete {
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    top: 20px;

    span {
      cursor: pointer;
    }

    span.del-phase {
      margin-left: 15px;
    }
  }
}

.custom-phase-item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .custom-phase-head {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }

  .custom-phase-desc {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3c3e49;
    margin-top: 6px;
  }

  .custom-phase-flex {
    display: flex;
    align-items: center;
  }

  .custom-phase-inner {
    margin-right: 50px;
    min-width: 100px;

    &:last-child {
      margin-right: 0;
    }
  }

  .custom-phase-desc ul {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      border-right: 1px solid #c0c3ce;
      padding-right: 20px;
      margin-right: 20px;
      line-height: 24px;

      &:last-child {
        border-right: none;
        padding-right: 0px;
        margin-right: 0px;
      }
    }
  }
}

.flag {
  background-image: url("../../assets/images/country-flag.jpg") !important;
}

.call-score-modal {
  font-family: $revamp-font;

  .modal-dialog {
    max-width: 650px;
  }

  .modal-body {
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .call-score {
    .top-head-score {
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 6px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #676b7e;
        margin: 0;
      }
    }

    .score-info-box {
      margin-top: 20px;
      background: #f8f9fa;
      border-radius: 3px;
      padding: 16px;

      .score-info-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          margin-bottom: 0;
        }

        .call-text {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.01em;
          color: #000000;
        }

        .call-with {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.01em;
          color: #000000;
          width: calc(100% - 100px);

          span {
            font-weight: 400;
          }
        }

        .pager-info,
        .score-date,
        .pager-next-previous {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.01em;
          color: #676b7e;
          margin-right: 40px;
        }

        .score-date {
          margin: 0;
        }

        .pager-next-previous {
          display: flex;
          align-items: center;
          margin: 0;
          color: #000;

          .pager-item {
            &.hide-on {
              visibility: hidden;
            }

            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              margin-left: 7px;
              margin-top: 1px;
            }
          }

          .next {
            margin-left: 16px;

            span {
              margin-left: 0;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

.angular-editor {
  li {
    list-style: initial;
  }

  ul {
    padding-left: 30px;
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 13px;
  }

  h6 {
    font-size: 10px;
  }
}

.angular-editor {
  img {
    max-width: 800px;
  }
}

.highLite_colors {
  min-height: 14rem;
  padding: 0.5rem 0.8rem 1rem 0.8rem;
  background-color: transparent;
  word-wrap: break-word;
  white-space: normal;
  overflow: auto;
  width: 100%;
  resize: none;
  border: none;
  line-height: 1.3;
  position: relative;
  height: calc(100% - 60px);
  max-height: 100% !important;

  // user-select: none !important;
  ::ng-deep blockquote {
    margin: 0px;
    border-left: 1px solid #bbbdbf;
    padding-left: 10px;
  }

  &:hover {
    background-color: #e9e9f0 !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #9d9b9b !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/****Todo revamp is here****/

/***common-pattern***/
.revamp-design.revamp-theme {

  .save-task,
  .del-task {
    img {
      margin-right: 5px;
    }

    color: #d50000;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.hide {
      visibility: hidden;
    }
  }

  .delayMsgOnTop {
    .revamp-tab-block {
      .projects-tab {
        .revamp-common-nav-part-inner {
          .nav-tabs {
            padding-top: 2px;
          }
        }
      }
    }
  }

  .revamp-tab-block {
    .projects-tab {
      .todo-listing-wrp {
        .tab-content {
          padding: 0;
          height: calc(100vh - 220px);
        }
      }

      .revamp-common-nav-part-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        width: 100%;

        .nav-tabs {
          margin-bottom: 0;
          border-bottom: none;

          .nav-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .delayMsgOnTop {
    .common-heading-revamp {
      padding-top: 10px;
    }

    .project-allocation-revamp {
      ul.project-breadcrumb {
        padding-top: 10px;
      }
    }
  }

  .common-heading-revamp {
    padding: 0;
    padding-top: 30px;
  }

  .nav-tab-right {
    padding-right: 30px;
    display: flex;
    align-items: center;

    .projects-search-filter {
      position: static;
      margin: 0;
      float: none;

      .projects-search {
        margin-right: 20px;
      }
    }

    button {
      border: none;
      font-size: 12px;
      height: 31px;
      width: 85px;
      flex-shrink: 0;
      padding: 0 16px;
      background: #3c3e49;
      border-radius: 3px;
      color: #fff;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .revamp-right-content {
    .todos-right {
      .inc-border {
        display: block;
        margin-left: -20px;
        margin-right: -20px;
        border-bottom: 1px solid #dfe0e6;
      }

      .todos-header {
        height: auto;
      }

      .todos-inner {
        top: 0;
        height: calc(100% - 127px);

        &.mailboxInner {
          top: 45px;
          height: calc(100% - 180px);
        }
      }
    }

    ul.tasks-queries-list {
      font-family: $revamp-font;

      li {
        cursor: pointer;
        border-left: none;
        min-height: initial;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0px;
        position: relative;

        .revamp-todo-list {
          border-bottom: 1px solid #dfe0e6;
          padding-left: 25px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 70px;
          width: 100%;

          &.mailing-list {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-right: 25px;
            width: calc(100% - 25px);
          }
        }

        &:after {
          display: none;
        }

        &:hover {
          background: #f8f9fa;
        }

        &.active {
          background: #f8f9fa;

          .todo-name {
            font-weight: 500;
          }
        }

        &:after {
          left: auto;
          right: 0;
          transform: none;
        }

        .todo-name {
          font-size: 18px;
          color: #3c3e49;
        }

        .todo-more-info {
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          margin-top: 12px;

          &.mail-message {
            margin-top: 0;
          }

          .todo-more-item {
            padding-right: 20px;
            color: #83889e;

            span {
              &.green {
                color: #558b2f;
              }

              strong {
                &.red {
                  color: #d50000;
                }
              }
            }

            &:last-child {
              padding-right: 0;
            }

            strong {
              font-weight: 400;
              color: #3c3e49;

              &.badge-style {
                background: #ffebee;
                color: #dd2c00;
                border-radius: 60px;
                padding: 3px 12px;
              }
            }
          }
        }
      }
    }

    ul.status-type {
      margin: 0;
      display: flex;
      font-family: $revamp-font;

      li {
        //margin-left: 20px;
        font-size: 12px;
        padding: 3px 12px;
        color: #3c3e49;
        flex-shrink: 0;
        cursor: pointer;

        &.active {
          background: $revamp-theme-color;
          color: #fff;
          border-radius: 3px;
        }
      }
    }

    .todosQueries {
      .todos-left {
        width: calc(50% + 1px);
      }

      .todos-right {
        font-family: $revamp-font;
        width: 50%;

        .commentArea {
          padding-top: 16px;
          padding-bottom: 16px;
          height: 135px;

          &.mailerComment {
            height: auto;
          }

          .commentTextArea {
            border-color: #dfe0e6;

            &.showBorder {
              border-color: $revamp-theme-color;
            }

            .commentAreaBtn {
              justify-content: space-between;

              .group-btn-attatch {
                display: flex;
                align-items: center;
              }

              .attached-file-edit {
                overflow-x: auto;
                margin-right: 12px;

                &::-webkit-scrollbar {
                  width: 5px;
                  height: 4px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                .attach-file-info {
                  width: 212px;
                  height: 28px;
                  padding: 8px 50px 8px 10px;
                  border-radius: 3px;
                  background: #f8f9fa;
                  position: relative;

                  .right-pos {
                    position: absolute;
                    right: 15px;
                    cursor: pointer;
                  }

                  span {
                    font-size: 10px;
                    color: #676b7e;
                  }
                }
              }

              .attach-file {
                margin-top: 0;
                overflow: initial;
              }

              button {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $revamp-theme-color;

                img {
                  width: auto;
                }
              }
            }
          }
        }

        .status-option {
          font-size: 13px;
          color: #3c3e49;
        }

        .cmn-datepicker-wrp.no-border {
          &:before {
            display: none;
          }

          input {
            border: none;
            padding: 0;
            height: auto;
            width: 115px;
            min-height: initial;
            min-width: initial;
            background-image: url("../../assets/images/revamp-calendar.svg");
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position: top 1px right;
            cursor: pointer;
          }
        }

        .task-detail-row {
          margin-bottom: 15px;

          .attach-file-info {
            width: 212px;
            height: 28px;
            padding: 8px 50px 8px 10px;
            border-radius: 3px;
            background: #f8f9fa;
            position: relative;

            span {
              font-size: 10px;
              color: #676b7e;
            }

            .right-pos {
              position: absolute;
              right: 15px;
              cursor: pointer;
            }
          }

          .assignedByName {
            p.assignedBy-name {
              font-size: 13px;
              color: #3c3e49;

              &.assignee-name {
                width: 135px;
              }
            }

            .initial-circle {
              font-weight: 500;
              margin-right: 15px;
              border: 0.675676px solid #91d5ff;
              color: #1890ff;
              width: 25px;
              height: 25px;
              background: #e6f7ff;
              font-size: 9px;
            }
          }

          .timeline-wrp {
            margin-bottom: 11px;
            padding-left: 40px;

            li {
              display: block;

              &:first-child {
                margin-top: 0;
              }

              margin: 0;
              font-size: 12px;
              line-height: 20px;
              color: #83889e;
              margin-bottom: 4px;
            }
          }

          ul.Comments-list {
            .timeline-wrp {
              li {
                &:first-child {
                  margin-top: 0;
                }
              }
            }

            li {
              &:first-child {
                margin-top: 1.2rem;
              }

              &:nth-child(4n + 1) {
                .initial {
                  background: #e6f7ff;
                  border-color: #91d5ff;
                }
              }

              &:nth-child(4n + 2) {
                .initial {
                  background: #e8f5e9;
                  border-color: #a5d6a7;
                }
              }

              &:nth-child(4n + 3) {
                .initial {
                  background: #fff2e8;
                  border-color: #ffbb96;
                }
              }

              &:nth-child(4n + 4) {
                .initial {
                  background: #f4eaff;
                  border-color: #d4afff;
                }
              }

              .initial {
                font-weight: 500;
                margin-right: 15px;
                border: 0.675676px solid #91d5ff;
                color: #1890ff;
                width: 25px;
                height: 25px;
                background: #e6f7ff;
                font-size: 10px;
              }

              h4 {
                font-size: 14px;
              }

              p {
                font-size: 14px;
                line-height: 1.5;
                font-family: $revamp-font;
                color: #676b7e;
                margin-bottom: 5px;
                margin-top: 10px;
              }

              .comm-detail {
                .date-time {
                  .edit-del-dropdown {
                    box-shadow: none;
                  }

                  .dropdown-menu {
                    top: 0;
                    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.15) !important;

                    a {
                      color: #d50000;
                    }
                  }
                }

                .below-comment-date {
                  .date-time {
                    position: static;
                    font-size: 10px;
                    color: #83889e;
                  }
                }

                .comment-attachedp-main {
                  margin-bottom: 6px;
                  overflow-x: auto;

                  &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                  }

                  &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #888;
                  }

                  &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                  }
                }

                .comment-attached-file {
                  .attach-file-info {
                    width: 212px;
                    height: 28px;
                    padding: 8px 50px 8px 10px;
                    border-radius: 3px;
                    border: 1px solid #dfe0e6;
                    position: relative;
                    margin-right: 12px;

                    &:last-child {
                      margin-right: 0;
                    }

                    #doc-name {
                      max-width: 150px;
                    }

                    .right-pos {
                      position: absolute;
                      right: 15px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .attach-file {
            margin-left: 0;

            span {
              margin-left: 10px;
              color: #3c3e49;
            }
          }

          .pro-status {
            .status-option {
              select {
                padding-left: 0;
                font-size: 13px;
                color: #3c3e49;
              }
            }
          }

          label {
            font-size: 12px;
            color: #83889e;
            font-weight: 400;
            min-width: 110px;
          }

          .radio-primary input[type='radio']:checked+label::before {
            border-color: $revamp-theme-color;
          }

          .custom-radio {
            label {
              min-width: initial;
              font-size: 14px;

              &:before {
                top: 3px;
                border-color: #83889e;
                width: 16px;
                height: 16px;
              }

              &:after {
                top: 7px;
                background: $revamp-theme-color;
                width: 8px;
                height: 8px;
              }
            }
          }
        }

        .grouping-icon {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          text-align: right;
          background: #fff;
          padding-right: 20px;
          z-index: 1;
          padding-top: 20px;
          display: flex;
          justify-content: flex-end;
        }

        .todo-title-wrp {
          padding-left: 20px;
          position: relative;
          z-index: 3;
          top: -25px;
          width: calc(100% - 150px);

          &.inbox-title-wrp {
            top: 0;
            font-size: 20px;
            height: 57px;
            display: flex;
            align-items: center;
            background: #fff;
            width: 100%;
          }

          h4 {
            font-size: 24px;
            margin: 0;
            font-weight: 400 !important;
            line-height: 1.5;
          }
        }
      }
    }

    .todos-header {
      height: 56px;
      background: #f8f9fa;
    }

    .todos-inner {
      height: calc(100% - 56px);
      top: 56px;

      &.inbox-inner {
        top: 0;
        height: calc(100% - 122px);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.global-assigned-box {
  width: 200px;

  .custom-multi-wrp {
    position: relative;

    .custom-multi-checkbox {
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      color: #555;
      line-height: 40px;
      padding-left: 0;

      &.open-multisearch {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-size: 10px 8px;

        &+.multi-search-checkbox {
          display: block;
        }
      }

      // span{
      //     opacity:0.37;
      // }
    }

    .multi-search-checkbox {
      box-shadow: 0 3px 6px 0 #cccccc;
      border: solid 1px #cccccc;
      background-color: #ffffff;
      padding: 12px 12px 0px;
      max-height: 168px;
      overflow-y: auto;
      position: absolute;
      z-index: 10;
      width: 100%;
      display: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .feature-list-block {
        float: none;

        em,
        span {
          color: #ff0014;
          font-size: 12px;
          padding-right: 5px;
          margin-bottom: 13px;
        }

        .feature-list-search {
          padding: 0 12px;
          box-shadow: 0 4px 10px 0 rgba(189, 199, 222, 0.23);
          border: solid 1px #cccccc;
          background-color: #fff;
          border-radius: 0;
          margin: 0;
          margin-bottom: 20px;

          em {
            margin: 0;
            font-size: 16px;
            color: #bcbccb;
          }

          input[type='text'] {
            color: #777;
            font-size: 13px;
          }
        }
      }

      .group-feature-checkbox {
        margin: 0 -12px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        margin-bottom: 20px;

        li {
          margin-top: 10px;
          padding-left: 12px;
          padding-right: 12px;

          &:first-child {
            margin-top: 0;

            label {
              font-weight: 500;
            }

            .ttl-features {
              font-size: 14px;
              font-weight: 500;
              float: right;
            }
          }

          .custom-checkbox {
            position: relative;

            &.heading-dropdown {
              label {
                padding: 0 !important;
              }
            }

            &.disabled {
              label {
                opacity: 0.4;
                pointer-events: none;
              }
            }

            label {
              padding: 0;
              margin-bottom: 0;
              font-size: 14px;
              padding-left: 25px;
              color: #555;
              vertical-align: top;
              width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              span {
                left: 0;
                top: 1px;
                border-radius: 100%;

                &:after {
                  width: 4px;
                }
              }
            }
          }
        }

        &:last-child {
          border: none;
          margin-bottom: 0;
        }

        .ml10 {
          margin-left: 10px;
        }
      }
    }
  }
}

/**my task planner**/
.revamp-design.revamp-theme {
  .mytask-planner {
    position: fixed;
    width: 472px;
    max-width: 100%;
    height: calc(100vh - 101px);
    right: 0;
    top: 53px;
    background: #fff;
    overflow-y: auto;
    border-left: solid 1px #e2e2e2;

    .revamp-right-content {
      height: 100%;
    }

    .todos-inner {
      position: static;
    }

    .todosQueries {
      .todos-right {
        border: none;
        top: 0;
        width: 100%;

        .todos-inner {
          height: 100%;
        }

        .todo-title-wrp {
          top: 0;
          width: 100%;
          padding-top: 20px;

          h4 {
            font-size: 18px;
            font-weight: 500 !important;
          }
        }
      }
    }

    .task-detail-row {
      textarea {
        width: 100%;
        padding: 5px;

        &:focus {
          border-color: $revamp-theme-color;
          outline: none;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .revamp-right-content {
      ul.tasks-queries-list {
        .revamp-todo-list {
          margin-left: 20px;
          margin-right: 20px;
        }

        li {
          .todo-name {
            font-size: 16px;
            display: flex;
            align-items: flex-start;

            .custom-radio-box {
              background: #ffffff;
              border: 1px solid #afb2c0;
              box-sizing: border-box;
              height: 18px;
              width: 18px;
              border-radius: 100%;
              margin-right: 9px;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 18px;
              min-height: 18px;
              margin-top: 3px;

              em {
                display: none;
                line-height: 0;
              }

              &.active {
                font-size: 11px;
                background: #00d659;
                color: #fff;
                border-color: #00d659;

                em {
                  display: block;
                }
              }
            }
          }

          .todo-more-info {
            .todo-more-item {
              padding-right: 10px;

              strong.badge-style {
                font-size: 10px;
                border-radius: 20px;
                padding: 4px 10px;

                &.green {
                  background: #e8f5e9;
                  color: #2e7d32;
                }

                &.amber {
                  color: #ff6d00;
                  background: #fff3e0;
                }

                &.project {
                  color: #6200ea;
                  background: #ede7f6;
                }

                &.pending_style {
                  background: #e3f2fd;
                  color: #304ffe;
                }
              }
            }
          }
        }
      }
    }
  }
}

.empty-result {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  .heading-empty {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #3c3e49;
    margin-top: 26px;
  }

  .subheading-empty {
    font-size: 16px;
    line-height: 19px;
    color: #3c3e49;
  }
}

body.revamp-design.revamp-theme {
  .projects-tab {
    .noPdf {
      .create-on {
        opacity: 0.5;
      }
    }

    .start-stop-installments {
      position: relative;
      top: 0;
      left: 0;

      &:hover {
        .installmentContentTooltip {
          display: block;
        }
      }

      .installmentContentTooltip {
        display: none;
        padding: 5px 5px;
        height: auto;
        min-height: initial;
        position: absolute;
        top: -27px;
        background: #fff;

        p {
          margin-bottom: 0;
        }
      }
    }

    .spec-past-row,
    .spec-upcoming-row {
      .col-lg-4 {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
      }
    }

    .revamp-project-card {
      font-family: $revamp-font;
      background: #ffffff;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0);
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      padding: 20px 15px;
      display: block;
      cursor: pointer;
      //box-shadow: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0));
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      height: 100%;
      position: relative;

      &:hover {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
      }

      h4.block-title {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #000000;
        margin-bottom: 10px;
      }

      .subtitle-box {
        letter-spacing: -0.005em;
        color: #676b7e;
        margin-bottom: 4px;
      }

      .content-item {
        margin-top: 16px;
      }

      .card-info-title {
        color: #9b9fb1;
        margin-bottom: 6px;
      }

      .card-info-desc {
        color: #3c3e49;

        a {
          color: #3c3e49;
        }
      }

      .inner-content-info {
        display: flex;
        align-items: center;
        position: relative;
      }

      .round-info-icon {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 17px;
        background: #ecedf0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .copy-link {
        align-self: flex-end;
        font-size: 10px;
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 3px;
        left: auto;
        top: auto;

        &:hover .installmentContentTooltip {
          display: none;
        }

        &.copied .installmentContentTooltip,
        &.copied:hover .installmentContentTooltip {
          display: block;
        }
      }

      .main-card-info {
        width: calc(100% - 100px);
        padding-right: 15px;
      }
    }

    .item-inline {
      display: flex;
    }

    .pdf-wrp {
      margin-right: 30px;
    }

    .create-on {
      letter-spacing: -0.005em;
      color: #000000;
      border-bottom: 2px solid #00d659;
      cursor: pointer;
      padding-bottom: 2px;
    }

    .build-card-data {
      .create-on {
        margin-left: 30px;
        white-space: nowrap;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .footer-box-card {
      display: flex;
      margin-top: 20px;
    }
  }
}

.spec-modal-revamp {
  font-family: $revamp-font;
  z-index: 950;

  .ui-tab {
    ul {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #9b9fb1;
      margin-bottom: 20px;
      flex-wrap: wrap;

      &:after {
        content: "";
        display: block;
        border-bottom: 2px solid #ecedf0;
        height: 2px;
        width: 100%;
        margin-bottom: 0;
        position: relative;
        bottom: 0;
      }

      li {
        cursor: pointer;
        padding-bottom: 11px;

        &.active {
          color: $revamp-theme-color;
          border-bottom: 3px solid $revamp-theme-color;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 650px;

    .modal-body {
      padding: 50px;

      .closePop {
        border: 1px solid #dfe0e6;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        right: 30px;
        top: 30px;
      }
    }

    .ques-ans-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .ques-item {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 10px;
        color: #3c3e49;
        line-height: 20px;
      }

      .ans-item {
        border: 1px solid #dfe0e6;
        box-sizing: border-box;
        border-radius: 3px;
        display: inline-block;
        padding: 6px 12px;
        font-size: 12px;
        color: #3c3e49;
        line-height: 20px;
      }

      .sub-ans-item {
        font-size: 12px;
        line-height: 14px;
        color: #83889e;
        margin-bottom: 6px;
      }

      .multi-ans {
        display: flex;
        align-items: center;
      }

      .multi-ans-inside {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .form-group {
      padding: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .copy-element {
        position: relative;
        // img {
        //   position: absolute;
        //   right: 15px;
        //   top: 50%;
        //   transform: translateY(-50%);
        // }
      }

      label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #afb2c0;
        margin-bottom: 4px;
      }

      input.form-control,
      textarea.form-control {
        min-height: 40px;
        background: #f8f9fa;
        border: 1px solid #dfe0e6;
        border-radius: 3px;
        color: #afb2c0;
        font-size: 14px;
      }

      textarea.form-control {
        resize: vertical;
      }
    }
  }
}

/***Comment In popup Todo**/
#markingIncomplete {
  .commentArea {
    position: static;
    background: none;
    padding: 0;
    border: none;
    height: auto;

    .commentTextArea {
      border: none;
      padding: 0;
      height: auto;

      textarea {
        padding: 5px 10px;
        min-height: 158px;
        background: #ffffff;
        border: 1px solid #d0d2da;
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 14px;
        font-family: $revamp-font;
      }
    }
  }
}

/**Editor fontsize**/
.activeToolbar {
  font-size: 14px;
}

.angular-editor {
  .angular-editor-wrapper {
    .angular-editor-textarea {
      margin-top: 0;
      b {
        font-weight: bold;
      }
    }
  }
}

.angular-editor-textarea[contenteditable='false'] {
  background: #dddddd !important;
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)),
      $search,
      $replace );
  }

  @return $string;
}

/**quill Editor Overide**/
.ql-toolbar.ql-snow {
  border: none;

  .ql-color-picker.ql-color .ql-picker-item {
    border-radius: 15px;
  }

  .ql-color-picker.ql-background .ql-picker-item {
    border-radius: 15px;
  }

  .ql-picker {
    &.ql-size {
      width: 60px;

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(str-replace(data-value, "px", "")) !important;
        }
      }
    }
  }

  /* Set dropdown font-families */
  .ql-font span[data-value="sans-serif"]::before,
  .ql-font span[data-label="Sans Serif"]::before {
    font-family: "Helvetica, Arial, sans-serif", sans-serif;
  }

  .ql-font span[data-value='courier-new']::before,
  .ql-font span[data-label='Courier New']::before {
    font-family: 'Courier New', sans-serif;
  }

  .ql-font span[data-value='tahoma']::before,
  .ql-font span[data-label='Tahoma']::before {
    font-family: 'Tahoma', sans-serif;
  }

  .ql-font span[data-value='times-new-roman']::before,
  .ql-font span[data-label='Times New Roman']::before {
    font-family: 'Times New Roman', sans-serif;
  }

  .ql-font span[data-value='calibari']::before,
  .ql-font span[data-label='Calibari']::before {
    font-family: 'Calibari', sans-serif;
  }

  .ql-picker {
    &.ql-font {
      .ql-picker-label {
        &::before {
          overflow: hidden;
          white-space: nowrap;
          width: 66px;
          text-overflow: ellipsis;
        }
      }

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }

  &+.ql-container.ql-snow {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 14px !important;
    border-bottom: 1px solid #dfe0e6 !important;

    .ql-font-sans-serif {
      font-family: "Helvetica, Arial, sans-serif", sans-serif;
    }

    .ql-font-tahoma {
      font-family: "Tahoma", sans-serif;
    }

    .ql-font-times-new-roman {
      font-family: "Times New Roman", sans-serif;
    }

    .ql-font-courier-new {
      font-family: "Courier New", sans-serif;
    }

    .ql-font-calibari {
      font-family: "Calibari", sans-serif;
    }

    .ql-editor {
      min-height: 13rem;
      max-height: 14rem !important;
      font-size: inherit !important;
      white-space: normal !important;

      img.ql-emoji {
        width: 22px;
        height: 22px;
        vertical-align: bottom;
      }
    }

    .ql-editor p,
    .ql-editor ol,
    .ql-editor ul,
    .ql-editor pre,
    .ql-editor blockquote,
    .ql-editor h1,
    .ql-editor h2,
    .ql-editor h3,
    .ql-editor h4,
    .ql-editor h5,
    .ql-editor h6 {
      min-height: auto !important;
    }
  }
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
  color: #c3d5ea;
}

.ck-editor__main {
  display: flex;
  flex-direction: column-reverse;

  .ck.ck-editor__editable {
    min-height: 250px;
    max-height: 13rem !important;
    padding: 0 10px !important;
    border: 0 !important;

    p {
      margin-bottom: 0rem !important;
    }
  }
}

.ck {
  &.ck-toolbar {
    border: 0 !important;
    border-top: solid 1px #e9e9f0 !important;

    .ck-color-table {
      .ck.ck-color-grid__tile {
        border-radius: 100px !important;
      }
    }
  }
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: 0 !important;
  box-shadow: none !important;
}

.slim-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/***Common css for slider team allocation**/
.feature-grouping-revamp {
  position: relative;

  ngu-carousel,
  .ngucarousel {
    position: static !important;
  }

  .ngucarousel {
    button {
      width: 24px;
      height: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: -7px;

      &.rightRs {
        left: auto;
        right: -4px;
      }
    }
  }
}

.custom-angular-editor, .angular-editor {
  table {
    tr {
      td {
        border-left: solid #000000 1pt;
        border-right: solid #000000 1pt;
        border-bottom: solid #000000 1pt;
        border-top: solid #000000 1pt;
        vertical-align: top;
        padding: 5pt 5pt 5pt 5pt;
        overflow: hidden;
        overflow-wrap: break-word;
        line-height: initial;
      }
    }
  }
  p, div, span, a {
    margin: initial;
    padding: initial;
  }
}

.custom-angular-editor {
  &.disabled-editor {
    border: 1px solid #ddd !important;
  }
  .angular-editor-toolbar {
    font:
      100 14px/15px Roboto,
      Arial,
      sans-serif;
    background-color: #f5f5f5 !important;
    font-size: 0.8rem;
    padding: 0.2rem;
    border: 1px solid #ddd !important;
  }

  .angular-editor-textarea {
    border: 1px solid #ddd !important;
    min-height: 150px !important;
  }
}

.skeleton-loader:empty {
  width: 100%;
  height: auto;
  display: block;
  min-height: 12px;
  border-radius: 4px;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%),
    #ebe9fb;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
    &.dark-mode{
    width: 100%;
    height: auto;
    display: block;
    min-height: 12px;
    border-radius: 4px;
    background: linear-gradient(to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%),
    #e3e3e3;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
      animation: shine 1s infinite;
    }
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

.alias {
  color: #3c3e49;
  font-size: 1.3rem;

  .alias-name {
    border-radius: 4px;
    background-color: #e5e7ff;
    padding: 0.1rem 0.25rem;
  }
}

.angular-editor-toolbar {
  font-size: 1.3rem !important;
}

.tooltip {
  font-size: 14px;
}

span.highlight-transcript {
  color: #3d5afe;
  font-weight: 500;
}

div.mention-container {
  position: relative;
  background-color: #F3EBFF;
  color: #6200EA;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  display: inline-block;
  &::before {
    content: '@';
  }
  span.mention {
    border-radius: initial !important;
    padding: initial !important;
  }
}

span.mention {
    background-color: #F3EBFF;
    color: #6200EA;
    border-radius: 4px;
    padding: 2px 4px;
    display: inline-block;
    position: relative;
    &[data-deactivated="true"] {
      background-color: #ECEDF0;
      color: #3c3e49ad;
      &[data-info] {
        &::before {
          content: attr(data-info);
        }
      }
    }
    &[data-email], &[data-info] {
      &:hover {
        &::before, &::after {
          display: inline-block;
        }
      }
    }
    &::before {
      content: attr(data-email);
      position: absolute;
      width: max-content;
      left: 0;
      top: calc(100% + 5px);
      padding: 4px 8px;
      background: #3C3E49;
      color: #fff;
      border-radius: 3px;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      display: none;
      z-index: 2;
    }
    &::after {
      z-index: 2;
      content: '';
      position: absolute;
      top: 100%;
      left: 6px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #3c3e49;
      display: none;
    }
  }

.cdk-overlay-pane {
  background: white;
  border: 1px #afb2c0 solid;
  border-radius: 3px;

  mat-option {
    min-height: 20px !important;
  }
}

.lighthouse-search {
  width: 80px;
  max-width: 100% !important;
  height: auto !important;
}

.meeting-list {
  .meeting-row {
    margin-bottom: 36px;
    position: relative;
    padding-left: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .meeting-counter {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.16px;
    }
    .meeting-info {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 8px;
        padding-right: 8px;
        border-right: 1px solid #dfe0e6;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16px;
        &:last-child {
          margin-right: 0;
          padding-right: 0;
          border-right: 0;
        }
        .duration {
          color: #676b7e;
          margin-left: 2px;
        }
      }
    }
    .meeting-tags {
      margin: 8px 0 -16px;
      display: flex;
      flex-wrap: wrap;
      .tag-col {
        margin-right: 16px;
        margin-bottom: 16px;
        display: inline-flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        > .tooltip-wrapper {
          display: inline-flex;
          margin-left: 4px;
          .tooltip-trigger {
            cursor: pointer;
            display: inline-flex;
          }
          .tooltip-content {
            &.right-aligned {
              right: -8px;
            }
          }
        }
      }
      .primary-purple-btn {
        height: 32px;
        display: inline-flex;
        align-items: center;
        padding: 0 12px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #262626;
        letter-spacing: 0.16px;
        .icon {
          margin-right: 8px;
          &.animated {
            animation: circle-anim 2s infinite linear;
          }
        }
        .postfix-icon {
          cursor: pointer;
          display: inline-flex;
          margin-left: 8px;
          border-left: 1px solid #afb2c0;
          padding-left: 8px;
        }
      }
    }
  }
}
