@media only screen and (min-width: 2101px) {
  .checklist-common-list {
    .account-setup-table {
      table {
        tr {
          td {
            &:nth-child(3) {
              max-width: 100%;
            }
          }
        }
      }
    }

    .builder-planner-table {
      table {
        td {
          &:nth-child(4) {
            max-width: 100%;
          }
        }
      }
    }

    .code-repo-table,
    .design-board-table {
      table {
        td {
          &:nth-child(3) {
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 2101px) and (min-width: 1615px) {
  .checklist-common-list {
    .account-setup-table {
      table {
        tr {
          td {
            &:nth-child(3) {
              max-width: 860px;
            }
          }
        }
      }
    }

    .builder-planner-table {
      table {
        td {
          &:nth-child(4) {
            max-width: 590px;
          }
        }
      }
    }

    .code-repo-table,
    .design-board-table {
      table {
        td {
          &:nth-child(3) {
            max-width: 690px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1447px) {
  .projects-tab {
    .nav-tabs {
      .nav-item {
        padding: 0 0.5rem;
        &.builderCareNav {
          a {
            img {
              position: absolute;
              left: 0.2rem;
              top: 12px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1370px) {
  .build-tab {
    .tab-content {
      &.epicPhaseTabCon {
        height: calc(100vh - 85px);
      }
    }
  }
}
@media only screen and (max-width: 1367px) {
  .checklist-common-list {
    .account-setup-table {
      table {
        tr {
          td {
            &:nth-child(3) {
              max-width: 500px;
            }
          }
        }
      }
    }
  }

  .invoice-tab .tab-content .tab-pane {
    .invoice-main-block {
      .invoice-table {
        .checklist-common-list {
          .invoice-table-inner {
            .table-content {
              max-height: 238px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1320px) {
  ul.project-card-list {
    li {
      &:nth-child(2) {
        float: none;
        top: auto;
      }
    }
  }
  .projects-tab {
    .nav-tabs {
      .nav-item {
        padding: 0 0.4rem;
        &.builderCareNav a img {
          width: 12px;
          left: 0.2rem;
          top: 11px;
        }
        .nav-link {
          font-size: 13.5px;
        }
      }
      .nav-item.show .nav-link.builderCare-tab-color,
      .nav-link.builderCare-tab-color.active {
        img {
          top: -23px !important;
        }
      }
    }

    .projectDetail {
      .project-info-row2 {
        flex-wrap: wrap;
        margin: 30px 0;
        .info-block {
          width: 50%;
          margin: 10px 0;
        }
      }
    }
  }

  body.revamp-design.revamp-theme {
    .projects-tab {
      .basic-info-revamp {
        .projectDetail {
          .project-info-top {
            .project-info-row {
              h3 {
                font-size: 16px;
              }
              span.customPrototype {
                font-size: 12px;
              }
            }
          }
        }
        .projectDetail {
          .project-info-top {
            .project-info-row {
              .final-cost {
                p {
                  font-size: 14px;
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/****Manoj added(july22) new start****/
@media only screen and (max-width: 991.98px) {
  .projects-tab {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      min-height: 45px;
      overflow: hidden;
      overflow-x: auto;
      .nav-item {
        flex-shrink: 0;
      }
    }
  }

  ul.ongoing-dashboard-nav:not(.ongoing-dashboard-secondary-nav) {
    display: none;
    left: auto;
    top: auto;
    z-index: 1010;
    bottom: 150px;
    right: 30px;
    margin: 0;
    height: calc(100% - 250px);
    border: 1px solid #d9d9d9;
    padding: 12px !important;
    &.active {
      display: block;
    }
  }

  .partner-tab {
    .partner-main-block {
      .capacity-partner-table {
        .checklist-common-list {
          .invoice-table-inner {
            table {
              tr {
                th,
                td {
                  width: 150px;
                  &:first-child,
                  &:nth-child(2) {
                    width: 200px;
                  }
                  &:nth-last-child(2),
                  &:last-child {
                    width: 150px;
                  }
                }
              }
            }

            &.recomm-table {
              table {
                tr {
                  th,
                  td {
                    width: 150px;
                    &:first-child,
                    &:nth-child(2) {
                      width: 200px;
                    }
                    &:nth-last-child(2),
                    &:last-child {
                      width: 150px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .manager-detail-block {
    .project-list-main {
      .project-list {
        display: flex;
        flex-wrap: wrap;
        li {
          flex-wrap: wrap;
          width: 50%;
          border: 1px solid #ccc;
          padding: 15px;
          p {
            width: 100%;
            margin: 0 0 10px;
          }
          .progress {
            width: 80%;
          }
        }
      }
    }
  }

  .todosQueries {
    .todos-left,
    .todos-right {
      width: 100%;
      height: 70%;
      float: none;
      clear: both;
    }
  }

  .revamp-design.revamp-theme {
    .revamp-right-content {
      .todosQueries {
        .todos-left,
        .todos-right {
          width: 100%;
          height: auto;
          float: none;
          clear: both;
          .todos-inner {
            position: relative;
            top: 0;
          }
          .commentArea {
            position: relative;
          }
        }
      }

      .todos-header {
        overflow-x: auto;
      }
    }
  }

  .feature-list-block {
    .feature-bucket {
      justify-content: space-between;
      .feature-bucket-block {
        width: calc(50% - 16px);
        margin: 0 0 20px;
        &:nth-child(5n),
        &:nth-child(5n + 1) {
          margin: 0 0 20px;
        }
      }
    }
  }

  .project-allocation-revamp {
    padding: 30px 5px;
    ul.project-breadcrumb {
      li {
        font-size: 18px;
      }
    }

    .revamp-nav-part {
      .ravamp-nav-part-inner {
        .nav-tabs {
          .nav-link {
            font-size: 13px;
          }
        }
        ul.project-allocation-edit.allocation-edit-mode li {
          font-size: 13px;
        }
      }
    }
  }

  body.revamp-design.revamp-theme {
    .revamp-tab-block {
      margin: 0 -15px;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .projects-block {
    h3 {
      display: block;
    }
  }

  .projects-search-filter {
    width: 100%;
    float: none;
    clear: both;
    margin: 0 0 10px;
    justify-content: flex-start;
    .filterIcons {
      width: 130px;
      > div {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }

        &.calendar {
          .calendarBlock {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1002;
            box-shadow: none;
            border: none;
            .triangle {
              display: none;
            }

            .calendarBlockHeader {
              width: 100%;
              height: 50px;
              .headerDropdownBlock {
                right: auto;
                left: 0;
                .triangle {
                  display: block;
                  right: 61px;
                }
              }
            }

            .calendarBlockContent {
              width: 100%;
              height: calc(100% - 108px);
              flex-wrap: wrap;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 3px;
              }
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }
              &::-webkit-scrollbar-thumb {
                background: #888;
              }
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }

              .calendarLeft {
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;
                .calendarInnerBlock {
                  padding: 20px;
                  border-radius: 6px;
                  margin: 0 10px 20px 10px;
                  border: 1px solid #ccc;
                }
              }
              .calendarSideFilter {
                width: 100%;
                min-height: 50px;
                border: none;
                border-top: 1px solid #ccc;
                ul {
                  width: 100%;
                  margin: 0;
                  padding: 12px 0 0;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  li {
                    display: block;
                    border: 1px solid #ccc;
                    border-radius: 20px;
                    padding: 2px 15px;
                    margin: 4px;
                    cursor: pointer;
                  }
                }
              }
            }

            .calendarBlockFooter {
              width: 100%;
              height: 58px;
            }
          }
        }
      }

      &.resetOn {
        width: 175px;
      }
    }
  }

  .projects-tab {
    .projectDetail {
      .contract-row {
        ul {
          &.pdf-contract-list {
            li {
              width: 100%;
            }
          }
        }
      }

      .project-info-row2 {
        .info-block {
          width: 100%;
        }
      }
      .project-info-top {
        .project-info-row {
          p {
            cite {
              &.tooltip_msg_dis {
                .triangle {
                  left: 66px;
                }
                .msgTooltip-wrp {
                  left: 0;
                }
                .msgTooltip {
                  min-width: 200px;
                  width: 200px;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    }
  }

  ul.phaseinfolist {
    li {
      width: 100%;
      border: none;
      border-top: 1px solid #dfe0e6;
      margin: 0;
      padding: 16px 0;
    }
  }

  .phasedevices {
    padding: 16px;
    ul.phasedevicelist {
      margin: 20px 0 0;
    }
  }

  body.revamp-design.revamp-theme {
    .main-cost {
      font-size: 14px;
    }
    .projects-tab {
      .basic-info-revamp {
        .projectDetail {
          .project-info-top {
            padding: 16px;
          }
        }
      }
    }

    .footer {
      p {
        text-align: center;
      }

      &.expandFooter {
        height: auto;
        z-index: 1012;
        ul.footer-links {
          display: flex;
        }
      }
    }
    .revamp-tab-block {
      .projects-tab {
        .tab-content {
          padding: 0 15px;
        }
        .revamp-common-nav-part-inner {
          .nav-tabs {
            margin: 0 0 20px;
            padding: 14px;
          }
        }
      }
      .projects-search-filter {
        &.projects-search-filter {
          position: relative;
          width: 100%;
          margin: 15px 0 0 0;
          padding: 0 15px;
          top: 0;
          left: 0;
          .projects-search {
            min-width: 100%;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
    .purpose-row-revammp {
      .purpose-row-item {
        width: calc(50% - 20px);
        margin: 0 0 24px;
      }
    }
    .nav-tab-right {
      width: 100%;
      margin: 0 0 20px;
    }

    .revamp-right-content {
      ul.tasks-queries-list {
        li {
          .todo-name {
            font-size: 14px;
          }
          .revamp-todo-list {
            padding: 16px;
          }
          .todo-more-info {
            margin-top: 5px;
            .todo-more-item {
              width: 100%;
              margin: 2px 0;
            }
          }
        }
      }
    }
  }

  .manager-detail-block {
    .manager-detail-top-row {
      ul {
        width: 100%;
        li {
          font-size: 13px;
        }
      }
    }
  }

  .repo-tab-main {
    .topAddUploadfile {
      position: relative;
      top: 0;
    }
  }

  .feature-ques-block {
    .ques-list-block {
      &.quick-tips-listing {
        .ques-list-row {
          height: auto;
        }
      }

      .ques-list-row {
        flex-wrap: wrap;
        height: auto;
        padding: 10px 15px;
        .ques-list-row-left {
          width: 100%;
          margin: 0 0 5px;
        }
        .ques-list-row-right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          ul.edit-del-list {
            justify-content: flex-start;
            width: 100%;
            order: 2;
          }
          ul.ques-listing-con {
            justify-content: flex-start;
            width: 100%;
            order: 1;
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }

  .projects-detail {
    .team-allocation {
      .tab-content {
        .tab-inner {
          .tab-inner-header {
            justify-content: space-between;
            h4 + .projects-search {
              margin-top: 10px;
            }
            .projects-search {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .meetings {
    .meetings-listing-tab {
      .tab-content {
        ul.tasks-queries-list {
          &.meetings-list {
            li {
              width: 800px;
            }
          }
        }
      }
    }
  }

  .invoice-tab {
    .tab-content {
      .tab-pane {
        .invoice-main-block {
          .invoice-top-row {
            .invoice-inner-block {
              width: 100%;
              margin: 0 0 10px;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  button.add-task-top-btn {
    top: 0;
  }
}

@media only screen and (max-width: 575.98px) {
  .project-card-bottom-list {
    border: none;
    padding: 0;
    height: auto;
    table {
      tr {
        display: flex;
        flex-wrap: wrap;
        td {
          width: 50%;
          border: 1px solid #dcdee2;
          padding: 8px;

          &.redCardBlock,
          &.NoPaymentBlock,
          &.grayCardBlock {
            &:before {
              height: calc(100%);
              top: 0;
            }
          }

          &:nth-child(3),
          &:nth-child(4),
          &:last-child {
            border: 1px solid #dcdee2;
            border-bottom: none;
          }
        }
      }
    }
  }

  .manager-detail-block {
    .project-list-main {
      .project-list {
        li {
          width: 100%;
        }
      }
    }
  }

  .call-score-modal {
    .modal-body {
      padding: 20px;
    }
  }

  .feature-list-block {
    .feature-bucket {
      .feature-bucket-block {
        width: 100%;
      }
    }
  }

  .feature-ques-block {
    .ques-list-block {
      .ques-list-row {
        .ques-list-row-left {
          ul.ques-listing-con {
            width: 100%;
            flex-wrap: wrap;
            li {
              width: 100%;
              padding: 0;
            }
          }
        }
        .ques-list-row-right {
          ul.ques-listing-con {
            flex-wrap: wrap;
            li {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .db-modal {
    .modal-dialog {
      .modal-body {
        padding: 20px;
      }
    }
  }

  .answer-bottom-detail {
    ul.ques-listing-con {
      width: 100%;
      flex-wrap: wrap;
      li {
        width: 100%;
        padding: 0;
      }
    }
  }

  .partner-tab {
    .partner-main-block {
      .capacity-partner-table {
        .checklist-common-list {
          .invoice-table-inner {
            &.recomm-table {
              table {
                tr {
                  .skill-block {
                    left: -100px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .folder-header {
    h4 {
      word-break: break-all;
      white-space: normal;
    }
  }

  .add-file-block {
    .add-file-con {
      img {
        width: 44px;
      }
      p {
        margin: 10px auto;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  body.revamp-design.revamp-theme {
    .purpose-row-revammp {
      .purpose-row-item {
        width: 100%;
        margin: 0 0 24px;
      }
    }
  }
}
/****Manoj added(july22) new close****/
