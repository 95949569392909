.checklist-tab {
  .tab-content {
    .revamp-table {
      tr {
        &.disabled-row {
          img {
            opacity: 0.5;
          }
        }
        th {
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          color: #3c3e49;
          border-bottom: 1px solid #ecedf0;
          &:first-child {
            padding-left: 15px;
          }
        }
        td {
          font-weight: 400;
          font-size: 13px;
          line-height: 17px;
          border-top: none;
          border-bottom: none;
          &:first-child {
            padding-left: 15px;
          }
          a {
            font-size: 12px;
            line-height: 14px;
            color: #3c3e49;
          }
        }
      }
      tbody {
        max-height: initial;
        tr {
          &:nth-child(even) {
            background: #f8f9fa;
          }
        }
      }
    }
  }
}
.full-height-parent {
  height: 100% !important;
}
.revamp-btn-green,
.revamp-btn-cancel {
  padding: 6px 16px;
  background: #00d659;
  border-radius: 3px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  border: none;
  line-height: 20px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    color: #fff;
  }
}

.revamp-btn-cancel {
  background: none;
  border: 1px solid #000000;
  font-weight: 400;
}

.revamp-checklist-modal {
  .modal-body {
    padding: 25px;
  }
  label {
    color: #3c3e49;
    font-weight: 500;
  }
  textarea.form-control {
    resize: vertical;
  }
  input.form-control,
  textarea.form-control {
    border: 1px solid #dfe0e6;
    border-radius: 3px;
    color: #000000;
  }
}
.table-fixed {
  table-layout: fixed;
}

.checklist-common-list {
  .checklist-common-table {
    table {
      td {
        a {
          font-size: 12px;
          line-height: 14px;
          color: #3c3e49;
          &:hover {
            color: #3c3e49;
          }
        }
      }
    }
  }
}
